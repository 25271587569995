import { onePromiseAtATime } from './promises'

export type GooglePickerAPI = typeof google.picker
type GoogleAPI = typeof gapi
export interface DriveDocument {
  description: string
  embedUrl: string
  iconUrl: string
  id: string
  isShared: boolean
  lastEditedUtc: number
  mimeType: string
  name: string
  serviceId: string
  sizeBytes: number
  type: string
  url: string
}

interface Action<T extends string> {
  action: T
}

export interface PickedAction extends Action<'picked'> {
  docs: DriveDocument[]
  viewToken: ['all', null, {}]
}

export type PickerAction = PickedAction | Action<'cancel'> | Action<'loaded'>

const loadGAPI = onePromiseAtATime(
  (): Promise<GoogleAPI> =>
    new Promise((resolve, reject) => {
      if (window.gapi) {
        resolve(window.gapi)
      }

      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/api.js'
      script.id = 'googleapis'
      document.body.appendChild(script)

      script.onerror = reject
      script.onload = () => {
        if (!window.gapi) {
          return reject(new Error('Expected gapi to load'))
        }
        resolve(window.gapi)
      }
    })
)

const loadGIS = () => {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.accounts) {
      resolve(window.google.accounts)
    } else {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.id = 'googleaccounts'
      document.body.appendChild(script)

      script.onerror = reject
      script.onload = () => {
        if (!window.google || !window.google.accounts) {
          return reject(new Error('Expected google.accounts to load'))
        }
        resolve(window.google.accounts)
      }
    }
  })
}

const loadService = (gapi: GoogleAPI, service: string) =>
  new Promise((resolve, reject) => {
    gapi.load(service, {
      callback: resolve,
      onerror: reject,
    })
  })

const apiNotFoundError = (service: string) =>
  new Error(`Unable to find ${service}}`)

export const loadPickerAPI = async () => {
  if (window.google && window.google.picker) {
    return window.google.picker
  }

  const gapi = await loadGAPI()
  await loadGIS()
  await loadService(gapi, 'picker')

  if (window.google && window.google.picker) {
    return window.google.picker
  }

  throw apiNotFoundError('google.picker')
}

export const loadDriveAPI = async () => {
  const gapi = await loadGAPI()
  await loadGIS()

  if (gapi.client && gapi.client.drive) {
    return gapi.client.drive
  }

  await loadService(gapi, 'client')

  if (!gapi.client) {
    throw apiNotFoundError('client')
  }

  await gapi.client.load('drive', 'v3')

  if (!gapi.client.drive) {
    throw apiNotFoundError('client.drive')
  }

  return gapi.client.drive
}

const CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID

export const getTokenClient = async (callback: any) => {
  if (!CLIENT_ID) {
    throw new Error('Expected to find gapi clientId')
  }

  await loadGIS()
  const tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/drive.file',
    callback: callback,
  })
  return { tokenClient }
}

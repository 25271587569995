import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import Select from 'react-select'
import { AuthenticatedRouteProps } from 'src/components/AuthenticatedRoute'
import { Flex } from 'src/components/Box'
import BasicCheckbox from 'src/components/FormFields/BasicCheckbox'
import { LargeTitle } from 'src/components/text'
import Loading from 'src/components/Loading'
import {
  useShowcaseProjectsQuery,
  GlobalGoal,
  ProjectPhaseState,
} from 'src/graphql-generated'
import PageBackground, { BackgroundType } from 'src/components/PageBackground'
import strings from 'src/strings'
import { minuteToMs } from 'src/lib/time'
import ErrorPage from 'src/pages/ErrorPage'
import { ProjectDetailsWithNotification } from 'src/lib/project'
import { enumValues } from 'src/lib/enum'
import { sortProjectsByCompletion } from 'src/lib/projectSortHelper'
import ProjectsLayout from '../Dashboard/ProjectsLayout'

const ShowcaseProjectsPlaceholder = React.lazy(() =>
  import('./ShowcaseProjectsPlaceholder')
)
const CLASS_GROUP_POLL_INTERVAL_MS = minuteToMs(2)

const filterProjects = (
  projects: ProjectDetailsWithNotification[],
  globalGoal: GlobalGoal | null,
  showCompleteProjects: boolean
): ProjectDetailsWithNotification[] => {
  let filteredProjects: ProjectDetailsWithNotification[] = []

  filteredProjects =
    globalGoal === null
      ? projects
      : projects.filter(project => project.globalGoal === globalGoal)

  if (showCompleteProjects) {
    filteredProjects = filteredProjects.filter(
      project =>
        project.phaseSummary.pitch.state === ProjectPhaseState.Approved &&
        project.phaseSummary.ideate.state === ProjectPhaseState.Approved &&
        project.phaseSummary.understand.state === ProjectPhaseState.Approved &&
        project.phaseSummary.prototype.state === ProjectPhaseState.Approved
    )
  }

  return filteredProjects
}

const Showcase: React.FunctionComponent<AuthenticatedRouteProps> = () => {
  const { data, loading, error } = useShowcaseProjectsQuery({
    pollInterval: CLASS_GROUP_POLL_INTERVAL_MS,
  })

  const [allProjectsList, setAllProjectsList] = useState<
    ProjectDetailsWithNotification[]
  >([])

  const [
    globalGoalIdentifier,
    setGlobalGoalIdentifier,
  ] = useState<GlobalGoal | null>(null)

  const [showCompleteOnly, setShowCompleteOnly] = useState<boolean>(true)

  const tempList: ProjectDetailsWithNotification[] = []

  useEffect(() => {
    setAllProjectsList(
      filterProjects(tempList, globalGoalIdentifier, showCompleteOnly)
    )
  }, [data, showCompleteOnly, globalGoalIdentifier])

  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <ErrorPage error={error} />
  }

  // consolidate data here...
  if (!loading && data) {
    tempList.push(...data.showcaseProjects)
    sortProjectsByCompletion(tempList)
  }
  // end data consolidation

  const options = enumValues(GlobalGoal).map(goal => ({
    label: `${strings.globalGoals[goal].number} - ${
      strings.globalGoals[goal].name
    }`,
    value: GlobalGoal[goal],
  }))

  const handleChange = (selectedOption: any): void => {
    const value = selectedOption ? selectedOption.value : null
    setGlobalGoalIdentifier(value)
  }

  const styles = {
    control: (base: any) => ({
      ...base,
      fontFamily: '"Maven Pro", sans-serif',
      fontSize: 20,
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: '"Maven Pro", sans-serif',
      fontSize: 20,
    }),
  }

  return (
    <Flex mt="10" flexDirection="column">
      <Helmet title={strings.pageTitles.showcaseTitle} />
      <PageBackground type={BackgroundType.YellowCurveConcave} />
      <LargeTitle mb="5" data-cy="showcaseHeading">
        {strings.showcase.heading}
      </LargeTitle>
      <>
        <Select
          options={options}
          isClearable
          placeholder={strings.showcase.filter.placeholderText}
          onChange={handleChange}
          styles={styles}
        />
        <Flex mt="5">
          <BasicCheckbox
            name="showCompleteProjects"
            checked={showCompleteOnly}
            onChangeHandler={() => {
              setShowCompleteOnly(!showCompleteOnly)
            }}
            labelText={strings.showcase.showComplete}
          />
        </Flex>
        {allProjectsList && allProjectsList.length === 0 ? (
          <React.Suspense fallback={<Loading />}>
            <ShowcaseProjectsPlaceholder />
          </React.Suspense>
        ) : (
          <ProjectsLayout
            projects={allProjectsList}
            title={''}
            cardsAreLinked={false}
            showAvatars={false}
          />
        )}
      </>
    </Flex>
  )
}
export default Showcase

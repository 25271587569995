import React from 'react'
import {
  useGoogleFilePicker,
  Params as GoogleFilePickerParams,
} from 'src/hooks/useGoogleFilePicker'
import { AttachmentService, ProjectPhase } from 'src/graphql-generated'
import { loadDriveAPI } from 'src/lib/gapis'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import { globalViewerId } from 'src/lib/globalViewerId'
import { trackEvent } from 'src/lib/analytics'
import * as Sentry from '@sentry/react'
import { useDocumentsQueryAndMutation } from './useDocumentsQueryAndMutation'

export const useDocumentsAndFilePicker = ({
  pickerTitle,
  projectId,
  projectPhase,
}: {
  pickerTitle: string
  projectId: number
  projectPhase: ProjectPhase
}) => {
  const {
    loadingDocuments,
    attachedDocuments,
    attachDocumentMutation,
  } = useDocumentsQueryAndMutation({
    projectId,
    projectPhase,
  })

  const [failedFiles, setFailedFiles] = React.useState<string[]>([])

  const filePickerOnSelect: GoogleFilePickerParams['onSelect'] = async docs => {
    const existingDocumentIds = attachedDocuments.map(d => d.serviceId)

    const failedFiles = await Promise.all(
      docs
        .map(d => ({
          url: d.url,
          serviceId: d.id,
          service: AttachmentService.Google,
          mimeType: d.mimeType,
          name: d.name,
        }))
        .map(async variables => {
          try {
            await allowCommentPermissions(variables.serviceId)
            if (existingDocumentIds.includes(variables.serviceId)) {
              return
            }

            await attachDocumentMutation({
              optimisticResponse: {
                attachDocument: {
                  __typename: 'AttachDocument',
                  projectId,
                  projectPhase,
                  ...variables,
                },
              },
              variables: {
                projectId,
                projectPhase,
                ...variables,
              },
            }).finally(() => {
              //globalViewerId.id is set in src/components/Header/index.tsx
              //current-location will be the value of the previous url when this is read
              if (globalViewerId.id !== 0) {
                recordAnalyticsEvent({
                  userId: globalViewerId.id,
                  event: AnalyticsEvents.DocumentAttached,
                  url: window.location.href,
                  type: AnalyticsEventTypes.Project,
                  title: variables.name,
                  mediaSource: null,
                  objectId: projectId.toString(),
                  mediaSourceUrl: null,
                  referrer: sessionStorage.getItem('current-location'),
                  trackStartTime: null,
                  timeZoneOffset: new Date().getTimezoneOffset(),
                })
              }

              trackEvent(
                AnalyticsEventTypes.Project,
                AnalyticsEvents.DocumentAttached,
                variables.name,
                projectId
              )
            })
          } catch (e) {
            Sentry.captureException(e)
            return variables.name
          }
        })
    )

    setFailedFiles(
      failedFiles.filter((x: any): x is string => typeof x === 'string')
    )
  }

  const { loading: loadingPicker, openFilePicker } = useGoogleFilePicker({
    multi: true,
    includeFolders: true,
    title: pickerTitle,
    onSelect: filePickerOnSelect,
  })

  return {
    loadingDocuments,
    loadingPicker: loadingPicker || loadingDocuments, // n.b. filePickerOnSelect uses attachedDocuments
    openFilePicker: openFilePicker
      ? () => {
          setFailedFiles([])
          return openFilePicker()
        }
      : null,
    attachedDocuments,
    failedFiles,
  }
}

const commentShareLinkPermission = (permission: any) =>
  permission.type === 'anyone' &&
  ['organizer', 'owner', 'fileOrganizer', 'writer', 'commenter'].includes(
    permission.role
  )

const getDomain = (emailAddress: string) => {
  const atIndex = emailAddress.indexOf('@')
  let domain = ''

  if (atIndex >= 0) {
    domain = emailAddress.slice(atIndex + 1, emailAddress.length)
  }

  return domain
}

const allowCommentPermissions = async (fileId: string) => {
  const drive = await loadDriveAPI()

  const {
    result: { capabilities, permissions },
  } = await drive.files.get({
    fileId: fileId,
    fields: 'capabilities,permissions',
  })

  if (permissions && permissions.find(commentShareLinkPermission)) {
    return
  }

  if (!capabilities || !capabilities.canShare) {
    throw new Error('Cant set permissions')
  }

  try {
    await drive.permissions.create({
      fileId: fileId,
      resource: {
        type: 'anyone',
        role: 'commenter',
        allowFileDiscovery: false,
      },
    } as any)
  } catch (ex) {
    //try domain level create
    const {
      result: { user },
    } = await drive.about.get({ fields: 'user' })

    const emailAddress = user && user.emailAddress ? user.emailAddress : ''
    const domain = getDomain(emailAddress)

    await drive.permissions.create({
      fileId: fileId,
      resource: {
        type: 'domain',
        role: 'commenter',
        allowFileDiscovery: false,
        domain: domain,
      },
    } as any)
  }
}

import styled from 'src/styles/styled'

const Action = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: ${props => props.theme.colors.dodgerBlue};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.skyBlue};
  }
`

export default Action

import { cssCalc, cssVar } from './variables'

export const VH_VAR = '--vh'

const setVh = () =>
  document.documentElement.style.setProperty(
    VH_VAR,
    `${window.innerHeight * 0.01}px`
  )

let initialized = false
export const initVerticalHeight = () => {
  if (initialized) {
    return
  }

  if (typeof window === 'undefined') {
    return
  }

  setVh()
  window.addEventListener('resize', setVh, false)
  initialized = true
}

export const vhHeight = (vhCount: number) =>
  cssCalc(cssVar(VH_VAR, '1vh'), '*', vhCount)

import React from 'react'
import { Box } from 'src/components/Box'
import { ProfileIcon } from 'src/components/ProfileIcon'
import { Student } from 'src/graphql-generated'

export const TeamMember: React.FunctionComponent<Student> = student => (
  <Box mr="3">
    <ProfileIcon user={student} size={32} />
  </Box>
)

import { createMediaQuery } from 'styled-system'
import { Interpolation } from '@emotion/css'
import { theme } from './theme'

export const breakpointStyle = (bp: 0 | 1, styles: Interpolation) => ({
  [createMediaQuery(theme.breakpoints[bp])]: styles,
})

export const tabletBreakpoint = (styles: Interpolation) => ({
  [createMediaQuery(theme.breakpoints[0])]: styles,
})

export const desktopBreakpoint = (styles: Interpolation) => ({
  [createMediaQuery(theme.breakpoints[1])]: styles,
})

import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Loading from './Loading'

type Props<
  ComponentProps extends {},
  TParams extends {} = {}
> = RouteComponentProps<TParams> & {
  component: React.LazyExoticComponent<
    React.ComponentType<RouteComponentProps<TParams> & ComponentProps>
  >
} & ComponentProps

const LazyRoute = <TComponentProps extends {} = {}, TParams extends {} = {}>({
  component: Component,
  ...props
}: Props<TComponentProps, TParams>) => (
  <React.Suspense fallback={<Loading />}>
    <Component {...props as any} />
  </React.Suspense>
)

export default LazyRoute

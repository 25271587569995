export const theme = {
  //  s:  0  1  2  3  4   5   6   7   8   9   10   11
  space: [0, 2, 4, 8, 16, 24, 32, 48, 64, 96, 128, 208],
  colors: {
    // Primary
    purple: '#6b39a2',
    topaz: '#10cab9',
    lemon: '#fff156',
    dullOrange: '#e88624',
    // Secondary
    lightPurple: '#9753e2',
    turquoise: '#02b3a3',
    dullYellow: '#f5e74d',
    lightOrange: '#ffa449',
    // Neutrals lightest to darkest
    white: '#ffffff',
    lightestGrey: '#f7f7f7',
    lightGrey: '#eaeaea',
    middleGrey: '#c3c3c3',
    mediumGrey: '#7e7e7e',
    charcoalGrey: '#323441',
    // Button & CTA lightest to darkest
    skyBlue: '#37a1ff',
    dodgerBlue: '#007ef0',
    peacockBlue: '#0054a1',
    // Error & Success
    coral: '#ee5959',
    leafly: '#10ca4e',
  },
  fonts: {
    barlow: 'Barlow, sans-serif',
    karla: 'Karla, sans-serif',
    mavenPro: "'Maven Pro', sans-serif",
  },
  breakpoints: ['40em', '52em', '64em'],
}

export type Theme = typeof theme

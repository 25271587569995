import { useClassGroupsQuery } from 'src/graphql-generated'

interface Result {
  classGroupId: number | null
  loading: boolean
}

export const useResolveClassGroupId = (id: number | undefined): Result => {
  if (localStorage.getItem('persistedClassGroupId')) {
    return {
      classGroupId: Number(localStorage.getItem('persistedClassGroupId')),
      loading: false,
    }
  }

  const { data, loading, error } = useClassGroupsQuery()

  if (typeof id !== 'undefined') {
    return { classGroupId: id, loading: false }
  }

  if (loading) {
    return { classGroupId: null, loading: true }
  }

  if (error || !data || data.viewerClassGroups.length === 0) {
    return { classGroupId: null, loading: false }
  }

  return { classGroupId: data.viewerClassGroups[0].id, loading: false }
}

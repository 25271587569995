import React, { useState } from 'react'
import { Flex, Box } from 'src/components/Box'
import { SectionTitle } from 'src/components/text'
import Action from 'src/components/Action'
import {
  ClassGroup,
  Viewer,
  ClassGroupRole,
  ProjectDetails,
} from 'src/graphql-generated'
import { ButtonLink } from 'src/components/Button'
import SelectField from 'src/components/FormFields/SelectField'
import { LargeProperty, SmallProperty } from 'src/components/Property'
import { strings } from 'src/strings'
import { newProjectPath } from 'src/lib/paths'
import { PageBackground, BackgroundType } from 'src/components/PageBackground'
import { setPreferredClassGroup } from 'src/lib/userPreferenceSettings'
import CreateClassGroupModal from 'src/components/CreateClassGroupModal'
import ManageCoTeaacherModal from 'src/components/ManageCoTeacherModal'
import { TeamMember } from 'src/components/TeamMember'

interface Props {
  classGroups: ClassGroup[]
  viewer: Viewer
  children?: (classGroup: ClassGroup) => React.ReactNode
}

const myProjectsCount = (
  projects: Pick<ProjectDetails, 'teamMembers'>[],
  viewer: Pick<Viewer, 'id'>
) =>
  projects.filter(p => p.teamMembers.map(({ id }) => id).includes(viewer.id))
    .length

const useSelectedClassGroup = (classGroups: Props['classGroups']) => {
  //Guard against a database admin removing someone from class group during an existing session.
  const persistedClassGroupId = localStorage.getItem('persistedClassGroupId')
    ? Number(localStorage.getItem('persistedClassGroupId'))
    : undefined

  const locallyStoredCGIdExistsInDB =
    persistedClassGroupId &&
    classGroups.find(cg => cg.id === persistedClassGroupId)

  if (persistedClassGroupId && !locallyStoredCGIdExistsInDB) {
    localStorage.removeItem('persistedClassGroupId')
  }

  const initialClassGroupId = locallyStoredCGIdExistsInDB
    ? persistedClassGroupId
    : classGroups[0].id

  const [selectedClassGroupId, setSelectedClassGroupId] = React.useState(
    initialClassGroupId
  )

  const selectedClassGroup = classGroups.find(
    cg => cg.id === selectedClassGroupId
  )

  if (!selectedClassGroup) {
    throw new Error('Expected to have selected class group')
  }

  return { selectedClassGroup, setSelectedClassGroupId }
}

const DashboardHeading: React.FunctionComponent<Props> = ({
  classGroups,
  viewer,
  children,
}) => {
  const { selectedClassGroup, setSelectedClassGroupId } = useSelectedClassGroup(
    classGroups
  )

  const [createClassModalIsOpen, setCreateClassModalIsOpen] = useState(false)
  const [manageCoTeacherModalIsOpen, setManageCoTeacherModalIsOpen] = useState(
    false
  )
  const isInstructor = viewer.role === ClassGroupRole.Instructor
  const isClassCreator = selectedClassGroup.classCreatorId
    ? viewer.id === selectedClassGroup.classCreatorId
    : false
  const showCreateButton =
    viewer.role === ClassGroupRole.Instructor ||
    myProjectsCount(selectedClassGroup.projects, viewer) > 0

  return (
    <>
      <PageBackground type={BackgroundType.DashboardSquiggles} />
      <Flex flexDirection="column">
        <Flex
          mt={[7, 0]}
          justifyContent="space-between"
          height={[337, 359]}
          flexDirection="column"
        >
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <LargeProperty title={strings.dashboard.dashboardTitle}>
              {viewer.name || ''}
            </LargeProperty>
            {classGroups.length > 0 && (
              <Flex flexDirection="column" alignItems="flex-start">
                <SelectField
                  onChange={value => {
                    setSelectedClassGroupId(Number(value))
                    setPreferredClassGroup(value)
                  }}
                  options={classGroups.map(cg => ({
                    value: cg.id.toString(),
                    label: cg.name,
                  }))}
                  value={selectedClassGroup.id.toString()}
                  label={strings.dashboard.selectClassLabel}
                />
                {isInstructor && (
                  <>
                    <Box mt="3">
                      <Action
                        onClick={() => {
                          setCreateClassModalIsOpen(true)
                        }}
                      >
                        <SectionTitle color="currentColor">
                          {strings.dashboard.instructor.createAClassButton}
                        </SectionTitle>
                      </Action>
                    </Box>
                    <CreateClassGroupModal
                      open={createClassModalIsOpen}
                      cancelOnClick={() => setCreateClassModalIsOpen(false)}
                    />
                    <ManageCoTeaacherModal
                      open={manageCoTeacherModalIsOpen}
                      cancelOnClick={() => setManageCoTeacherModalIsOpen(false)}
                      classGroupId={selectedClassGroup.id}
                    />
                  </>
                )}
              </Flex>
            )}
          </Flex>
          <Flex auto justifyContent="center" my="5" flexDirection="column">
            <Flex
              alignItems="flex-end"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <LargeProperty title={strings.dashboard.myClassTitle}>
                {selectedClassGroup.name}
              </LargeProperty>
              {showCreateButton && (
                <Flex mt={[5, 0]}>
                  <ButtonLink
                    to={newProjectPath()}
                    state={{ classGroupId: selectedClassGroup.id }}
                  >
                    {strings.dashboard.createProjectButton}
                  </ButtonLink>
                </Flex>
              )}
            </Flex>
            {isInstructor && (
              <>
                <Flex mt="4">
                  <Box mb={[3, 4]}>
                    <SmallProperty title={strings.dashboard.classCodeTitle}>
                      {selectedClassGroup.code}
                    </SmallProperty>
                  </Box>
                </Flex>
                <Flex flexDirection="column">
                  <Flex>
                    <Box mb={[3, 2]}>
                      <SectionTitle>
                        {strings.dashboard.instructor.instructorTitle}
                      </SectionTitle>
                    </Box>
                  </Flex>
                  <Flex>
                    {selectedClassGroup.instructors.map(instructor => (
                      <TeamMember key={instructor.id} {...instructor} />
                    ))}
                    {isClassCreator && (
                      <Action
                        onClick={() => {
                          setManageCoTeacherModalIsOpen(true)
                        }}
                      >
                        <SectionTitle color="currentColor">
                          {strings.dashboard.instructor.manageInstructorsButton}
                        </SectionTitle>
                      </Action>
                    )}
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>

        {children ? children(selectedClassGroup) : null}
      </Flex>
    </>
  )
}

export default DashboardHeading

import { ProjectDetails, ProjectPhaseState } from '../graphql-generated'

enum GlobalGoalIntegerBased {
  NoPoverty = 1,
  ZeroHunger,
  GoodHealthAndWellBeing,
  QualityEducation,
  GenderEquality,
  CleanWaterAndSanitation,
  AffordableAndCleanEnergy,
  DecentWorkAndEconomicGrowth,
  IndustryInnovationAndInfrastructure,
  ReducedInequalities,
  SustainableCitiesAndCommunities,
  ResponsibleConsumptionAndProduction,
  ClimateAction,
  LifeBelowWater,
  LifeOnLand,
  PeaceJusticeAndStrongInstitutions,
  PartnershipsForTheGoals,
}

const calculatePhaseAndStateSummary = (project: ProjectDetails): number => {
  if (project && project.phaseSummary) {
    return (
      calculateStateValue(project.phaseSummary.pitch.state) +
      calculateStateValue(project.phaseSummary.prototype.state) +
      calculateStateValue(project.phaseSummary.ideate.state) +
      calculateStateValue(project.phaseSummary.understand.state)
    )
  }
  return 0
}

const calculateStateValue = (state: string): number => {
  switch (state) {
    case ProjectPhaseState.Approved:
      return 3
      break
    case ProjectPhaseState.Returned:
      return 2
      break
    case ProjectPhaseState.Submitted:
      return 1
      break
    default:
      return 0
  }
}

export const sortProjectsByCompletion = (
  projects: ProjectDetails[]
): ProjectDetails[] => {
  projects.sort(
    (projectA, projectB): number => {
      // Logic in here is written in an inelegant manner to reduce
      // confusion and convolution.  May want to refactor this to be better later.
      // Logic written to prioritize fields to sort by
      if (
        calculatePhaseAndStateSummary(projectA) >
        calculatePhaseAndStateSummary(projectB)
      ) {
        return -1
      }

      if (
        calculatePhaseAndStateSummary(projectA) <
        calculatePhaseAndStateSummary(projectB)
      ) {
        return 1
      }

      if (
        GlobalGoalIntegerBased[projectA.globalGoal] <
        GlobalGoalIntegerBased[projectB.globalGoal]
      ) {
        return -1
      }

      if (
        GlobalGoalIntegerBased[projectA.globalGoal] >
        GlobalGoalIntegerBased[projectB.globalGoal]
      ) {
        return 1
      }

      if (projectA.name < projectB.name) {
        return -1
      }

      if (projectA.name > projectB.name) {
        return 1
      }

      if (projectA.description < projectB.description) {
        return -1
      }

      if (projectA.description > projectB.description) {
        return 1
      }

      return 0
    }
  )
  return projects
}

import React from 'react'
import { Form } from 'react-final-form'
import { get } from 'lodash'
import {
  useJoinClassGroupMutation,
  ClassGroupsDocument,
  ClassGroupsQuery,
} from 'src/graphql-generated'
import { Title1, Body } from './text'
import { Flex, Box } from './Box'
import GroupClassCodeField from './GroupClassCodeField'
import Button from './Button'

const JoinClassGroupForm: React.FunctionComponent = () => {
  const mutate = useJoinClassGroupMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const cachedClassGroups = get(
        cache.readQuery<ClassGroupsQuery>({
          query: ClassGroupsDocument,
        }),
        'viewerClassGroups',
        []
      )

      cache.writeQuery({
        query: ClassGroupsDocument,
        data: {
          viewerClassGroups: [...cachedClassGroups, data.joinClassGroup],
        },
      })
    },
  })

  return (
    <Form
      onSubmit={async ({ code }: any) => {
        if (!code) {
          throw new Error('Invalid code')
        }

        return mutate({
          variables: {
            code,
          },
        })
      }}
      render={({ pristine, valid, submitting, values, handleSubmit }) => {
        const canSubmit =
          !submitting &&
          !pristine &&
          valid &&
          Object.values(values).reduce((acc, v) => acc && Boolean(v), true)
        return (
          <form onSubmit={handleSubmit}>
            <Flex flexDirection="column">
              <Title1 textAlign="center" color="purple">
                Join Class
              </Title1>
              <Body textAlign="center">Enter your 4-digit class code</Body>

              <Box>
                <GroupClassCodeField name="code" />
              </Box>

              <Flex mt="5" justifyContent="center">
                <Button type="submit" disabled={!canSubmit}>
                  Join Class
                </Button>
              </Flex>
            </Flex>
          </form>
        )
      }}
    />
  )
}

export default JoinClassGroupForm

import React from 'react'
import isPropValid from '@emotion/is-prop-valid'
import css from '@emotion/css'

import NoPovertySVG from 'assets/svgs/globalGoals/1-no-poverty.inline.svg'
import ZeroHungerSVG from 'assets/svgs/globalGoals/2-zero-hunger.inline.svg'
import GoodHealthAndWellBeingSVG from 'assets/svgs/globalGoals/3-good-health-and-well-being.inline.svg'
import QualityEducationSVG from 'assets/svgs/globalGoals/4-quality-education.inline.svg'
import GenderEqualitySVG from 'assets/svgs/globalGoals/5-gender-equality.inline.svg'
import CleanWaterAndSanitationSVG from 'assets/svgs/globalGoals/6-clean-water-and-sanitation.inline.svg'
import AffordableAndCleanEnergySVG from 'assets/svgs/globalGoals/7-affordable-and-clean-energy.inline.svg'
import DecentWorkAndEconomicGrowthSVG from 'assets/svgs/globalGoals/8-decent-work-and-economic-growth.inline.svg'
import IndustryInnovationAndInfrastructureSVG from 'assets/svgs/globalGoals/9-industry-innovation-and-infrastructure.inline.svg'
import ReducedInequalitiesSVG from 'assets/svgs/globalGoals/10-reduced-inequalities.inline.svg'
import SustainableCitiesAndCommunitiesSVG from 'assets/svgs/globalGoals/11-sustainable-cities-and-communities.inline.svg'
import ResponsibleConsumptionAndProductionSVG from 'assets/svgs/globalGoals/12-responsible-consumption-and-production.inline.svg'
import ClimateActionSVG from 'assets/svgs/globalGoals/13-climate-action.inline.svg'
import LifeBelowWaterSVG from 'assets/svgs/globalGoals/14-life-below-water.inline.svg'
import LifeOnLandSVG from 'assets/svgs/globalGoals/15-life-on-land.inline.svg'
import PeaceJusticeAndStrongInstitutionsSVG from 'assets/svgs/globalGoals/16-peace-justice-and-strong-institutions.inline.svg'
import PartnershipsForTheGoalsSVG from 'assets/svgs/globalGoals/17-partnerships-for-the-goals.inline.svg'
import { GlobalGoal } from 'src/graphql-generated'
import styled from 'src/styles/styled'
import { theme } from 'src/styles/theme'

const cardHover = (color: string) => css`
    &:hover {
      color: ${theme.colors.white};
      border-color ${color};
      background: ${color};
      pointer: cursor;
    }
`

const createComponent = (
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  color: string
) =>
  styled<'svg', { invert: boolean; asCard: boolean; clickable: boolean }>(
    svg as any,
    {
      shouldForwardProp: isPropValid,
    }
  )`
    color: ${props => (props.invert ? props.theme.colors.white : color)};
    background: ${props => (props.invert ? color : undefined)};
    fill: currentColor;
    box-shadow: ${props =>
      props.asCard ? '2px 5px 12px 0 rgba(0, 0, 0, 0.04)' : undefined};
    border-radius: ${props => (props.asCard ? '4px' : undefined)};
    border: ${props => (props.asCard ? 'solid 2px' : undefined)};
    border-color: ${props =>
      props.invert ? color : props.theme.colors.lightGrey};
    ${props => props.clickable && cardHover(color)};
  `

const svgMap = {
  [GlobalGoal.NoPoverty]: createComponent(NoPovertySVG, '#e5243b'),
  [GlobalGoal.ZeroHunger]: createComponent(ZeroHungerSVG, '#dda63a'),
  [GlobalGoal.GoodHealthAndWellBeing]: createComponent(
    GoodHealthAndWellBeingSVG,
    '#4C9F38'
  ),
  [GlobalGoal.QualityEducation]: createComponent(
    QualityEducationSVG,
    '#C5192D'
  ),
  [GlobalGoal.GenderEquality]: createComponent(GenderEqualitySVG, '#FF3A21'),
  [GlobalGoal.CleanWaterAndSanitation]: createComponent(
    CleanWaterAndSanitationSVG,
    '#26BDE2'
  ),
  [GlobalGoal.AffordableAndCleanEnergy]: createComponent(
    AffordableAndCleanEnergySVG,
    '#FCC30B'
  ),
  [GlobalGoal.DecentWorkAndEconomicGrowth]: createComponent(
    DecentWorkAndEconomicGrowthSVG,
    '#A21942'
  ),
  [GlobalGoal.IndustryInnovationAndInfrastructure]: createComponent(
    IndustryInnovationAndInfrastructureSVG,
    '#FD6925'
  ),
  [GlobalGoal.ReducedInequalities]: createComponent(
    ReducedInequalitiesSVG,
    '#DD1367'
  ),
  [GlobalGoal.SustainableCitiesAndCommunities]: createComponent(
    SustainableCitiesAndCommunitiesSVG,
    '#FD9D24'
  ),
  [GlobalGoal.ResponsibleConsumptionAndProduction]: createComponent(
    ResponsibleConsumptionAndProductionSVG,
    '#BF8B2E'
  ),
  [GlobalGoal.ClimateAction]: createComponent(ClimateActionSVG, '#3F7E44'),
  [GlobalGoal.LifeBelowWater]: createComponent(LifeBelowWaterSVG, '#0A97D9'),
  [GlobalGoal.LifeOnLand]: createComponent(LifeOnLandSVG, '#56C02B'),
  [GlobalGoal.PeaceJusticeAndStrongInstitutions]: createComponent(
    PeaceJusticeAndStrongInstitutionsSVG,
    '#00689D'
  ),
  [GlobalGoal.PartnershipsForTheGoals]: createComponent(
    PartnershipsForTheGoalsSVG,
    '#19486A'
  ),
}

interface Props {
  globalGoal: GlobalGoal
  width?: string | number
  height?: string | number
  invert?: boolean
  asCard?: boolean
  clickable?: boolean
}

const GlobalGoalIcon: React.FunctionComponent<Props> = ({
  globalGoal,
  invert = false,
  asCard = false,
  clickable = false,
  ...props
}) => {
  const Icon = svgMap[globalGoal]
  /* TODO: make accessible */
  return (
    <Icon {...props} invert={invert} asCard={asCard} clickable={clickable} />
  )
}

export default GlobalGoalIcon

import { useState } from 'react'
import {
  ProjectPhase,
  ProjectPhaseState,
  useProjectPhaseStatusQuery,
  useSubmitProjectPhaseMutation,
  useAttachedDocumentsQuery,
  ProjectPhaseHistoryDocument,
} from 'src/graphql-generated'
import { strings } from 'src/strings'
import Button from 'src/components/Button'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import { globalViewerId } from 'src/lib/globalViewerId'
import { trackEvent } from 'src/lib/analytics'
import { SubmitPhaseModal } from './SubmitPhaseModal'

interface Props {
  projectId: number
  phase: ProjectPhase
}

export const SubmitPhaseButton: React.FunctionComponent<Props> = ({
  projectId,
  phase,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const projectAndPhase = {
    projectId,
    projectPhase: phase,
  }

  const {
    loading: statusLoading,
    data: statusData,
    error: statusError,
  } = useProjectPhaseStatusQuery({
    variables: projectAndPhase,
  })

  const {
    loading: documentsLoading,
    data: documentsData,
    error: documentsError,
  } = useAttachedDocumentsQuery({
    variables: projectAndPhase,
  })

  const mutate = useSubmitProjectPhaseMutation()

  const onSubmit = () => {
    mutate({
      variables: {
        input: projectAndPhase,
      },
      optimisticResponse: {
        submitProjectPhase: {
          __typename: 'ProjectPhaseStatus',
          id: statusData && statusData.projectPhaseStatus.id,
          state: ProjectPhaseState.Submitted,
          time: Date.now(),
        },
      },
      refetchQueries: [
        {
          query: ProjectPhaseHistoryDocument,
          variables: projectAndPhase,
        },
      ],
    })

    //globalViewerId.id is set in src/components/Header/index.tsx
    //current-location will be the value of the previous url when this is read
    if (globalViewerId.id !== 0) {
      recordAnalyticsEvent({
        userId: globalViewerId.id,
        event: AnalyticsEvents.PhaseSubmit,
        url: window.location.href,
        type: AnalyticsEventTypes.Project,
        title: projectAndPhase.projectPhase,
        mediaSource: null,
        objectId: projectId.toString(),
        mediaSourceUrl: null,
        referrer: sessionStorage.getItem('current-location'),
        trackStartTime: null,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })
    }

    trackEvent(
      AnalyticsEventTypes.Project,
      AnalyticsEvents.PhaseSubmit,
      projectAndPhase.projectPhase,
      projectId
    )
  }

  const canSubmit =
    !statusLoading &&
    !statusError &&
    statusData &&
    statusData.projectPhaseStatus.state !== ProjectPhaseState.Submitted &&
    !documentsLoading &&
    !documentsError &&
    documentsData &&
    documentsData.attachedDocuments.length > 0

  const documentCount =
    canSubmit && documentsData ? documentsData.attachedDocuments.length : 0

  return (
    <>
      <Button
        data-cy="openSubmitModal"
        type="button"
        variant="secondary"
        disabled={!canSubmit}
        onClick={() => {
          setModalIsOpen(true)
        }}
      >
        {strings.documentStep.submitButtonLabel}
      </Button>

      <SubmitPhaseModal
        open={modalIsOpen}
        closeModal={() => {
          setModalIsOpen(false)
        }}
        documentCount={documentCount}
        onSubmit={onSubmit}
        phase={phase}
      />
    </>
  )
}

import React from 'react'
import ReactPopup, { Props as ReactPopupProps } from 'reactjs-popup'
import { theme } from 'src/styles'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  trigger: JSX.Element
  children: ReactPopupProps['children']
}

const contentStyle = {
  border: `solid 2px ${theme.colors.lightGrey}`,
  boxShadow: '0 6px 30px 0 rgba(0, 0, 0, 0.06)',
  borderRadius: '4px',
  padding: 0,
  width: 'auto',
}
const overlayStyle = {}

const arrowStyle = {
  width: '20px',
  height: '20px',
  boxShadow: `${theme.colors.lightGrey} 1px 2px`,
  marginLeft: '-28px',
  marginTop: '-9px',
}

const Popup: React.FunctionComponent<Props> = ({
  children,
  isOpen,
  onRequestClose,
  trigger,
}) => (
  <ReactPopup
    position="bottom right"
    on="click"
    contentStyle={contentStyle}
    overlayStyle={overlayStyle}
    arrowStyle={arrowStyle}
    offsetX={15}
    offsetY={15}
    trigger={trigger}
    open={isOpen}
    onClose={onRequestClose}
  >
    {children}
  </ReactPopup>
)

export default Popup

import React from 'react'
import css from '@emotion/css'
import { MAX_CONTENT_WIDTH } from 'src/constants'
import { Flex, Box } from 'src/components/Box'
import footerSVG from 'assets/svgs/backgrounds/footer.svg'
import {
  gettingStartGuidePath,
  globalGoalGuidePath,
  resourceGuidePath,
  termsOfUsePath,
  privacyPolicyPath,
  dashboardPath,
  innovatorsGuidePath,
  contactPath,
} from 'src/lib/paths'
import strings from 'src/strings'
import useLoggedIn from 'src/hooks/useLoggedIn'
import Link from './Link'
import { SectionTitle, Body, SmallBody } from './text'
import { WordMark } from './icons'

const footerBackground = css`
  background-image: url(${footerSVG});
  background-position: bottom center;
  background-size: 4000px 1440px;
  background-repeat: no-repeat;
  background-attachment: scroll;
`

interface LinkItem {
  to: string
  text: string
}

const NavList: React.FunctionComponent<{
  title: LinkItem
  items: LinkItem[]
}> = ({ title, items }) => (
  <Flex mt={[5, 0]} as="nav" flexDirection="column">
    <SectionTitle>
      <Link plain to={title.to}>
        {title.text}
      </Link>
    </SectionTitle>
    <Flex display={['none', 'flex']} flexDirection="column">
      {items.map(({ text, to }) => (
        <Flex mt="4" key={[to, text].join('-')}>
          <Body>
            <Link to={to}>{text}</Link>
          </Body>
        </Flex>
      ))}
    </Flex>
  </Flex>
)

const Footer: React.FunctionComponent = () => {
  const { viewer } = useLoggedIn()

  if (!viewer) {
    return null
  }
  return (
    <Flex
      mt="9"
      pt="8"
      minHeight="400px"
      css={footerBackground}
      justifyContent="center"
      as="footer"
      px={4}
      pb={4}
    >
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        width="100vw"
        maxWidth={MAX_CONTENT_WIDTH}
      >
        <Box mt="6" mb={[3, 6]}>
          <WordMark height="30" />
        </Box>
        <Flex
          auto
          flexDirection={['column', 'row']}
          justifyContent={['flex-start', 'space-between']}
        >
          <NavList
            title={{
              to: innovatorsGuidePath(),
              text: strings.footer.innovatorGuideTitle,
            }}
            items={[
              {
                to: gettingStartGuidePath(),
                text: strings.footer.gettingStartedLink,
              },
              {
                to: globalGoalGuidePath(),
                text: strings.footer.globalGoalLink,
              },
              { to: resourceGuidePath(), text: strings.footer.resourceLink },
            ]}
          />
          <NavList
            title={{
              to: dashboardPath(),
              text: strings.footer.dashboardTitle,
            }}
            items={[
              {
                to: dashboardPath('my-projects'),
                text: strings.footer.myProjectLink,
              },
              {
                to: dashboardPath('class-projects'),
                text: strings.footer.classProjectsLink,
              },
            ]}
          />
          <Flex mt={[5, 0]} as="nav" flexDirection="column">
            <SectionTitle>
              <Link target="_blank" plain to={contactPath()}>
                {strings.footer.contactTitle}
              </Link>
            </SectionTitle>
          </Flex>
        </Flex>
        <Flex flexDirection={['column', 'row']}>
          <Box mr={[0, 7]}>
            <SmallBody>
              <Link to={termsOfUsePath()}>{strings.footer.termsLink}</Link>
            </SmallBody>
          </Box>
          <Box mt={[4, 0]}>
            <SmallBody>
              <Link to={privacyPolicyPath()}>{strings.footer.privacyLink}</Link>
            </SmallBody>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Footer

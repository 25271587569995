import {
  useCreateProjectMutation as useBaseCreateProjectMutation,
  ClassGroupQuery,
  ClassGroupDocument,
  useUpdateProjectMutation as useBaseUpdateProjectMutation,
  useDeleteProjectMutation as useBaseDeleteProjectMutation,
} from 'src/graphql-generated'

export const useCreateProjectMutation = () => {
  const mutate = useBaseCreateProjectMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const queryVariables = { id: data.createProject.classGroupId }
      const cachedClassGroup = cache.readQuery<ClassGroupQuery>({
        query: ClassGroupDocument,
        variables: queryVariables,
      })

      if (!cachedClassGroup) {
        return
      }

      cachedClassGroup.viewerClassGroup.projects.push(data.createProject)

      cache.writeQuery({
        query: ClassGroupDocument,
        variables: queryVariables,
        data: cachedClassGroup,
      })
    },
  })

  return mutate
}

export const useUpdateProjectMutation = () => {
  const mutate = useBaseUpdateProjectMutation()

  return mutate
}

export const useDeleteProjectMutation = () => {
  const mutate = useBaseDeleteProjectMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const queryVariables = { id: data.deleteProject.classGroupId }
      const cachedClassGroup = cache.readQuery<ClassGroupQuery>({
        query: ClassGroupDocument,
        variables: queryVariables,
      })

      if (!cachedClassGroup) {
        return
      }

      cachedClassGroup.viewerClassGroup.projects = cachedClassGroup.viewerClassGroup.projects.filter(
        project => project.id !== data.deleteProject.projectId
      )

      cache.writeQuery({
        query: ClassGroupDocument,
        variables: queryVariables,
        data: cachedClassGroup,
      })
    },
  })

  return mutate
}

import ReactModal from 'react-modal'
import { theme } from 'src/styles/theme'

const overlayStyle = {
  maxWidth: '100vw',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 10,
}

const contentStyle = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
  boxShadow: '0 6px 30px 0 rgba(0, 0, 0, 0.06)',
  border: `solid 2px ${theme.colors.lightGrey}`,
  backgroundColor: theme.colors.white,
  padding: 0,
  zIndex: 11,
}

export interface ModalProps {
  open: boolean
}

export const Modal: React.FunctionComponent<ModalProps> = ({
  children,
  open,
}) => (
  <ReactModal
    isOpen={open}
    style={{
      overlay: overlayStyle,
      content: contentStyle,
    }}
  >
    {children}
  </ReactModal>
)

export default Modal

import React from 'react'
import { rgba } from 'polished'
import {
  ProjectDetails,
  ProjectPhase,
  ProjectPhaseState,
} from 'src/graphql-generated'
import { Label } from 'src/components/text'
import { Flex, Grid, PositionContainer } from 'src/components/Box'
import { theme } from 'src/styles'
import {
  CheckmarkIcon,
  PaperclipIcon,
  CommentIcon,
  PitchIcon,
} from 'src/components/icons'
import { Theme } from 'src/styles/theme'
import PitchMaskSVG from 'assets/svgs/backgrounds/pitch.svg'
import strings from 'src/strings'

const PHASE_ICON_SIZE = '14px'
const BOX_SIZE = '26px'
const PITCH_BOX_SIZE = '30px'

interface Props {
  project: ProjectDetails
}

const ProjectProgress: React.FunctionComponent<Props> = ({ project }) => (
  <Grid
    mt="3"
    gridGap="3"
    gridTemplateColumns={`repeat(auto-fit, ${BOX_SIZE})`}
  >
    <PhaseProgress
      phase={ProjectPhase.Understand}
      state={project.phaseSummary.understand.state}
    />
    <PhaseProgress
      phase={ProjectPhase.Ideate}
      state={project.phaseSummary.ideate.state}
    />
    <PhaseProgress
      phase={ProjectPhase.Prototype}
      state={project.phaseSummary.prototype.state}
    />
    <PhaseProgress
      phase={ProjectPhase.Pitch}
      state={project.phaseSummary.pitch.state}
    />
  </Grid>
)

const PhaseContainer: React.FunctionComponent<{
  borderColor?: keyof Theme['colors'] | string
  background?: keyof Theme['colors'] | string
  mask?: string
}> = ({ background, borderColor = 'transparent', children, mask }) => (
  <Flex
    width={BOX_SIZE}
    height={BOX_SIZE}
    justifyContent="center"
    alignItems="center"
    borderRadius="4px"
    border="solid 2px"
    borderColor={borderColor}
    bg={background}
    css={{
      position: 'relative',
      mask,
    }}
  >
    {children}
  </Flex>
)

interface PhaseProps {
  phase: ProjectPhase
}

const pitchMask = `url(${PitchMaskSVG}) no-repeat 50% 50%`

const UnsubmittedPhase: React.FunctionComponent<PhaseProps> = ({ phase }) => {
  const letter = strings.projectPhases[phase].name.charAt(0).toUpperCase()

  if (phase === ProjectPhase.Pitch) {
    return (
      <PhaseContainer>
        <PositionContainer
          width={PITCH_BOX_SIZE}
          height={PITCH_BOX_SIZE}
          position="absolute"
          center
        >
          <PitchIcon width={PITCH_BOX_SIZE} height={PITCH_BOX_SIZE} />
        </PositionContainer>
        <Label ml="2px" color="lightGrey">
          {letter}
        </Label>
      </PhaseContainer>
    )
  }

  return (
    <PhaseContainer borderColor="lightGrey">
      <Label ml="2px" color="lightGrey">
        {letter}
      </Label>
    </PhaseContainer>
  )
}

const SubmittedPhase: React.FunctionComponent<PhaseProps> = ({ phase }) => (
  <PhaseContainer
    mask={phase === ProjectPhase.Pitch ? pitchMask : undefined}
    background={rgba(theme.colors.lightPurple, 0.18)}
  >
    <PaperclipIcon
      color="purple"
      width={PHASE_ICON_SIZE}
      height={PHASE_ICON_SIZE}
    />
  </PhaseContainer>
)

const ReturnedPhase: React.FunctionComponent<PhaseProps> = ({ phase }) => (
  <PhaseContainer
    mask={phase === ProjectPhase.Pitch ? pitchMask : undefined}
    background={rgba(theme.colors.lightOrange, 0.2)}
  >
    <CommentIcon
      color="dullOrange"
      width={PHASE_ICON_SIZE}
      height={PHASE_ICON_SIZE}
    />
  </PhaseContainer>
)

const ApprovedPhase: React.FunctionComponent<PhaseProps> = ({ phase }) => (
  <PhaseContainer
    mask={phase === ProjectPhase.Pitch ? pitchMask : undefined}
    background={rgba(theme.colors.topaz, 0.15)}
  >
    <CheckmarkIcon
      color="topaz"
      width={PHASE_ICON_SIZE}
      height={PHASE_ICON_SIZE}
    />
  </PhaseContainer>
)

export const PhaseProgress: React.FunctionComponent<{
  phase: ProjectPhase
  state: ProjectPhaseState
}> = ({ phase, state }) => {
  switch (state) {
    case ProjectPhaseState.Unsubmitted:
      return <UnsubmittedPhase phase={phase} />

    case ProjectPhaseState.Approved:
      return <ApprovedPhase phase={phase} />

    case ProjectPhaseState.Submitted:
      return <SubmittedPhase phase={phase} />

    case ProjectPhaseState.Returned:
      return <ReturnedPhase phase={phase} />

    // no default
  }
}

export default ProjectProgress

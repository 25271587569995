import React from 'react'
import { get } from 'lodash'
import { ApolloError } from 'apollo-boost'
import { RouteComponentProps } from '@reach/router'

import error500 from 'assets/svgs/error-500.svg'
import strings from 'src/strings'
import { contactPath } from 'src/lib/paths'
import Link from 'src/components/Link'
import ErrorPageLayout from 'src/components/ErrorPageLayout'

interface Props {
  error?: ApolloError
}

const SomethingWentWrong: React.FunctionComponent = () => (
  <ErrorPageLayout
    img={
      <img
        width="400"
        src={error500}
        alt={strings.pageTitles.somethingWentWrong}
      />
    }
    pageTitle={strings.pageTitles.somethingWentWrong}
    title={strings.somethingWentWrong.title}
    body={strings.formatString(strings.somethingWentWrong.body, {
      contactUsLink: (
        <Link target="_blank" to={contactPath()}>
          {strings.somethingWentWrong.contactUsLink}
        </Link>
      ),
      siteLink: <Link to={'/'}>{strings.somethingWentWrong.siteLink}</Link>,
    })}
  />
)

const Forbidden: React.FunctionComponent = () => (
  <ErrorPageLayout
    img={
      <img
        width="400"
        src={error500}
        alt={strings.pageTitles.somethingWentWrong}
      />
    }
    pageTitle={strings.pageTitles.forbidden}
    title={strings.unauthorized.title}
    body={strings.unauthorized.body}
  />
)

const ErrorPage: React.FunctionComponent<RouteComponentProps & Props> = ({
  error,
}) => {
  switch (get(error, 'graphQLErrors[0].extensions.code')) {
    case 'FORBIDDEN':
      return <Forbidden />
    default:
      return <SomethingWentWrong />
  }
}

export default ErrorPage

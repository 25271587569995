import { keyframes } from '@emotion/core'

export const dashOffset = (strokeLength: number, reverse: boolean) => keyframes`
  from {
    stroke-dashoffset: ${reverse ? -strokeLength : strokeLength}%;
  }
  to {
    stroke-dashoffset: 0%;
  }
`

import { Body, LargeTitle } from 'src/components/text'
import { Box } from 'src/components/Box'
import { ProjectPhase } from 'src/lib/project'
import { strings } from 'src/strings'

interface Props {
  projectId: number
  phase: ProjectPhase
}

export const PhaseHeader: React.FunctionComponent<Props> = ({ phase }) => (
  <>
    <LargeTitle>{strings.projectPhases[phase].name}</LargeTitle>
    <Box mt="4">
      <Body>{strings.projectPhases[phase].phaseHeaderDescription}</Body>
    </Box>
  </>
)

import { Match } from '@reach/router'
import { Title2 } from 'src/components/text'
import { Box, Flex, PositionContainer } from 'src/components/Box'
import { Link } from 'src/components/Link'
import { projectPhasePath } from 'src/lib/paths'
import { ProjectPhase, ProjectPhaseStep } from 'src/lib/project'
import strings from 'src/strings'
import { useProjectNotifications } from 'src/hooks/useProjectNotifications'
import { NotificationDot } from 'src/components/Notification'

interface Props {
  projectId: string | number
  phase: ProjectPhase
}
export const Navigation: React.FunctionComponent<Props> = ({
  projectId,
  phase,
}) => {
  const { notifications } = useProjectNotifications()
  const hasNotification =
    notifications.filter(n => n.projectId === projectId && n.phase === phase)
      .length > 0

  return (
    <Flex flexDirection={['row', 'column']} alignItems="flex-start">
      <NavItem
        phase={phase}
        projectId={projectId}
        step={ProjectPhaseStep.Explore}
      />

      <Box mx={[6, 0]} my={[0, 6]}>
        <NavItem
          phase={phase}
          projectId={projectId}
          step={ProjectPhaseStep.Do}
        />
      </Box>

      <NavItem
        hasNotification={hasNotification}
        phase={phase}
        projectId={projectId}
        step={ProjectPhaseStep.Document}
      />
    </Flex>
  )
}

interface NavItemProps extends Props {
  step: ProjectPhaseStep
  hasNotification?: boolean
}

const NavItem: React.FunctionComponent<NavItemProps> = props => (
  <NavItemLinkMaybe {...props}>
    {strings.projectPhaseSteps[props.step].name}
  </NavItemLinkMaybe>
)

const NavItemLinkMaybe: React.FunctionComponent<NavItemProps> = ({
  children,
  phase,
  projectId,
  step,
  hasNotification = false,
}) => (
  <Match path={step}>
    {props =>
      props.match ? (
        <Title2 color="dodgerBlue" as="div">
          {children}
        </Title2>
      ) : (
        <PositionContainer position="relative">
          {hasNotification && (
            <PositionContainer position="absolute" top="5px" right="-10px">
              <NotificationDot size={6} />
            </PositionContainer>
          )}
          <Title2 as="div">
            <Link
              data-cy={step}
              to={projectPhasePath({
                projectId,
                phase,
                step,
              })}
            >
              {children}
            </Link>
          </Title2>
        </PositionContainer>
      )
    }
  </Match>
)

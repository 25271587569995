import React from 'react'
import { RouteComponentProps } from '@reach/router'
import error404 from 'assets/svgs/error-404.svg'
import strings from 'src/strings'
import { dashboardPath, contactPath } from 'src/lib/paths'
import { ButtonLink } from 'src/components/Button'
import useLoggedIn from 'src/hooks/useLoggedIn'
import Link from 'src/components/Link'
import ErrorPageLayout from 'src/components/ErrorPageLayout'
import { Box } from 'src/components/Box'

const NotFound: React.FunctionComponent<RouteComponentProps> = () => {
  const { viewer } = useLoggedIn()
  return (
    <ErrorPageLayout
      pageTitle={strings.pageTitles.notFound}
      title={strings.notFound.title}
      img={
        <Box pl="5">
          <img width="175px" src={error404} alt={strings.pageTitles.notFound} />
        </Box>
      }
      body={strings.formatString(strings.notFound.body, {
        contactUsLink: (
          <Link target="_blank" to={contactPath()}>
            {strings.notFound.contactUsLink}
          </Link>
        ),
        siteLink: <Link to={'/'}>{strings.notFound.siteLink}</Link>,
      })}
    >
      <ButtonLink variant="primary" to={viewer ? dashboardPath() : '/'}>
        {viewer ? strings.notFound.loggedInLink : strings.notFound.defaultLink}
      </ButtonLink>
    </ErrorPageLayout>
  )
}

export default NotFound

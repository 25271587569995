import React from 'react'
import strings from 'src/strings'
import { Form } from 'react-final-form'
import { get } from 'lodash'
import {
  useJoinClassGroupMutation,
  ClassGroupsDocument,
  ClassGroupsQuery,
} from 'src/graphql-generated'
import { setPreferredClassGroup } from 'src/lib/userPreferenceSettings'
import { dashboardPath } from 'src/lib/paths'
import { Title1, Body } from './text'
import { Flex, Box } from './Box'
import Button from './Button'
import { Modal, ModalProps } from './Modal'
import GroupClassCodeField from './GroupClassCodeField'

type ConfirmationModalProps = ModalProps & {
  cancelOnClick: () => void
}

const JoinClassGroupModal: React.FunctionComponent<ConfirmationModalProps> = ({
  open,
  cancelOnClick,
}) => {
  const mutate = useJoinClassGroupMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const cachedClassGroups = get(
        cache.readQuery<ClassGroupsQuery>({
          query: ClassGroupsDocument,
        }),
        'viewerClassGroups',
        []
      )

      cache.writeQuery({
        query: ClassGroupsDocument,
        data: {
          viewerClassGroups: [...cachedClassGroups, data.joinClassGroup],
        },
      })
    },
  })

  return (
    <Modal open={open}>
      <Flex
        maxWidth="410px"
        width="90vw"
        m="8"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        data-cy="modal"
      >
        <Title1 color="purple">{strings.joinClassGroupModal.title}</Title1>

        <Box my="4" width="100%">
          <Form
            onSubmit={async ({ code }: any) => {
              if (!code) {
                throw new Error('Invalid code')
              }

              const returnValue = await mutate({
                variables: {
                  code,
                },
              })

              if (returnValue && returnValue.data) {
                setPreferredClassGroup(returnValue.data.joinClassGroup.id)
              }

              window.location.assign(dashboardPath())
            }}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Flex flexDirection="column">
                    <Body textAlign="center" mb="5">
                      {strings.joinClassGroupModal.subtitle}
                    </Body>

                    <Box>
                      <GroupClassCodeField name="code" />
                    </Box>

                    <Flex
                      mt="5"
                      flexDirection={['column', 'row']}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box mr={[0, 5]} mb={[5, 0]}>
                        <Button
                          data-cy="cancel-joinClassGroup"
                          type="button"
                          minWidth="177px"
                          onClick={cancelOnClick}
                          px={undefined}
                          variant="secondary"
                        >
                          <Flex flexDirection="row" justifyContent="center">
                            {strings.joinClassGroupModal.cancelButtonText}
                          </Flex>
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          data-cy="confirm-joinClassGroup"
                          type="submit"
                          variant="primary"
                          minWidth="177px"
                        >
                          <Flex flexDirection="row" justifyContent="center">
                            {strings.joinClassGroupModal.joinButtonText}
                          </Flex>
                        </Button>
                      </Box>
                    </Flex>
                  </Flex>
                </form>
              )
            }}
          />
        </Box>
      </Flex>
    </Modal>
  )
}

export default JoinClassGroupModal

import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import { Flex, Box, PositionContainer } from 'src/components/Box'
import { TextInput } from './TextInput'
import FieldLabel from './FieldLabel'
import ErrorLabel from './ErrorLabel'

interface Props {
  validating?: boolean
  field: FieldRenderProps<HTMLInputElement> & {
    validating?: boolean
  }
  label?: string
  placeholder?: string
  optional?: boolean
  maxLength?: number
}

const TextField: React.FunctionComponent<Props> = ({
  label,
  field,
  placeholder,
  optional = false,
  maxLength,
}) => {
  const showValid =
    !field.validating &&
    Boolean(field.meta.valid) &&
    !(optional && !field.input.value)

  const showError =
    !field.validating && Boolean(field.meta.error && field.meta.touched)

  return (
    <Flex pb="5" css={{ position: 'relative' }} flexDirection="column">
      {label && (
        <Box mb="4">
          <FieldLabel
            optional={optional}
            active={field.meta.active}
            htmlFor={field.input.name}
          >
            {label}
          </FieldLabel>
        </Box>
      )}

      <TextInput
        data-cy={field.input.name}
        placeholder={placeholder}
        error={showError}
        valid={showValid}
        maxLength={maxLength}
        {...field.input}
      />

      {showError && (
        <PositionContainer position="absolute" bottom="0">
          <ErrorLabel>{field.meta.error}</ErrorLabel>
        </PositionContainer>
      )}
    </Flex>
  )
}

export default TextField

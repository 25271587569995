import { Text, Block, Inline } from '@contentful/rich-text-types'

export const findYouTubeMarkup = (node: Block | Inline) => {
  if (isTextContent(node.content)) {
    const text = node.content[0]
    return findYouTubeMarkupWithinString(text.value)
  }

  return null
}

export const findYouTubeMarkupWithinString = (str: string) => {
  const youTubeRegex = /^\s*\[youtube:(.*)\]\s*$/ // ignore leading and trailing whitespace

  const regexResult = youTubeRegex.exec(str)

  return regexResult ? regexResult[1] : null
}

function isTextContent(
  content?: Array<Inline | Text> | Array<Block | Inline | Text>
): content is Array<Text> {
  return !!content && content.length === 1 && content[0].nodeType === 'text'
}

import { Router, RouteComponentProps } from '@reach/router'
import Helmet from 'react-helmet'

import { ProjectDetails } from 'src/graphql-generated'
import { Box, Flex, PositionContainer } from 'src/components/Box'
import {
  ProjectPhase as ProjectPhaseEnum,
  ProjectPhaseStep,
} from 'src/lib/project'
import NotFound from 'src/pages/NotFound'
import { PageBackground, BackgroundType } from 'src/components/PageBackground'
import strings from 'src/strings'
import { SectionTitle } from 'src/components/text'
import { BackToProjectOverview } from './BackToProjectOverview'
import { DoStep } from './DoStep'
import { DocumentStep } from './DocumentStep'
import { ExploreStep } from './ExploreStep'
import { Navigation } from './Navigation'
import { PhaseHeader } from './PhaseHeader'

interface Props {
  project: ProjectDetails
  phase: ProjectPhaseEnum
}

const ProjectPhase: React.FunctionComponent<Props & RouteComponentProps> = ({
  project,
  phase,
}) => {
  const titleParts = [
    strings.projectPhases[phase].name,
    project.name,
    strings.pageTitles.phaseSuffix,
  ]

  return (
    <>
      <Helmet
        title={titleParts.join(' - ')}
        titleTemplate={['%s', ...titleParts].join(' - ')}
      />
      <PageBackground type={BackgroundType.yellowCurveConcaveStreamlined} />
      <Flex flexDirection="column">
        <Box mb={[8, 9]}>
          <BackToProjectOverview projectId={project.id} />
          <SectionTitle breakWord mt="3">
            {project.name}
          </SectionTitle>
        </Box>
        <Box maxWidth="480px" width="100%" pb={6}>
          <PhaseHeader projectId={project.id} phase={phase} />
        </Box>
        <Flex
          flexDirection={['column', 'row']}
          pt={[4, 6]}
          css={theme => ({
            borderTop: '1px solid',
            borderTopColor: theme.colors.lightGrey,
          })}
          alignItems="flex-start"
        >
          <PositionContainer
            position={[null, 'sticky']}
            top={[null, '32px']}
            maxWidth={[null, '140px']}
            width="100%"
            pb="6"
            as="nav"
          >
            <Navigation projectId={project.id} phase={phase} />
          </PositionContainer>
          <Box width="100%">
            <StepRouter project={project} phase={phase} />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

const StepRouter: React.FunctionComponent<Props> = ({ project, phase }) => (
  <Router primary={false}>
    <ExploreStep path={ProjectPhaseStep.Explore} phase={phase} />
    <DoStep path={ProjectPhaseStep.Do} phase={phase} />
    <DocumentStep
      path={ProjectPhaseStep.Document}
      project={project}
      phase={phase}
    />
    <NotFound default />
  </Router>
)

export default ProjectPhase

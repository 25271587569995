import React from 'react'
import Helmet from 'react-helmet'
import { PAGE_LAYOUT_CONTENT_WIDTH } from 'src/constants.ts'
import { Box, Flex } from 'src/components/Box'
import { Title1 } from 'src/components/text'

interface Props {
  title?: string
}

const PageLayout: React.FunctionComponent<Props> = ({ title, children }) => (
  <Flex flexDirection="column" alignItems="center">
    {title && <Helmet title={title} />}

    <Box maxWidth={PAGE_LAYOUT_CONTENT_WIDTH} width="100%" as="article">
      {title && <Title1 mb="4">{title}</Title1>}

      {children}
    </Box>
  </Flex>
)

export default PageLayout

import {
  useViewerProjectNotificationsQuery,
  useRemoveProjectNotificationsMutation,
  ViewerProjectNotificationsDocument,
  ProjectPhase,
} from 'src/graphql-generated'
import { minuteToMs } from 'src/lib/time'

const NOTIFICATION_INTERVAL_MS = minuteToMs(2)

export const useProjectNotifications = () => {
  const { data, refetch } = useViewerProjectNotificationsQuery({
    pollInterval: NOTIFICATION_INTERVAL_MS,
  })

  const notifications =
    data && data.viewerProjectNotifications
      ? data.viewerProjectNotifications
      : []

  return { notifications, refetch }
}

export const useProjectPhaseNotification = (
  projectId: number,
  projectPhase: ProjectPhase
) => {
  const { notifications, refetch } = useProjectNotifications()
  const notification = notifications.find(
    n => n.projectId === projectId && n.phase === projectPhase
  )

  return { notification, refetch }
}

export const useRemoveProjectNotifications = () => {
  const mutate = useRemoveProjectNotificationsMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      cache.writeQuery({
        query: ViewerProjectNotificationsDocument,
        data: {
          viewerProjectNotifications: data.removeProjectNotifications,
        },
      })
    },
  })
  return mutate
}

import { Title1 } from './text'
import { Flex, Box } from './Box'
import Button from './Button'
import { Modal, ModalProps } from './Modal'
import { Icon } from './icons'

type ConfirmationModalProps = ModalProps & {
  cancelLabel: string
  cancelOnClick: () => void
  cancelIcon?: Icon
  confirmLabel: string
  confirmOnClick: () => void
  confirmIcon?: Icon
  title: string
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  cancelIcon: CancelIcon,
  cancelLabel,
  cancelOnClick,
  children,
  confirmIcon: ConfirmIcon,
  confirmLabel,
  confirmOnClick,
  open,
  title,
}) => (
  <Modal open={open}>
    <Flex
      maxWidth="410px"
      width="90vw"
      m="8"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-cy="modal"
    >
      <Title1 color="purple">{title}</Title1>

      <Box my="4" width="100%">
        {children}
      </Box>

      <Flex mt="6" flexDirection={['column', 'row']}>
        <Box mr={[0, 5]} mb={[5, 0]}>
          <Button
            data-cy="cancel"
            type="button"
            minWidth="177px"
            onClick={cancelOnClick}
            px={CancelIcon ? 3 : undefined}
            variant="secondary"
          >
            <Flex flexDirection="row" justifyContent="center">
              {CancelIcon && (
                <Box pr="3" height="13px">
                  <CancelIcon color="dodgerBlue" width={17} height={17} />
                </Box>
              )}
              {cancelLabel}
            </Flex>
          </Button>
        </Box>
        <Box>
          <Button
            data-cy="confirm"
            type="button"
            minWidth="177px"
            px={ConfirmIcon ? 3 : undefined}
            onClick={confirmOnClick}
          >
            <Flex flexDirection="row" justifyContent="center">
              {ConfirmIcon && (
                <Box pr="3" height="13px">
                  <ConfirmIcon color="white" width={17} height={17} />
                </Box>
              )}
              {confirmLabel}
            </Flex>
          </Button>
        </Box>
      </Flex>
    </Flex>
  </Modal>
)

export default ConfirmationModal

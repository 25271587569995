import { createClient, ContentfulClientApi } from 'contentful'

let client: ContentfulClientApi

export const getContentfulClient = () => {
  if (!client) {
    client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
    })
  }

  return client
}

let previewClient: ContentfulClientApi

export const getContentfulPreviewClient = () => {
  if (!previewClient) {
    previewClient = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
      accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN || '',
      host: 'preview.contentful.com',
    })
  }

  return previewClient
}

import React from 'react'

import { LargeTitle, Body } from 'src/components/text'
import { Flex, Box, PositionContainer } from 'src/components/Box'
import {
  ArrowTopLeftToRightIcon,
  ArrowTopRightToLeftIcon,
  ArrowBottomLeftToRightIcon,
  ArrowBottomRightToLeftIcon,
} from 'src/components/icons'
import strings from 'src/strings'
import { useProjectNotifications } from 'src/hooks/useProjectNotifications'
import { ProjectPhase, ProjectPhaseStep } from 'src/lib/project'
import { projectPhasePath } from 'src/lib/paths'
import { ProjectDetails, ProjectNotification } from 'src/graphql-generated'
import PhaseCard from './PhaseCard'
import PitchPhaseCard from './PitchPhaseCard'

interface Props {
  project: Pick<ProjectDetails, 'id' | 'phaseSummary'>
  canEdit: boolean
}

const cardProps = (
  project: Pick<ProjectDetails, 'id' | 'phaseSummary'>,
  phase: ProjectPhase,
  projectNotifications: ProjectNotification[]
) => ({
  phase,
  phaseStatus: project.phaseSummary[phase],
  toDocument: projectPhasePath({
    projectId: project.id,
    phase,
    step: ProjectPhaseStep.Document,
  }),
  toExplore: projectPhasePath({
    projectId: project.id,
    phase,
    step: ProjectPhaseStep.Explore,
  }),
  hasNotification:
    projectNotifications.filter(notification => notification.phase === phase)
      .length > 0,
  ...strings.projectPhases[phase],
})

const PhaseCards: React.FunctionComponent<Props> = ({ project, canEdit }) => {
  const { notifications } = useProjectNotifications()
  const notificationsForProject = notifications.filter(
    n => n.projectId === project.id
  )
  return (
    <>
      <Box mb="4">
        <LargeTitle>{strings.projectOverview.phasesTitle}</LargeTitle>
      </Box>
      <Box width="100%">
        <Body>{strings.projectOverview.phasesDescription}</Body>
      </Box>
      <ArrowsTop />
      <Flex
        flexDirection="row"
        justifyContent="space-around"
        alignItems="flex-start"
        flexWrap="wrap"
        mt={[5, 0]}
      >
        <Box mt={[4, 0]} m="2">
          <PhaseCard
            canEdit={canEdit}
            {...cardProps(
              project,
              ProjectPhase.Understand,
              notificationsForProject
            )}
          />
        </Box>
        <Box mt={[4, 0]} m="2">
          <PhaseCard
            canEdit={canEdit}
            {...cardProps(
              project,
              ProjectPhase.Ideate,
              notificationsForProject
            )}
          />
        </Box>
        <Box mt={[4, 0]} m="2">
          <PhaseCard
            canEdit={canEdit}
            {...cardProps(
              project,
              ProjectPhase.Prototype,
              notificationsForProject
            )}
          />
        </Box>
      </Flex>
      <ArrowsBottom />
      <Flex flexDirection="row" justifyContent="space-around">
        <Box mt={[4, 0]} m="2">
          <PitchPhaseCard
            canEdit={canEdit}
            {...cardProps(project, ProjectPhase.Pitch, notificationsForProject)}
          />
        </Box>
      </Flex>
    </>
  )
}
const ArrowsTop = () => (
  <PositionContainer
    position="relative"
    width="100%"
    height="96px"
    display={['none', 'block']}
    zIndex={-1}
  >
    <PositionContainer position="absolute" left="27%" right="14%" bottom="10%">
      <ArrowTopRightToLeftIcon />
    </PositionContainer>
    <PositionContainer position="absolute" left="55%" right="28%" bottom="0%">
      <ArrowTopLeftToRightIcon />
    </PositionContainer>
  </PositionContainer>
)

const ArrowsBottom = () => (
  <PositionContainer
    position="relative"
    width="100%"
    height="64px"
    display={['none', 'none', 'block']}
    zIndex={-1}
  >
    <PositionContainer
      position="absolute"
      left="65%"
      top="-9px"
      bottom="5%"
      right="20%"
    >
      <ArrowBottomRightToLeftIcon />
    </PositionContainer>
    <PositionContainer position="absolute" left="20%" top="10px" right="60%">
      <ArrowBottomLeftToRightIcon />
    </PositionContainer>
  </PositionContainer>
)

export default PhaseCards

import React from 'react'

import { Flex, Box, PositionContainer } from 'src/components/Box'
import pitchMaskSVG from 'assets/svgs/backgrounds/pitch.svg'
import { PitchIcon } from 'src/components/icons'
import { Theme } from 'src/styles/theme'
import { Link } from 'src/components/Link'
import { ProjectPhaseState } from 'src/graphql-generated'
import { NotificationDot } from 'src/components/Notification'

import CardContent from './CardContent'
import { PitchPhaseCardLabel } from './PhaseCardLabel'
import { APPROVED_BACKGROUND_COLOR, PhaseCardProps } from './PhaseCard'

const pitchBorderColor = (state: ProjectPhaseState): keyof Theme['colors'] => {
  switch (state) {
    case ProjectPhaseState.Unsubmitted:
      return 'dullYellow'
    case ProjectPhaseState.Submitted:
      return 'lightPurple'
    case ProjectPhaseState.Returned:
      return 'lightOrange'
    case ProjectPhaseState.Approved:
      return 'topaz'
    // no default
  }
}

const pitchBackgroundColor = (state: ProjectPhaseState): string => {
  switch (state) {
    case ProjectPhaseState.Unsubmitted:
    case ProjectPhaseState.Submitted:
    case ProjectPhaseState.Returned:
      return 'white'
    case ProjectPhaseState.Approved:
      return APPROVED_BACKGROUND_COLOR
    // no default
  }
}

const PitchPhaseCard: React.FunctionComponent<PhaseCardProps> = ({
  cardDescription,
  name,
  phase,
  toDocument,
  toExplore,
  canEdit,
  phaseStatus,
  hasNotification,
}) => (
  <PositionContainer position="relative">
    {hasNotification && (
      <PositionContainer zIndex={2} position="absolute" top="60px" left="20px">
        <NotificationDot />
      </PositionContainer>
    )}
    <PositionContainer css={{ marginTop: '2px' }} position="absolute" center>
      <PitchIcon
        color={pitchBorderColor(phaseStatus.state)}
        width="286px"
        height="286px"
      />
    </PositionContainer>
    <Box
      width="280px"
      height="280px"
      p="8"
      css={{
        textAlign: 'center',
        backgroundColor: pitchBackgroundColor(phaseStatus.state),
        mask: `url(${pitchMaskSVG}) no-repeat 50% 50%`,
      }}
    >
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Link
          css={{ flex: '1 1 auto' }}
          plain
          as={canEdit ? undefined : 'div'}
          to={toExplore}
        >
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <CardContent
              canEdit={canEdit}
              name={name}
              cardDescription={cardDescription}
              phase={phase}
            />
          </Flex>
        </Link>
        {phaseStatus.state !== ProjectPhaseState.Unsubmitted && (
          <Flex mt="4" justifyContent="center">
            <Link plain as={canEdit ? undefined : 'div'} to={toDocument}>
              <PitchPhaseCardLabel state={phaseStatus.state} />
            </Link>
          </Flex>
        )}
      </Flex>
    </Box>
  </PositionContainer>
)

export default PitchPhaseCard

import React from 'react'
import { Title1, Body } from 'src/components/text'
import { Flex, Grid } from 'src/components/Box'
import {
  ProjectDetailsWithNotification,
  sortByNotification,
} from 'src/lib/project'
import ProjectCard from './ProjectCard'

interface Props {
  title: string
  projects: ProjectDetailsWithNotification[]
  cardsAreLinked?: boolean
  showAvatars?: boolean
}

const ProjectsLayout: React.FunctionComponent<Props> = ({
  title,
  projects,
  cardsAreLinked,
  showAvatars,
}) => (
  <Flex flexDirection="column" mt="3">
    <Title1>{title}</Title1>
    <Grid mt="7" gridGap="6" gridTemplateColumns="repeat(auto-fit, 370px)">
      {projects.length === 0 && <Body>No projects found</Body>}
      {projects.sort(sortByNotification).map(p => (
        <ProjectCard
          key={p.id}
          project={p}
          isLinkable={cardsAreLinked}
          showAvatars={showAvatars}
        />
      ))}
    </Grid>
  </Flex>
)

export default ProjectsLayout

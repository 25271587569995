import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Loading from 'src/components/Loading'
import PageLayout from 'src/components/PageLayout'
import { PageModel } from 'src/contentful/models'
import renderRichTextDocument from 'src/contentful/renderRichTextDocument'
import { ContentfulPageEntryId } from 'src/contentful/entryIds'
import useContentfulEntry from 'src/hooks/useContentfulEntry'
import ErrorPage from 'src/pages/ErrorPage'

type Props = RouteComponentProps<{
  entryId: ContentfulPageEntryId | string // string for preview entryId
  preview?: boolean
}>

const ContentfulPage: React.FunctionComponent<Props> = ({
  entryId,
  preview,
}) => {
  if (!entryId) {
    throw new Error('Expected entry id')
  }

  const { loading, error, fields } = useContentfulEntry<PageModel>(
    entryId,
    preview
  )

  if (loading) {
    return (
      <PageLayout>
        <Loading />
      </PageLayout>
    )
  }

  if (error || !fields) {
    return <ErrorPage error={error} />
  }

  return (
    <PageLayout title={fields.title}>
      {renderRichTextDocument(fields.body)}
    </PageLayout>
  )
}

export default ContentfulPage

import { Box } from 'src/components/Box'
import styled from 'src/styles/styled'
import { standardMargin } from './index'

export const DeduplicateExaggeratedMargins = styled(Box)`
  h3 + h4 {
    margin-top: ${props => props.theme.space[standardMargin]}px;
  }

  h3:first-child,
  h4:first-child /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
    /* https://github.com/emotion-js/emotion/pull/1209 */
    margin-top: ${props => props.theme.space[standardMargin]}px;
  }
`

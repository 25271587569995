import { Global, css, SerializedStyles } from '@emotion/core'
import yellowCurveSVG from 'assets/svgs/backgrounds/yellowCurve.svg'
import yellowConcaveCurveSVG from 'assets/svgs/backgrounds/yellowCurveConcave.svg'
import yellowConcaveStreamlinedCurveSVG from 'assets/svgs/backgrounds/yellowCurveConcaveStreamlined.svg'
import onboardingSquiggles from 'assets/svgs/backgrounds/onboarding-squiggles.svg'
import dashboardSquiggles from 'assets/svgs/backgrounds/dashboard-squiggles.svg'
import purpleCurve from 'assets/svgs/backgrounds/innovators-guide.svg'

export enum BackgroundType {
  YellowCurveConvex,
  YellowCurveConcave,
  yellowCurveConcaveStreamlined,
  PurpleCurve,
  DashboardSquiggles,
  Onboarding,
  None,
}

const properties = css`
  background-position: top center;
  background-size: 2560px 1440px;
  background-repeat: no-repeat;
  background-attachment: scroll;
`

const backgroundMap: Record<BackgroundType, SerializedStyles> = {
  [BackgroundType.None]: css`
    background-image: none;
  `,
  [BackgroundType.YellowCurveConvex]: css`
    background-image: url(${yellowCurveSVG});
    ${properties}
  `,
  [BackgroundType.YellowCurveConcave]: css`
    background-image: url(${yellowConcaveCurveSVG});
    ${properties}
  `,
  [BackgroundType.yellowCurveConcaveStreamlined]: css`
    background-image: url(${yellowConcaveStreamlinedCurveSVG});
    ${properties}
  `,
  [BackgroundType.DashboardSquiggles]: css`
    background-image: url(${dashboardSquiggles});
    ${properties}
    background-size: 4000px 1440px;
  `,
  [BackgroundType.PurpleCurve]: css`
    background-image: url(${purpleCurve});
    ${properties}
    background-size: 4000px 1440px;
  `,
  [BackgroundType.Onboarding]: css`
    background-image: url(${onboardingSquiggles});
    ${properties}
  `,
}

interface Props {
  type: BackgroundType
}

export const PageBackground: React.FunctionComponent<Props> = ({ type }) => (
  <Global
    styles={{
      body: backgroundMap[type],
    }}
  />
)

export default PageBackground

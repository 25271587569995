import { theme } from 'src/styles/theme'
import css from '@emotion/css'

export const breakWordStyle = css`
  word-break: break-word;
`

export const lineClampStyle = (lines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const truncateStyle = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const body = css`
  font-family: ${theme.fonts.mavenPro};
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
`

export const smallBody = css`
  font-family: ${theme.fonts.mavenPro};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.36;
`

export const largeTitle = css`
  font-family: ${theme.fonts.barlow};
  font-size: 34px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.12;
`

export const title1 = css`
  font-family: ${theme.fonts.barlow};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.25;
`

export const title2 = css`
  font-family: ${theme.fonts.barlow};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.67;
`

export const sectionTitle = css`
  font-family: ${theme.fonts.karla};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.4px;
  line-height: 1.43;
  text-transform: uppercase;
`

export const label = css`
  font-family: ${theme.fonts.karla};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 1.31;
  text-transform: uppercase;
`

export const buttonLabel = css`
  ${label}
  letter-spacing: 1.3px;
`

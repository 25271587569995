import React, { useState } from 'react'
import strings from 'src/strings'
import { Form, FormSpy } from 'react-final-form'
import {
  useUpdateClassGroupInstructorsMutation,
  ClassGroupsDocument,
  ClassGroupsQuery,
} from 'src/graphql-generated'
import { dashboardPath } from 'src/lib/paths'
import { get } from 'lodash'
import useLoggedIn from 'src/hooks/useLoggedIn'
import TeacherListField from './TeacherListField'
import { Title1, Body } from './text'
import { Flex, Box } from './Box'
import Button from './Button'
import { Modal, ModalProps } from './Modal'

type ConfirmationModalProps = ModalProps & {
  cancelOnClick: () => void
  classGroupId: number
}

const ManageCoTeacherModal: React.FunctionComponent<ConfirmationModalProps> = ({
  open,
  cancelOnClick,
  classGroupId,
}) => {
  const mutate = useUpdateClassGroupInstructorsMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const cachedClassGroups = get(
        cache.readQuery<ClassGroupsQuery>({
          query: ClassGroupsDocument,
        }),
        'viewerClassGroups',
        []
      )

      cache.writeQuery({
        query: ClassGroupsDocument,
        data: {
          viewerClassGroups: [
            ...cachedClassGroups,
            data.updateClassGroupInstructors,
          ],
        },
      })
    },
  })

  const { viewer } = useLoggedIn()
  const [saving, setSaving] = useState(false)

  return (
    <Modal open={open}>
      <Flex
        maxWidth="410px"
        width="90vw"
        m="8"
        flexDirection="column"
        alignItems="center"
        justifyContent="top"
        data-cy="modal"
      >
        <Title1 color="purple">{strings.manageCoTeacherModal.title}</Title1>

        <Box my="4" width="100%">
          <Form
            onSubmit={async ({ instructorIds }: any) => {
              setSaving(true)

              await mutate({
                variables: {
                  memberIds: instructorIds,
                  classGroupId: classGroupId,
                },
              })

              //Reload the dashboard
              window.location.assign(dashboardPath())
            }}
            render={({ handleSubmit }) => {
              return (
                <FormSpy subscription={{ pristine: true }}>
                  {props => (
                    <form onSubmit={handleSubmit}>
                      <Flex flexDirection="column">
                        <Flex alignItems="center" justifyContent="center">
                          <Body mb="5">
                            {strings.manageCoTeacherModal.subTitle}
                          </Body>
                        </Flex>
                        <Box mb="10">
                          {viewer && (
                            <TeacherListField
                              name="instructorIds"
                              classGroupId={classGroupId}
                              viewer={viewer}
                            />
                          )}
                        </Box>

                        <Flex
                          mt="4"
                          flexDirection={['column', 'row']}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box mr={[0, 5]} mb={[5, 0]}>
                            <Button
                              data-cy="cancel-manageCoTeachers"
                              type="button"
                              minWidth="177px"
                              onClick={cancelOnClick}
                              px={undefined}
                              variant="secondary"
                            >
                              <Flex flexDirection="row" justifyContent="center">
                                {strings.manageCoTeacherModal.cancelButtonText}
                              </Flex>
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              data-cy="confirm-manageCoTeachers"
                              type="submit"
                              variant="primary"
                              minWidth="177px"
                              disabled={props.pristine || saving}
                            >
                              <Flex flexDirection="row" justifyContent="center">
                                {strings.manageCoTeacherModal.createButtonText}
                              </Flex>
                            </Button>
                          </Box>
                        </Flex>
                      </Flex>
                    </form>
                  )}
                </FormSpy>
              )
            }}
          />
        </Box>
      </Flex>
    </Modal>
  )
}

export default ManageCoTeacherModal

import React from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { Viewer } from 'src/graphql-generated'
import useLoggedIn from 'src/hooks/useLoggedIn'
import Loading from './Loading'

interface Props extends RouteComponentProps {
  component:
    | React.ComponentType<AuthenticatedRouteProps>
    | React.LazyExoticComponent<React.ComponentType<AuthenticatedRouteProps>>
}

export type AuthenticatedRouteProps<RouteParams = {}> = RouteComponentProps<
  RouteParams
> & { viewer: Viewer }

export const AuthenticatedRoute: React.FunctionComponent<Props> = ({
  component: Component,
  ...props
}) => {
  const { viewer, error, loading } = useLoggedIn()

  if (loading) {
    return <Loading />
  }

  if (error || !viewer) {
    return <Redirect noThrow to="/" />
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <Component {...props as any} viewer={viewer} />
    </React.Suspense>
  )
}

export default AuthenticatedRoute

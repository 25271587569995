import { get, uniqBy } from 'lodash'

import {
  AttachedDocumentsDocument,
  AttachedDocumentsQuery,
  useAttachDocumentMutation,
  useAttachedDocumentsQuery,
  ProjectPhase,
} from 'src/graphql-generated'

export const useDocumentsQueryAndMutation = ({
  projectId,
  projectPhase,
}: {
  projectId: number
  projectPhase: ProjectPhase
}) => {
  const { loading: loadingDocuments, data } = useAttachedDocumentsQuery({
    variables: { projectId, projectPhase },
  })

  const attachedDocuments = (data && data.attachedDocuments) || []

  const attachDocumentMutation = useAttachDocumentMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const cachedDocuments = get(
        cache.readQuery<AttachedDocumentsQuery>({
          query: AttachedDocumentsDocument,
          variables: { projectId, projectPhase },
        }),
        'attachedDocuments',
        []
      )

      cache.writeQuery({
        query: AttachedDocumentsDocument,
        variables: { projectId, projectPhase },
        data: {
          attachedDocuments: uniqBy(
            [...cachedDocuments, data.attachDocument],
            d => `${d.service}-${d.serviceId}`
          ),
        },
      })
    },
  })

  return {
    loadingDocuments,
    attachedDocuments,
    attachDocumentMutation,
  }
}

import { ProjectPhase } from 'src/graphql-generated'
import { strings } from 'src/strings'
import { Body } from 'src/components/text'
import pluralize from 'src/lib/pluralize'
import ConfirmationModal from 'src/components/ConfirmationModal'

interface Props {
  closeModal: () => void
  documentCount: number
  onSubmit: () => void
  open: boolean
  phase: ProjectPhase
}

export const SubmitPhaseModal: React.FunctionComponent<Props> = ({
  closeModal,
  documentCount,
  onSubmit,
  open,
  phase,
}) => (
  <ConfirmationModal
    cancelLabel={strings.submitPhaseModal.cancelButtonLabel}
    cancelOnClick={closeModal}
    confirmLabel={strings.submitPhaseModal.submitButtonLabel}
    confirmOnClick={() => {
      onSubmit()
      closeModal()
    }}
    title={
      strings.formatString(strings.submitPhaseModal.title, {
        phaseName: strings.projectPhases[phase].name,
      }) as string
    }
    open={open}
  >
    <Body textAlign="center">
      {strings.formatString(strings.submitPhaseModal.body, {
        phaseName: strings.projectPhases[phase].name,
        isPluralize: pluralize(
          documentCount,
          strings.submitPhaseModal.isSingular,
          strings.submitPhaseModal.isPlural
        ),
        count: documentCount.toString(),
        documentPluralize: pluralize(
          documentCount,
          strings.submitPhaseModal.documentSingular
        ),
      })}
    </Body>
  </ConfirmationModal>
)

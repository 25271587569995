import React from 'react'

import { Box } from 'src/components/Box'
import { Body, Label, SmallBody } from 'src/components/text'
import {
  ProjectPhase,
  useProjectPhaseHistoryQuery,
  ProjectPhaseState,
  ProjectPhaseHistoryEntry,
} from 'src/graphql-generated'
import Loading from 'src/components/Loading'
import strings from 'src/strings'
import { dateToString } from 'src/lib/date'
import {
  useRemoveProjectNotifications,
  useProjectPhaseNotification,
} from 'src/hooks/useProjectNotifications'
import ErrorPage from 'src/pages/ErrorPage'

interface Props {
  projectId: number
  projectPhase: ProjectPhase
}

const PhaseStateLabel: React.FunctionComponent<{
  state: ProjectPhaseState
}> = ({ state }) => {
  switch (state) {
    case ProjectPhaseState.Approved:
      return (
        <Label color="turquoise">{strings.projectStatusHistory[state]}</Label>
      )
    case ProjectPhaseState.Returned:
      return (
        <Label color="dullOrange">{strings.projectStatusHistory[state]}</Label>
      )
    case ProjectPhaseState.Submitted:
      return <Label color="purple">{strings.projectStatusHistory[state]}</Label>
    default:
      return null
  }
}

const PhaseAction: React.FunctionComponent<{
  entry: ProjectPhaseHistoryEntry
}> = ({ entry }) => {
  return (
    <Box mt="4" mb="6">
      <PhaseStateLabel state={entry.state} />
      <Box my="1">
        <SmallBody color="mediumGrey">
          {dateToString(new Date(entry.time))}
        </SmallBody>
      </Box>
      {entry.comment && (
        <Box my="3">
          <Body breakWord>{entry.comment}</Body>
        </Box>
      )}
    </Box>
  )
}

export const PhaseHistory: React.FunctionComponent<Props> = ({
  projectId,
  projectPhase,
}) => {
  const { notification } = useProjectPhaseNotification(projectId, projectPhase)

  const mutate = useRemoveProjectNotifications()

  const {
    loading,
    data,
    error,
    refetch: refetchHistory,
  } = useProjectPhaseHistoryQuery({
    variables: {
      projectId,
      projectPhase,
    },
  })

  React.useEffect(() => {
    if (notification) {
      mutate({ variables: { ids: [notification.id] } })
      refetchHistory()
    }
  }, [(notification && notification.time) || 0])

  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <ErrorPage error={error} />
  }

  if (data.projectPhaseHistory.length === 0) {
    return (
      <SmallBody my="3" color="mediumGrey">
        {strings.documentStep.placeholderNoFeedback}
      </SmallBody>
    )
  }

  return (
    <>
      {data.projectPhaseHistory.map(entry => (
        <PhaseAction entry={entry} key={entry.id} />
      ))}
    </>
  )
}

import React from 'react'
import { get } from 'lodash'
import {
  BLOCKS,
  Document as RichTextDocument,
  INLINES,
} from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  RenderNode,
} from '@contentful/rich-text-react-renderer'

import { PAGE_LAYOUT_CONTENT_WIDTH } from 'src/constants.ts'
import { Box } from 'src/components/Box'
import {
  Body,
  Title1,
  Title2,
  SectionTitle,
  LargeTitle,
  OrderedList,
  UnorderedList,
  ListItem,
} from 'src/components/text'
import { Link, isExternalLink } from 'src/components/Link'
import Image from 'src/components/Image'
import YouTubePlayer from 'src/components/YouTubePlayer'
import styled from 'src/styles/styled'

import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import { globalViewerId } from 'src/lib/globalViewerId'
import { trackEvent } from 'src/lib/analytics'
import { findYouTubeMarkup } from './findYouTubeMarkup'
import { DeduplicateExaggeratedMargins } from './DeduplicateExaggeratedMargins'
import { Activity } from './Activity'

export const standardMargin = 4
const exaggeratedMargin = 7

const imageMaxWidth = PAGE_LAYOUT_CONTENT_WIDTH
const imageMaxHeight = 450
const imageScaleQuality = 95
const imageRetinaScaleFactor = 2.0

export const renderRichTextDocument = (
  richTextDocument: RichTextDocument,
  overrides?: RenderNode
) => {
  return (
    <DeduplicateExaggeratedMargins>
      {documentToReactComponents(richTextDocument, {
        renderNode: { ...nodeRenderers, ...overrides },
      })}
    </DeduplicateExaggeratedMargins>
  )
}

export const nodeRenderers: RenderNode = {
  [BLOCKS.PARAGRAPH]: (node, children) => {
    const youTubeVideoId = findYouTubeMarkup(node)

    if (youTubeVideoId) {
      return <YouTubePlayer videoId={youTubeVideoId} width="100%" />
    }

    return <Body my={standardMargin}>{children}</Body>
  },
  [BLOCKS.HEADING_1]: (_node, children) => (
    <LargeTitle my={standardMargin} as="h1">
      {children}
    </LargeTitle>
  ),
  [BLOCKS.HEADING_2]: (_node, children) => (
    <Title1 my={standardMargin} as="h2">
      {children}
    </Title1>
  ),
  [BLOCKS.HEADING_3]: (_node, children) => (
    <Title2 mt={exaggeratedMargin} mb={standardMargin} as="h3">
      {children}
    </Title2>
  ),
  [BLOCKS.HEADING_4]: (_node, children) => (
    <SectionTitle mt={exaggeratedMargin} mb={standardMargin} as="h4">
      {children}
    </SectionTitle>
  ),
  [BLOCKS.OL_LIST]: (_node, children) => (
    <OrderedList pl="6" my={standardMargin}>
      {children}
    </OrderedList>
  ),
  [BLOCKS.UL_LIST]: (_node, children) => (
    <UnorderedList pl="6" my={standardMargin}>
      {children}
    </UnorderedList>
  ),
  [BLOCKS.LIST_ITEM]: (_node, children) => (
    <ListItem my="3">{children}</ListItem>
  ),
  [BLOCKS.QUOTE]: (_node, children) => {
    return <Blockquote>{children}</Blockquote>
  },
  [BLOCKS.EMBEDDED_ASSET]: (node, _children) => {
    const { data } = node

    const mimeType = get(data, 'target.fields.file.contentType', '')
    const mimeTypeGroup = mimeType.split('/')[0]

    if (mimeTypeGroup === 'image') {
      const contentfulWidth = imageMaxWidth * imageRetinaScaleFactor
      const contentfulHeight = imageMaxHeight * imageRetinaScaleFactor

      const url =
        get(data, 'target.fields.file.url', '') +
        `?w=${contentfulWidth}&h=${contentfulHeight}&q=${imageScaleQuality}`

      const title = get(data, 'target.fields.title', '')
      const description = get(data, 'target.fields.description', '')

      return (
        <Image
          align="center"
          src={url}
          alt={title}
          caption={description}
          maxHeight={imageMaxHeight}
        />
      )
    }

    return undefined
  },
  [BLOCKS.EMBEDDED_ENTRY]: (node, _children) => {
    const { title, introduction, activity } = get(
      node,
      'data.target.fields',
      {}
    )

    return (
      <Activity
        title={title || ''}
        introduction={introduction || ''}
        activity={activity || null}
      />
    )
  },
  [INLINES.HYPERLINK]: (node, children) => {
    const { data } = node

    const to = get(data, 'uri', '')
    const target = isExternalLink(to) ? '_blank' : undefined

    return (
      <Link
        to={to}
        target={target}
        onClick={() => {
          //globalViewerId.id is set in src/components/Header/index.tsx
          //current-location will be the value of the previous url when this is read
          if (globalViewerId.id !== 0) {
            recordAnalyticsEvent({
              userId: globalViewerId.id,
              event: AnalyticsEvents.LinkClick,
              url: to,
              type: AnalyticsEventTypes.Link,
              title: null,
              mediaSource: null,
              objectId: null,
              mediaSourceUrl: null,
              referrer: sessionStorage.getItem('current-location'),
              trackStartTime: null,
              timeZoneOffset: new Date().getTimezoneOffset(),
            })
          }

          trackEvent(AnalyticsEventTypes.Link, AnalyticsEvents.LinkClick, to)
        }}
      >
        {children}
      </Link>
    )
  },
  [INLINES.ASSET_HYPERLINK]: (node, children) => {
    const { data } = node

    const { file, title } = get(data, 'target.fields', {})

    return (
      <Link
        to={file.url}
        target="_blank"
        onClick={() => {
          //globalViewerId.id is set in src/components/Header/index.tsx
          //current-location will be the value of the previous url when this is read
          if (globalViewerId.id !== 0) {
            recordAnalyticsEvent({
              userId: globalViewerId.id,
              event: AnalyticsEvents.LinkClick,
              url: file.url,
              type: AnalyticsEventTypes.Link,
              title: null,
              mediaSource: null,
              objectId: null,
              mediaSourceUrl: null,
              referrer: sessionStorage.getItem('current-location'),
              trackStartTime: null,
              timeZoneOffset: new Date().getTimezoneOffset(),
            })
          }

          trackEvent(
            AnalyticsEventTypes.Link,
            AnalyticsEvents.LinkClick,
            file.url
          )
        }}
        download
      >
        {children || title}
      </Link>
    )
  },
}

const BlockquoteInner = styled(Box)`
  margin-top: ${props => props.theme.space[standardMargin]}px;
  margin-bottom: ${props => props.theme.space[standardMargin]}px;
  margin-right: ${props => props.theme.space[6]}px;
  margin-left: ${props => props.theme.space[4]}px;
  padding-left: ${props => props.theme.space[4]}px;
  border-left: 2px solid ${props => props.theme.colors.lightGrey};
`

const Blockquote = BlockquoteInner.withComponent('blockquote')

export default renderRichTextDocument

/* eslint-disable prettier/prettier */

import LocalizedStrings from 'react-localization'
import { ProjectPhase, ProjectPhaseStep } from './lib/project'
import { GlobalGoal, ProjectPhaseState } from './graphql-generated'

export const strings = new LocalizedStrings({
         en: {
           dashboard: {
             dashboardTitle: 'My Dashboard',
             myClassTitle: 'My Class',
             createProjectButton: 'Create a Project',
             selectClassLabel: 'Select a class',
             classCodeTitle: 'Class Code',
             instructor: {
               createAClassButton: '+ Create a class',
               instructorTitle: 'Instructors',
               manageInstructorsButton: 'Add/Remove',
               classProjectsTab: 'Class Projects',
               readyForReviewTab: 'Ready for Review',
               classProjectsPanelTitle: 'All class projects',
               readyForReviewPanelTitle: 'Review student submissions',
               classProjectsPlaceholder: {
                 title: 'Your class hasn’t created any projects yet',
                 body:
                   'You can create a project for a team in your class or sit back and relax while your students take their first steps towards creating a better world',
               },
               readyForReviewPlaceholder: {
                 title: 'You have no team submissions to review',
                 body:
                   'Looks like all the teams in your class are working on their projects. Once a team submits a phase for review, it will appear here.',
               },
               reviewModals: {
                 commentLabel: 'Comments',
                 cancelLabel: 'Cancel',
                 phaseTitle: 'Phase',
                 projectTitle: 'Project',
                 return: {
                   body:
                     'Confirm that you are returning these files to the team for additional work in the phase.',
                   confirmLabel: 'Return to Team',
                   title: 'Return to Team',
                 },
                 approve: {
                   body:
                     'Confirm that you have reviewed all files and want to mark the phase complete for the team.',
                   confirmLabel: 'Approve Phase',
                   title: 'Approve Phase',
                 },
               },
             },
             student: {
               myProjectTab: 'My Projects',
               classProjectsTab: 'Class Projects',
               myProjectsPanelTitle: 'All my projects',
               classProjectPanelTitle: 'All class projects',
               classProjectsPlaceholder: {
                 title: 'Your class hasn’t created any projects yet',
                 body:
                   'But don’t worry great minds are at work. Check back to see what sustainable goals the teams in your class will work toward.',
               },
               myProjectPlaceholder: {
                 exploreTitle: 'Explore the innovator’s mindset',
                 exploreBody:
                   'Embrace your inner innovator by using the design thinking process to create solutions for local and global problems. You will help build a better world.',
                 exploreLink: 'Learn More',
                 getStartedTitle: 'Ready to get started?',
                 getStartedBody:
                   'Take your first step towards creating solutions that will help build communities and protect the environment.',
               },
             },
           },
           projectOverview: {
             projectTitle: 'Project',
             teamTitle: 'Team',
             globalGoalTitle: 'Global Goal',
             globalGoalAlt: 'Global goal icon',
             phasesTitle: 'Design Thinking Phases',
             editProject: 'Edit project',
             phasesDescription:
               'Explore the three phases of design thinking process that you will be working through on  every project. Within each phase, you can learn about design thinking, complete activities, and then submit work for feedback and approval.',
             profilePictureAlt: 'Profile picture for {name}',
             profilePictureAltEmpty: 'Empty profile picture',
             phaseCardActLearn: 'Explore',
             phaseCardActPitch: 'Submit your pitch',
           },
           projectPhases: {
             [ProjectPhase.Understand]: {
               name: 'Understand',
               cardDescription:
                 'Discover the complexity of the problem and how it affects people',
               phaseHeaderDescription:
                 'Discover the complexity of the problem and how it affects people.',
             },
             [ProjectPhase.Ideate]: {
               name: 'Ideate',
               cardDescription:
                 'Stimulate your ideas to spark an innovative solution',
               phaseHeaderDescription:
                 'Stimulate your ideas to spark an innovative solution.',
             },
             [ProjectPhase.Prototype]: {
               name: 'Prototype',
               cardDescription:
                 'Build your solution and bring it to life',
               phaseHeaderDescription:
                 'Build your solution and bring it to life.',
             },
             [ProjectPhase.Pitch]: {
               name: 'Pitch',
               cardDescription: 'Share your solution with others',
               phaseHeaderDescription:
                 'Share your solution with others.',
             },
           },
           projectPhaseSteps: {
             [ProjectPhaseStep.Explore]: {
               name: 'Explore',
             },
             [ProjectPhaseStep.Do]: {
               name: 'Do',
             },
             [ProjectPhaseStep.Document]: {
               name: 'Document',
             },
           },
           projectPhaseState: {
             [ProjectPhaseState.Approved]: 'Approved',
             [ProjectPhaseState.Returned]: 'Returned',
             [ProjectPhaseState.Submitted]: 'Submitted',
           },
           projectPitchApproved: '🎉 Approved 🎉',
           projectStatusHistory: {
             [ProjectPhaseState.Approved]: 'Phase Approved',
             [ProjectPhaseState.Returned]: 'Phase Returned',
             [ProjectPhaseState.Submitted]: 'Phase Submitted',
           },
           globalGoals: {
             [GlobalGoal.NoPoverty]: {
               number: '1',
               name: 'No Poverty',
             },
             [GlobalGoal.ZeroHunger]: {
               number: '2',
               name: 'Zero Hunger',
             },
             [GlobalGoal.GoodHealthAndWellBeing]: {
               number: '3',
               name: 'Good Health and Well-Being',
             },
             [GlobalGoal.QualityEducation]: {
               number: '4',
               name: 'Quality Education',
             },
             [GlobalGoal.GenderEquality]: {
               number: '5',
               name: 'Gender Equality',
             },
             [GlobalGoal.CleanWaterAndSanitation]: {
               number: '6',
               name: 'Clean Water and Sanitation',
             },
             [GlobalGoal.AffordableAndCleanEnergy]: {
               number: '7',
               name: 'Affordable and Clean Energy',
             },
             [GlobalGoal.DecentWorkAndEconomicGrowth]: {
               number: '8',
               name: 'Decent Work and Economic Growth',
             },
             [GlobalGoal.IndustryInnovationAndInfrastructure]: {
               number: '9',
               name: 'Industry, Innovation, and Infrastructure',
             },
             [GlobalGoal.ReducedInequalities]: {
               number: '10',
               name: 'Reduced Inequalities',
             },
             [GlobalGoal.SustainableCitiesAndCommunities]: {
               number: '11',
               name: 'Sustainable Cities and Communities',
             },
             [GlobalGoal.ResponsibleConsumptionAndProduction]: {
               number: '12',
               name: 'Responsible Production and Consumption',
             },
             [GlobalGoal.ClimateAction]: {
               number: '13',
               name: 'Climate Action',
             },
             [GlobalGoal.LifeBelowWater]: {
               number: '14',
               name: 'Life Below Water',
             },
             [GlobalGoal.LifeOnLand]: {
               number: '15',
               name: 'Life On Land',
             },
             [GlobalGoal.PeaceJusticeAndStrongInstitutions]: {
               number: '16',
               name: 'Peace, Justice, and Strong Institutions',
             },
             [GlobalGoal.PartnershipsForTheGoals]: {
               number: '17',
               name: 'Partnerships for the Goals',
             },
           },
           documentStep: {
             documentsColumnTitle: 'Linked Files',
             historyColumnTitle: 'Teacher Feedback',
             headerDescription:
               'Link your files for your teacher below. Once your team is ready, you can send the files to your teacher for review.',
             openFilePickerLabel: '+ Add',
             placeholderNoDocuments:
               'Your team hasn’t linked any files yet. Select the  + Add button above.',
             placeholderNoFeedback:
               'Submit files to get feedback from your teacher.',
             submitButtonLabel: 'Submit Files',
             submitColumnBody:
               'Submit the linked files to your teacher for review.',
             submitColumnTitle: 'Ready for Feedback?',
             fileSharingErrorBody: `We recommend requesting permission to edit the file on Google Drive, or updating share permissions so that anyone with a link can view. If you’re having trouble, {contactUsLink}.`,
             fileSharingContactLink: 'contact us',
           },
           submitPhaseModal: {
             body:
               'Would you like to send files for the {phaseName} phase to your teacher? There {isPluralize} {count} {documentPluralize} linked.',
             cancelButtonLabel: 'Cancel',
             documentSingular: 'document',
             isPlural: 'are',
             isSingular: 'is',
             submitButtonLabel: 'Send to Teacher',
             title: 'Submit {phaseName} Phase',
           },
           StudentSignupForm: {
             title: 'Create a student account',
             subtitle: 'Enter your 4-character class code to sign up',
             button: 'Sign up with Google',
             ageConfirmation: 'I am 13 years or older',
             terms: 'I agree to the {termsLink} and {privacyLink}',
             privacyLink: 'Privacy Policy',
             termsLink: 'Terms of Use',
             alreadyHaveAccount: 'Already have an account? {signinLink}',
             notFoundTitle: 'Account not found',
           },
           joinClassGroupModal: {
             title: 'Join another class',
             subtitle: 'Enter your 4-character class code',
             cancelButtonText: 'Cancel',
             joinButtonText: 'Join Class',
           },
           createClassGroupModal: {
             title: 'Create a class',
             cancelButtonText: 'Cancel',
             createButtonText: 'Create',
           },
           manageCoTeacherModal: {
             title: 'Manage Inscructors for this Class',
             subTitle: 'Add or remove instructors for this class.',
             instructorLabel: 'Instructor',
             instructorPlaceholder: 'Search for instructors',
             cancelButtonText: 'Cancel',
             createButtonText: 'Save',
           },
           TeacherSignupForm: {
             messageClassCreationNotAllowed:
               'Class creation is currently unavailable.',
             title: 'Create a teacher account',
             subtitle: 'Enter the requested information below.',
             classNameLabel: 'Class Name',
             classNamePlaceholder:
               'Your class name will appear throughout Pactful',
             classDescriptionLabel: 'Describe your class',
             classDescriptionPlaceholder:
               'Describe the class you are going to use with Pactful',
             organizationNameLabel: 'School/Organization',
             organizationNamePlaceholder:
               'Enter the name of your school/organization',
             cityLabel: 'City',
             cityPlaceholder: "Enter your shcool/organization's city",
             stateLabel: 'State/Province',
             statePlaceholder:
               "Enter your shcool/organization's state or province",
             postalCodeLabel: 'Postal Code',
             postalCodePlaceholder:
               "Enter your shcool/organization's postal or ZIP code",
             countryLabel: 'Country',
             countryPlaceholder:
               "Enter your shcool/organization's country",
             jobTitleLabel: 'Job Title',
             jobTitlePlaceholder: 'Enter your job title',
             studentGradeLevelsLabel: 'Student Grade Level(s)',
             studentGradeLevelLabel1: '7th Grade (13 years old)',
             studentGradeLevelLabel2: '8th Grade (13–14 years old)',
             studentGradeLevelLabel3: '9th Grade (14–15 years old)',
             studentGradeLevelLabel4: '10th Grade (15–16 years old)',
             studentGradeLevelLabel5: '11th Grade (16–17 years old)',
             studentGradeLevelLabel6: '12th Grade (17–18 years old)',
             studentGradeLevelLabel7: 'Other/Adults',
             studentTypeDecriptionPlaceholder:
               'What are the ages and grade levels of your students?',
             button: 'Sign up with Google',
             ageConfirmation: 'I am 18 years or older',
             terms: 'I agree to the {termsLink} and {privacyLink}',
             privacyLink: 'Privacy Policy',
             termsLink: 'Terms of Use',
             alreadyHaveAccount: 'Already have an account? {signinLink}',
             notFoundTitle: 'Account not found',
           },
           homepage: {
             title: 'Welcome to Pactful',
             body1:
               '{pactfulLink} is the social innovation tool for students and teachers who want to build a better world.',
             body2:
               'Pactful requires the use of a Google Account. Students who submit files to teachers for review will be asked to allow Pactful to manage those files to change the share settings, so teachers may review and comment on submissions.',
             signUpLabel: 'Create Account',
             privacyLink: 'Privacy Policy',
             termsLink: 'Terms of Use',
             pactfulLink: 'Pactful',
             errorUserNotFound:
               'You do not have a Pactful account. Please click Create Account and provide the needed information.',
           },
           chooseAccountType: {
             title: 'Choose an Account Type',
             teacherLabel: 'Teacher',
             studentLabel: 'student',
           },
           projectForm: {
             createAction: 'Create Project',
             createFormTitle: 'Create Project',
             editFormTitle: 'Edit Project',
             editAction: 'Update Project',
             deleteProjectField: {
               deleteLinkText: 'DELETE PROJECT',
               confirmationModalTitle: 'Confirm Project Deletion',
               confirmationModalCancelButtonText: 'Cancel',
               confirmationModalDeleteButtonText: 'Delete Project',
               deleteConfirmationInstruction:
                 'This action will permanently delete this project.',
             },
             globalGoalField: {
               explaination:
                 'Start by choosing a {globalGoalLink} for your project. Select a goal that is related to the local or global problem you’d like to work on.',
               learnMore:
                 'Unsure about which goal to choose? Don’t worry, you can learn more about all the {globalGoalsLink} through our {innovatorsGuideLink}.',
               globalGoalLink: 'Global Goal',
               globalGoalsLink: 'Global Goals',
               innovatorsGuideLink: "Innovator's Guide",
               fieldTitle: 'Select a Global Goal',
             },
             teamField: {
               globalGoalTitle: 'Global Goal',
               changeGoal: 'change goal',
               formTeamTitle: 'Your Team',
               teamNameLabel: 'Team Name',
               teamNamePlaceholder: 'Give your team a unique name',
               memberSearchLabel: 'Team Members (optional)',
               memberSearchPlaceholder: 'Add team members',
             },
             projectDetails: {
               title: 'Your Project',
               nameLabel: 'Project Name',
               namePlaceholder: 'Give your project a unique name',
               descriptionLabel: 'Description',
               descriptionPlaceholder:
                 'Describe your project in more detail.',
             },
           },
           signIn: 'Sign in',
           groupClassCodeField: {
             label: 'Class Code',
             placeholder: 'Enter your class code',
             invalidCodeError: "We can't verify your code. Try again.",
           },
           formValidation: {
             required: 'Required',
             genericError: 'Something went wrong',
             optional: '(optional)',
           },
           searchInput: {
             noResults: 'No Results',
           },
           formWizard: {
             next: 'Next',
             cancel: 'Cancel',
           },
           projectCard: {
             approveLabel: 'Approve Phase',
             goalTitle: 'Goal {goalNumber}',
             teamTitle: 'Team',
             pitchVideoTitle: 'Pitch Video',
             progressTitle: 'Progress',
             returnLabel: 'Return to Team',
             submittedDocumentsTitle: 'Submitted Documents',
           },
           pageTitles: {
             template: '%s - Pactful',
             defaultTitle: 'Pactful',
             phaseSuffix: 'Pactful',
             editProject: 'Edit {projectName}',
             joinClassGroup: 'Join Class',
             dashboardTitle: 'Dashboard',
             createProject: 'Create Project',
             showcaseTitle: 'Showcase',
             innovatorGuideTemplate: "%s - Innovator's Guide - Pactful",
             innovatorGuideDefault: "Innovator's Guide - Pactful",
             notFound: 'Not Found',
             somethingWentWrong: 'Something went wrong',
             forbidden: 'Not Authorized',
           },
           innovatorsGuide: {
             heading: "Innovator's Guide",
             subheading:
               'This is your guide to all things innovation and social impact.',
             tabs: {
               gettingStarted: 'Getting Started',
               globalGoals: 'Global Goals',
               resources: 'Resources',
             },
           },
           showcase: {
             heading: 'Showcase',
             filter: { placeholderText: 'Filter by Global Goal...' },
             showComplete: 'Show only completed projects',
             showcaseProjectsPlaceholder: {
               title: 'No projects found',
               body: 'There are no projects matching your criteria.',
             },
           },
           header: {
             myDashboard: 'My Dashboard',
             community: 'Community',
             showcase: 'Showcase',
             innovatorsGuide: "Innovator's Guide",
             logout: 'Sign out',
             contactUs: 'Need help or have questions? {contactUsLink}',
             contactUsLink: 'Contact us',
             joinClass: 'Join a Class',
           },
           footer: {
             innovatorGuideTitle: "Innovator's Guide",
             gettingStartedLink: 'Getting Started',
             globalGoalLink: 'Global Goals',
             resourceLink: 'Resources',
             dashboardTitle: 'My Dashboard',
             myProjectLink: 'My Projects',
             classProjectsLink: 'Class Projects',
             contactTitle: 'Contact Us',
             termsLink: 'Terms of Use',
             privacyLink: 'Privacy Policy',
           },
           links: {
             contactPath: 'https://forms.gle/ALUoyELoxq1Rz4Sr8',
             pactfulMarketingSitePath: 'https://www.pactful.org',
           },
           notFound: {
             title: "We can't find this page",
             loggedInLink: 'Back to Dashboard',
             defaultLink: 'Back',
             body: `If you reached this page from a link on {siteLink}, please {contactUsLink}
      about the problem.`,
             siteLink: 'pactful.org',
             contactUsLink: 'contact us',
           },
           somethingWentWrong: {
             title: "Something went wrong, and we're trying to fix it.",
             body: `There's been an error on our server. Return to {siteLink}, or {contactUsLink} so we can work on this problem.`,
             siteLink: 'pactful.org',
             contactUsLink: 'contact us',
           },
           unauthorized: {
             title: 'Unauthorized',
             body: "You're not authorized to view this content.",
           },
           errors: {
             invalidFilePermission: 'Unable to share {documentName}',
             forbidden: 'Unauthorized',
             generic: 'Oops! Something isnt right here',
             fileSharing:
               'Something went wrong while linking these files:',
           },
           emailTemplates: {
             classCreated: {
               subject: 'Your Pactful class has been created',
               body: `
        <!doctype html>
        <html>
        <head>
        </head>
        <body>
        <p>Welcome to Pactful! We are so thrilled that you're joining us on our journey to build a better world. Your class has been created and you’re ready to get started.</p>
        <p>You can invite students to your class using your class code (always shown on your dashboard) using the process below:</p>
        <ol>
            <li>Go to <a href="https://www.pactful.org">pactful.org</a> and click the <strong>Sign In > Have a class code? Create an Account</strong>.</li>
            <li>Enter your class code of <strong>{{CLASS_CODE}}</strong> and click <strong>Sign up With Google</strong>.</li>
        </ol>
        <p>In addition, we welcome your involvement with the <a href="https://community.pactful.org/">Pactful Community</a> where you can engage with other educators, the team from the Jacobs Institute, and get support using the support category.</p>
        <p>
            <strong>Pactful Support</strong><br>
            <a href="mailto:support@pactful.org">support@pactful.org</a>
        </p>
        <p><em>Pactful® is a social good innovation curriculum and tool for teens and teachers who want to build a better world. Pactful is created and supported by the <a href="https://www.sandiego.edu/soles/jacobs-institute/">Jacobs Institute for Innovation in Education</a> at the University of San Diego.</em></p>
        </body>
        </html>
        `,
             },
           },
         },
       })

export default strings

import React from 'react'
import { Body, LargeTitle, SectionTitle } from 'src/components/text'
import { Flex, Box } from 'src/components/Box'
import { lineClampStyle } from 'src/styles/typography'

interface Props {
  title: string
  children: string
  color?: string
}

export const LargeProperty: React.FunctionComponent<Props> = ({
  title,
  children,
  color,
}) => (
  <Flex flexDirection="column">
    <Box mb={[3, 5]}>
      <SectionTitle>{title}</SectionTitle>
    </Box>
    <LargeTitle css={lineClampStyle(2)} color={color}>
      {children}
    </LargeTitle>
  </Flex>
)

export const SmallProperty: React.FunctionComponent<Props> = ({
  title,
  children,
  color,
}) => (
  <Flex flexDirection="column">
    <Box mb="1">
      <SectionTitle>{title}</SectionTitle>
    </Box>
    <Body color={color}>{children}</Body>
  </Flex>
)

export const SmallHorizontalProperty: React.FunctionComponent<Props> = ({
  title,
  children,
  color,
}) => (
  <Flex flexDirection="row" alignItems="baseline">
    <SectionTitle mr="2">{title}:</SectionTitle>
    <Body color={color}>{children}</Body>
  </Flex>
)

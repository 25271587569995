import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Box, Flex } from 'src/components/Box'
import { Body, Title2, SectionTitle, SmallBody } from 'src/components/text'
import { ProjectPhase, ProjectDetails } from 'src/graphql-generated'
import Loading from 'src/components/Loading'
import PageLayout from 'src/components/PageLayout'
import Action from 'src/components/Action'
import strings from 'src/strings'
import { ProjectPhaseStep } from 'src/lib/project'
import { InlineError } from 'src/components/errors'
import { contactPath } from 'src/lib/paths'
import Link from 'src/components/Link'
import { useDocumentsAndFilePicker } from './useDocumentsAndFilePicker'
import { DocumentList } from './DocumentList'
import { PhaseHistory } from './PhaseHistory'
import { SubmitPhaseButton } from './SubmitPhaseButton'

type Props = RouteComponentProps & {
  project: ProjectDetails
  phase: ProjectPhase
}

export const DocumentStep: React.FunctionComponent<Props> = ({
  project,
  phase,
}) => {
  const {
    loadingDocuments,
    loadingPicker,
    attachedDocuments,
    openFilePicker,
    failedFiles,
  } = useDocumentsAndFilePicker({
    pickerTitle: 'Link Document',
    projectId: project.id,
    projectPhase: phase,
  })

  return (
    <PageLayout
      title={strings.projectPhaseSteps[ProjectPhaseStep.Document].name}
    >
      <Body>{strings.documentStep.headerDescription}</Body>
      {failedFiles.length > 0 && (
        <Flex
          mt="6"
          flexDirection="column"
          p="5"
          border="1px solid"
          borderColor="coral"
          borderRadius="4px"
        >
          <InlineError>{strings.errors.fileSharing}</InlineError>
          <Flex flexDirection="column">
            {failedFiles.map(f => (
              <SmallBody mt="2" color="coral" key={f}>
                {f}
              </SmallBody>
            ))}
          </Flex>
          <Body mt="4">
            {strings.formatString(strings.documentStep.fileSharingErrorBody, {
              contactUsLink: (
                <Link target="_blank" to={contactPath()}>
                  {strings.documentStep.fileSharingContactLink}
                </Link>
              ),
            })}
          </Body>
        </Flex>
      )}

      <Flex flexDirection={['column', 'row']}>
        <Box flexBasis={[null, '335px']} mr={[0, 5]} mt={[6, 7]}>
          <Flex flexDirection="row" alignItems="first baseline">
            <Box mr="3">
              <Title2>{strings.documentStep.documentsColumnTitle}</Title2>
            </Box>
            {!loadingPicker && openFilePicker && (
              <Action
                onClick={() => {
                  openFilePicker()
                }}
              >
                <SectionTitle color="currentColor">
                  {strings.documentStep.openFilePickerLabel}
                </SectionTitle>
              </Action>
            )}
          </Flex>
          <Box mt="4">
            {loadingDocuments ? (
              <Loading />
            ) : (
              <DocumentList documents={attachedDocuments} />
            )}
          </Box>
        </Box>
        <Box flexBasis={[null, '300px']} mt={[5, 7]}>
          <Title2>{strings.documentStep.submitColumnTitle}</Title2>

          <Body mt="4">{strings.documentStep.submitColumnBody}</Body>

          <Box mt="5">
            <SubmitPhaseButton projectId={project.id} phase={phase} />
          </Box>
        </Box>
      </Flex>

      <Box maxWidth="560px" mt="7">
        <Title2>{strings.documentStep.historyColumnTitle}</Title2>
        <PhaseHistory projectId={project.id} projectPhase={phase} />
      </Box>
    </PageLayout>
  )
}

import React from 'react'

import { LargeTitle, SectionTitle, Body } from 'src/components/text'
import { TeamMember } from 'src/components/TeamMember'
import { TeamMemberFullName } from 'src/components/TeamMemberFullName'
import { ProjectDetails } from 'src/graphql-generated'
import { Flex, Box } from 'src/components/Box'
import strings from 'src/strings'
import GlobalGoalIcon from 'src/components/GlobalGoalIcon'

const Details: React.FunctionComponent<ProjectDetails> = ({
  description,
  globalGoal,
  name,
  teamName,
  teamMembers,
}) => {
  const [studentListIsOpen, setStudentListOpen] = React.useState(false)

  return (
    <Flex justifyContent="space-between" width="100%" flexWrap="wrap">
      <Box>
        <Box width="100%" maxWidth="430px">
          <Box mb="5">
            <LargeTitle breakWord>{name}</LargeTitle>
          </Box>
          {description && (
            <Box mb="5">
              <Body breakWord>{description}</Body>
            </Box>
          )}
          <Box pt={[1, 3]}>
            <Box mb={[3, 4]}>
              <SectionTitle breakWord>
                {strings.projectOverview.teamTitle}
              </SectionTitle>
              <Body breakWord>{teamName}</Body>
            </Box>
            <Flex
              onClick={() => {
                setStudentListOpen(!studentListIsOpen)
              }}
              style={{ cursor: 'pointer' }}
            >
              {!studentListIsOpen && (
                <Flex>
                  {teamMembers.map(student => (
                    <TeamMember key={student.id} {...student} />
                  ))}
                </Flex>
              )}

              {studentListIsOpen && (
                <Flex flexDirection="column">
                  {teamMembers.map(student => (
                    <TeamMemberFullName key={student.id} {...student} />
                  ))}
                </Flex>
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box mt={[6, 0]}>
        <Box mb={[1, 4]}>
          <SectionTitle>{strings.projectOverview.globalGoalTitle}</SectionTitle>
        </Box>
        <Box>
          <GlobalGoalIcon
            css={{ transform: 'translateX(-3px)' }}
            width="175"
            globalGoal={globalGoal}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default Details

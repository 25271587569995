import React from 'react'
import styled from 'src/styles/styled'
import { vhHeight } from 'src/styles/verticalHeight'
import Action from './Action'
import { RemoveIcon } from './icons'
import { Box } from './Box'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const DRAWER_WIDTH = '275px'

const Container = styled<'div', Pick<Props, 'isOpen'>>('div')`
  position: fixed;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: flex-end;
  right: 0;
  top: 0;
  transition: transform 0.3s;
  overflow: hidden;
  width: ${DRAWER_WIDTH};
  height: ${vhHeight(100)};
  z-index: 1000;
  box-shadow: 5px 4px 12px 0 rgba(0, 0, 0, 0.2);
  transform: ${props =>
    props.isOpen
      ? 'translate3d(0, 0, 0)'
      : `translate3d(${DRAWER_WIDTH}, 0, 0)`};
`

const Drawer: React.FunctionComponent<Props> = ({
  children,
  isOpen,
  onClose,
}) => (
  <Container isOpen={isOpen}>
    <Box p="4">
      <Action onClick={onClose}>
        <RemoveIcon color="charcoalGrey" width="24px" />
      </Action>
    </Box>
    {children}
  </Container>
)

export default Drawer

import React from 'react'
import { partition } from 'lodash'
import { ClassGroup, Viewer } from 'src/graphql-generated'
import { TabList, Tab, TabPanel, RouteTabs } from 'src/components/Tabs'
import strings from 'src/strings'
import Loading from 'src/components/Loading'
import { useProjectNotifications } from 'src/hooks/useProjectNotifications'
import { dashboardPath } from 'src/lib/paths'
import { sortByHasFeedback } from 'src/lib/project'
import DashboardHeading from './DashboardHeading'
import ProjectsLayout from './ProjectsLayout'

const MyProjectPlaceholder = React.lazy(() => import('./MyProjectPlaceholder'))
const ClassProjectsPlaceholder = React.lazy(() =>
  import('./ClassProjectsPlaceholder')
)

interface Props {
  classGroups: ClassGroup[]
  viewer: Viewer
  refetchClassGroups: () => any
}

const StudentDashboard: React.FunctionComponent<Props> = ({
  classGroups,
  viewer,
  refetchClassGroups,
}) => (
  <DashboardHeading viewer={viewer} classGroups={classGroups}>
    {classGroup => {
      const { notifications } = useProjectNotifications()
      const notificationProjectIds = notifications.map(n => n.projectId)
      const projectsWithNotifications = classGroup.projects.map(p => ({
        ...p,
        hasNotification: notificationProjectIds.includes(p.id),
      }))

      const [myProjects, classProjects] = partition(
        projectsWithNotifications,
        s => s.teamMembers.map(({ id }) => id).includes(viewer.id)
      )

      return (
        <RouteTabs
          onSelect={() => refetchClassGroups()}
          tabRoutes={[
            dashboardPath('my-projects'),
            dashboardPath('class-projects'),
          ]}
        >
          <TabList>
            <Tab>{strings.dashboard.student.myProjectTab}</Tab>
            <Tab>{strings.dashboard.student.classProjectsTab}</Tab>
          </TabList>
          <TabPanel>
            {myProjects.length === 0 ? (
              <React.Suspense fallback={<Loading />}>
                <MyProjectPlaceholder />
              </React.Suspense>
            ) : (
              <ProjectsLayout
                projects={myProjects.sort(sortByHasFeedback)}
                title={strings.dashboard.student.myProjectsPanelTitle}
              />
            )}
          </TabPanel>
          <TabPanel>
            {classProjects.length === 0 ? (
              <React.Suspense fallback={<Loading />}>
                <ClassProjectsPlaceholder viewer={viewer} />
              </React.Suspense>
            ) : (
              <ProjectsLayout
                projects={classProjects}
                title={strings.dashboard.student.classProjectPanelTitle}
                cardsAreLinked
                showAvatars
              />
            )}
          </TabPanel>
        </RouteTabs>
      )
    }}
  </DashboardHeading>
)

export default StudentDashboard

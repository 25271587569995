import {
  color,
  ColorProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system'

import styled, { AsProps } from 'src/styles/styled'
import { flowStyles } from 'src/styles'
import * as typography from 'src/styles/typography'

export interface TextProps
  extends AsProps,
    ColorProps,
    TextAlignProps,
    SpaceProps {
  truncate?: boolean
  breakWord?: boolean
}

const breakWord = ({ breakWord = false }: TextProps) => {
  if (!breakWord) {
    return
  }

  return typography.breakWordStyle
}

const truncate = ({ truncate = false }: TextProps) => {
  if (!truncate) {
    return
  }

  return typography.truncateStyle
}

export const textStyles = flowStyles(
  color,
  textAlign,
  space,
  truncate,
  breakWord
)

export const LargeTitle = styled<'h1', TextProps>('h1')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.largeTitle}
  ${textStyles}
`

export const Title1 = styled<'h2', TextProps>('h2')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.title1}
  ${textStyles}
`

export const Title2 = styled<'h3', TextProps>('h3')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.title2}
  ${textStyles}
`

export const SectionTitle = styled<'h4', TextProps>('h4')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.sectionTitle}
  ${textStyles}
`

export const Label = styled<'div', TextProps>('div')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.label}
  ${textStyles}
`

export const Body = styled<'p', TextProps>('p')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.body}
  ${textStyles}
`

export const SmallBody = styled<'p', TextProps>('p')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.smallBody}
  ${textStyles}
`

export const ButtonLabel = styled<'span', TextProps>('span')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.buttonLabel}
  ${textStyles}
`

export const OrderedList = styled<'ol', SpaceProps>('ol')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.body}
  ${space}
`

export const UnorderedList = styled<'ul', SpaceProps>('ul')`
  color: ${props => props.theme.colors.charcoalGrey};
  ${typography.body}
  ${space}
`

export const ListItem = styled<'li', SpaceProps>('li')`
  p,
  div {
    display: inline;
  }

  ${space}
`

import { SmallBody } from 'src/components/text'
import { Link } from 'src/components/Link'
import { projectPath } from 'src/lib/paths'
import { BackArrowIcon } from 'src/components/icons'

interface Props {
  projectId: number
}

export const BackToProjectOverview: React.FunctionComponent<Props> = ({
  projectId,
}) => (
  <SmallBody>
    <BackArrowIcon height={9} />{' '}
    <Link to={projectPath(projectId)}>Back to Project Home</Link>
  </SmallBody>
)

import React from 'react'
import { Box } from 'src/components/Box'
import { SmallBody, Title2 } from 'src/components/text'
import Link from 'src/components/Link'
import strings from 'src/strings'

import { PhaseCardProps } from './PhaseCard'

const CardContent: React.FunctionComponent<
  Pick<PhaseCardProps, 'phase' | 'name' | 'cardDescription' | 'canEdit'>
> = ({ phase, name, cardDescription, canEdit }) => (
  <>
    <Box>
      <Title2>{name}</Title2>
      <SmallBody>{cardDescription}</SmallBody>
    </Box>
    <Box>
      {canEdit && (
        <SmallBody>
          <Link as="span" data-cy={`${phase}`}>
            {strings.projectOverview.phaseCardActLearn}
          </Link>
        </SmallBody>
      )}
    </Box>
  </>
)

export default CardContent

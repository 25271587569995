import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'

import './global.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  environment: process.env.ENVIRONMENT,
  dsn:
    'https://5fe4bd214a084e2680918eb0c8378e0a@o360533.ingest.sentry.io/3678519',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const root = document.getElementById('root')

if (root) {
  ReactModal.setAppElement(root)

  ReactDOM.render(<App />, root)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

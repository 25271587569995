import React from 'react'

import { CheckmarkIcon, CommentIcon, PaperclipIcon } from 'src/components/icons'
import { ProjectPhaseState } from 'src/graphql-generated'
import { SectionTitle } from 'src/components/text'
import strings from 'src/strings'
import { Flex } from 'src/components/Box'

const PHASE_ICON_SIZE = '18px'

interface Props {
  state:
    | ProjectPhaseState.Approved
    | ProjectPhaseState.Submitted
    | ProjectPhaseState.Returned
}

export const PitchPhaseCardLabel: React.FunctionComponent<Props> = ({
  state,
}) => {
  switch (state) {
    case ProjectPhaseState.Approved:
      return (
        <SectionTitle ml="3" color="topaz">
          {strings.projectPitchApproved}
        </SectionTitle>
      )
    default:
      return <PhaseCardLabel state={state} />
  }
}

export const PhaseCardLabel: React.FunctionComponent<Props> = ({ state }) => {
  switch (state) {
    case ProjectPhaseState.Approved:
      return (
        <Flex flexDirection="row">
          <CheckmarkIcon
            color="topaz"
            width={PHASE_ICON_SIZE}
            height={PHASE_ICON_SIZE}
          />
          <SectionTitle ml="3" color="topaz">
            {strings.projectPhaseState[state]}
          </SectionTitle>
        </Flex>
      )
    case ProjectPhaseState.Returned:
      return (
        <Flex flexDirection="row">
          <CommentIcon
            color="dullOrange"
            width={PHASE_ICON_SIZE}
            height={PHASE_ICON_SIZE}
          />
          <SectionTitle ml="3" color="dullOrange">
            {strings.projectPhaseState[state]}
          </SectionTitle>
        </Flex>
      )
    case ProjectPhaseState.Submitted:
      return (
        <Flex flexDirection="row">
          <PaperclipIcon
            color="purple"
            width={PHASE_ICON_SIZE}
            height={PHASE_ICON_SIZE}
          />
          <SectionTitle ml="3" color="purple">
            {strings.projectPhaseState[state]}
          </SectionTitle>
        </Flex>
      )

    // no default
  }
}

// for PageModel
export enum ContentfulPageEntryId {
  PrivacyPolicy = '5hhR8FPvFniUPJ3DuQXdyy',
  TermsOfUse = '22tO7MIUnbHAhukC4nnHiN',
  GettingStarted = '2GxVsS1cbOSfK2dQZx7KSf',
  Resources = 'bcI4Ug9Ghwh0YAr312yWD',
  Community = '2zDMyEriVrN8czWvMeQlWm',
}

// for DoStepModel
export enum DoStepEntryId {
  Understand = '2vSX6RFzhDvGXCrqeq5w2S',
  Ideate = '540rZvnEIA0qXXMOHZX5Ta',
  Prototype = '4oP4HZJmhqgmf9BvixULaJ',
  Pitch = '1bk7VvIpSIAGcMKf6AzNDZ',
}

// for ExploreStepModel
export enum ExploreStepEntryId {
  Understand = '4jerOtNiXXixt45PjrmVbr',
  Ideate = '2Z8tlXylYjc1pCCZ7dQitV',
  Prototype = '28YPWuTWh5EHq2Ou1b8LNf',
  Pitch = '1s5zx9WHTUK5x8dcUln8g1',
}

export enum GuideEntryId {
  GlobalGoals = '3feDntZPzCnjerwydmOKpf',
}

import { identifyUser } from 'src/lib/analytics'
import useLoggedIn from './useLoggedIn'

const useTrackUser = () => {
  const { viewer } = useLoggedIn()

  if (!viewer) {
    return
  }

  identifyUser(viewer.id)
}

export default useTrackUser

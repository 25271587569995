import styled, { AsProps } from 'src/styles/styled'
import { User } from 'src/graphql-generated'
import strings from 'src/strings'
import arrify from 'arrify'

export const profileIconSize = 28

interface Props {
  user: Pick<User, 'name' | 'profileIconUrl'>
  size?: number
}

export const ProfileIcon: React.FunctionComponent<Props> = ({
  user: { profileIconUrl, name },
  size = profileIconSize,
}) => (
  <Icon
    src={profileIconUrl}
    size={size}
    alt={
      name
        ? arrify(
            strings.formatString(strings.projectOverview.profilePictureAlt, {
              name,
            })
          ).join(' ')
        : strings.projectOverview.profilePictureAltEmpty
    }
    title={name || undefined}
  />
)

export const ProfileIconLoadingPlaceholder: React.FunctionComponent<
  Pick<Props, 'size'>
> = ({ size = profileIconSize }) => (
  <Icon size={size} shadowColor="transparent" as="div" />
)

interface IconProps extends AsProps {
  size: number
  shadowColor?: string
}

const Icon = styled.img<IconProps>`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 5px 10px
    ${props => (props.shadowColor ? props.shadowColor : 'rgba(0, 0, 0, 0.12)')};
`

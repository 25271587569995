import React from 'react'
import Helmet from 'react-helmet'
import arrify from 'arrify'
import { AuthenticatedRouteProps } from 'src/components/AuthenticatedRoute'
import { useClassGroupsQuery, ClassGroupRole } from 'src/graphql-generated'
import Loading from 'src/components/Loading'
import JoinClassGroupForm from 'src/components/JoinClassGroupForm'
import strings from 'src/strings'
import ErrorPage from 'src/pages/ErrorPage'
import { minuteToMs } from 'src/lib/time'
import StudentDashboard from './StudentDashboard'

const InstructorDashboard = React.lazy(() => import('./InstructorDashboard'))

const CLASS_GROUP_POLL_INTERVAL_MS = minuteToMs(2)

const Dashboard: React.FunctionComponent<AuthenticatedRouteProps> = ({
  viewer,
}) => {
  const { data, loading, error, refetch } = useClassGroupsQuery({
    pollInterval: CLASS_GROUP_POLL_INTERVAL_MS,
  })

  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <ErrorPage error={error} />
  }

  if (!data.viewerClassGroups.length) {
    return (
      <>
        <Helmet title={strings.pageTitles.joinClassGroup} />
        <JoinClassGroupForm />
      </>
    )
  }

  const dashboardTitle = arrify(
    strings.formatString(strings.pageTitles.dashboardTitle)
  ).join(' - ')

  data.viewerClassGroups.sort(
    (firstEl, secondEl) => secondEl.time - firstEl.time
  ) //Sort descending by time

  if (viewer.role === ClassGroupRole.Student) {
    return (
      <>
        <Helmet title={dashboardTitle} />
        <StudentDashboard
          refetchClassGroups={refetch}
          classGroups={data.viewerClassGroups}
          viewer={viewer}
        />
      </>
    )
  }

  if (viewer.role === ClassGroupRole.Instructor) {
    return (
      <React.Suspense fallback={<Loading />}>
        <>
          <Helmet title={dashboardTitle} />
          <InstructorDashboard
            refetchClassGroups={refetch}
            classGroups={data.viewerClassGroups}
            viewer={viewer}
          />
        </>
      </React.Suspense>
    )
  }

  return <ErrorPage />
}

export default Dashboard

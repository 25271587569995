import React from 'react'

import { SectionTitle } from 'src/components/text'
import { Link } from 'src/components/Link'
import { Box } from 'src/components/Box'
import { EditIcon } from 'src/components/icons'
import strings from 'src/strings'
import { ProjectDetails } from 'src/graphql-generated'
import { editProjectPath } from 'src/lib/paths'

const PageTitle: React.FunctionComponent<{
  canEdit: boolean
  details: Pick<ProjectDetails, 'id'>
}> = ({ canEdit, details }) => (
  <SectionTitle as="h1">
    {strings.projectOverview.projectTitle}{' '}
    {canEdit && (
      <EditIconWithLink
        to={editProjectPath(details.id)}
        title={strings.projectOverview.editProject}
      />
    )}
  </SectionTitle>
)

const EditIconWithLink: React.FunctionComponent<{
  to: string
  title: string
}> = ({ to, title }) => (
  <Box display="inline-block" css={{ position: 'relative', top: '1px' }}>
    <Link to={to} title={title} data-cy="edit" plain>
      <EditIcon color="dodgerBlue" />
    </Link>
  </Box>
)

export default PageTitle

import React from 'react'
import { ColorProps, color } from 'styled-system'
import isPropValid from '@emotion/is-prop-valid'
import css from '@emotion/css'

import { flowStyles } from 'src/styles'
import styled from 'src/styles/styled'
import { Theme } from 'src/styles/theme'
import * as animations from 'src/styles/animations'

import BackArrowSVG from 'assets/svgs/backArrow.inline.svg'
import CheckmarkSVG from 'assets/svgs/checkmark.inline.svg'
import ChevronSVG from 'assets/svgs/chevron.inline.svg'
import CommentSVG from 'assets/svgs/comment.inline.svg'
import EditSVG from 'assets/svgs/edit.inline.svg'
import ErrorSVG from 'assets/svgs/error.inline.svg'
import PaperClipSVG from 'assets/svgs/paperclip.inline.svg'
import PitchSVG from 'assets/svgs/pitch-unfilled.inline.svg'
import RemoveSVG from 'assets/svgs/x-icon.inline.svg'
import ReturnSVG from 'assets/svgs/return.inline.svg'
import WordMarkSVG from 'assets/svgs/wordmark.inline.svg'

import ArrowBottomLeftToRightSVG from 'assets/svgs/projectOverview/arrow-bottom-left-to-right.inline.svg'
import ArrowBottomRightToLeftSVG from 'assets/svgs/projectOverview/arrow-bottom-right-to-left.inline.svg'
import ArrowTopLeftToRightSVG from 'assets/svgs/projectOverview/arrow-top-left-to-right.inline.svg'
import ArrowTopRightToLeftSVG from 'assets/svgs/projectOverview/arrow-top-right-to-left.inline.svg'

export interface Props extends ColorProps {
  animate?: boolean
}

interface AnimateStrokeOptions {
  strokeLength: number
  duration: number
  reverse?: boolean
}

const animateStroke = (options?: AnimateStrokeOptions) => (props: {
  animate?: boolean
}) => {
  if (!props.animate) return undefined

  if (options === undefined) {
    throw new Error(
      'cannot use animate attribute on icon without AnimateStrokeOptions'
    )
  }

  const { strokeLength, duration, reverse = false } = options

  return css`
    stroke-dasharray: ${strokeLength}%;
    stroke-dashoffset: 0%;
    animation: ${animations.dashOffset(strokeLength, reverse)} ${duration}s;
  `
}

const svgStyles = (animateOptions?: AnimateStrokeOptions) =>
  flowStyles<Props>(color, animateStroke(animateOptions))

const createComponent = (
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  color?: keyof Theme['colors'],
  animateOptions?: AnimateStrokeOptions
) =>
  styled<'svg', Props>(svg as any, {
    shouldForwardProp: isPropValid,
  })`
    color: ${props => (color ? props.theme.colors[color] : undefined)};
    ${svgStyles(animateOptions)}
  `

export const CheckmarkIcon = createComponent(CheckmarkSVG, 'leafly', {
  strokeLength: 100,
  duration: 0.5,
})

// TODO: figure out a better way to get the type returned from createComponent()
export type Icon = typeof CheckmarkIcon

export const CommentIcon = createComponent(CommentSVG, 'dullOrange')
export const ChevronIcon = createComponent(ChevronSVG, 'dodgerBlue')
export const PaperclipIcon = createComponent(PaperClipSVG, 'purple')

export const EditIcon = createComponent(EditSVG, 'charcoalGrey', {
  strokeLength: 100,
  duration: 0.5,
})

export const BackArrowIcon = createComponent(BackArrowSVG, 'charcoalGrey')

export const ErrorIcon = createComponent(ErrorSVG, 'coral', {
  strokeLength: 250,
  duration: 0.5,
})

export const RemoveIcon = createComponent(RemoveSVG)

export const ReturnIcon = createComponent(ReturnSVG, 'charcoalGrey')

export const ArrowTopLeftToRightIcon = createComponent(
  ArrowTopLeftToRightSVG,
  'lightGrey',
  { strokeLength: 150, duration: 1 }
)

export const ArrowTopRightToLeftIcon = createComponent(
  ArrowTopRightToLeftSVG,
  'lightGrey',
  { strokeLength: 150, duration: 1, reverse: true }
)

export const ArrowBottomLeftToRightIcon = createComponent(
  ArrowBottomLeftToRightSVG,
  'lightGrey',
  { strokeLength: 150, duration: 1 }
)

export const ArrowBottomRightToLeftIcon = createComponent(
  ArrowBottomRightToLeftSVG,
  'lightGrey',
  { strokeLength: 150, duration: 1 }
)

export const WordMark = createComponent(WordMarkSVG, 'charcoalGrey')

export const PitchIcon = createComponent(PitchSVG, 'lightGrey')

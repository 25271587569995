import React from 'react'
import { ClassNames } from '@emotion/core'
import { Location } from '@reach/router'
import {
  Tabs as ReactTabs,
  TabList as ReactTabList,
  Tab as ReactTab,
  TabPanel as ReactTabPanel,
  TabProps,
  TabPanelProps,
  TabsProps,
} from 'react-tabs'
import * as styles from 'src/styles'
import { Theme } from 'src/styles/theme'
import { tabletBreakpoint } from 'src/styles/media'

export const Tabs: React.FunctionComponent<
  Pick<TabsProps, 'onSelect' | 'selectedIndex'>
> = ({ children, onSelect, selectedIndex }) => (
  <ClassNames>
    {({ css }) => (
      <ReactTabs
        data-cy="tab-container"
        onSelect={onSelect}
        selectedIndex={selectedIndex}
        className={css`
          display: flex;
          flex-direction: column;
          max-width: calc(100vw - 32px);
        `}
      >
        {children}
      </ReactTabs>
    )}
  </ClassNames>
)

export const RouteTabs: React.FunctionComponent<
  {
    tabRoutes: string[]
    defaultTab?: number
  } & Pick<TabsProps, 'onSelect'>
> = ({ children, tabRoutes, onSelect, defaultTab = 0 }) => (
  <Location>
    {({ location, navigate }) => {
      const tabIndex = tabRoutes.findIndex(p => location.pathname.includes(p))
      return (
        <Tabs
          selectedIndex={tabIndex >= 0 ? tabIndex : defaultTab}
          onSelect={(index, last, event) => {
            onSelect && onSelect(index, last, event)
            navigate(tabRoutes[index])
          }}
        >
          {children}
        </Tabs>
      )
    }}
  </Location>
)

export const TabList: React.FunctionComponent & { tabsRole: string } = ({
  children,
}) => (
  <ClassNames>
    {({ css }) => (
      <ReactTabList
        className={css`
          display: flex;
          width: 100%;
          flex-direction: row;
          margin: 0;
          padding: 0;
        `}
      >
        {children}
      </ReactTabList>
    )}
  </ClassNames>
)
TabList.tabsRole = 'TabList'

export const Tab: React.FunctionComponent<
  Pick<TabProps, 'tabIndex' | 'disabled'> & { color?: keyof Theme['colors'] }
> & { tabsRole: string } = ({ children, color, ...props }) => (
  <ClassNames>
    {({ css }) => (
      <ReactTab
        data-cy="tab"
        className={css`
          color: ${color ? styles.theme.colors[color] : undefined};
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          list-style: none;
          padding: 12px 20px;
          margin: 0;
          cursor: pointer;
          ${styles.typography.sectionTitle};
          line-height: 1.43;
          font-size: 12px;
          height: 50px;
          ${tabletBreakpoint({ fontSize: '14px', height: 'auto' })};
        `}
        {...props}
        selectedClassName={css`
          background: ${styles.theme.colors.white};
          color: ${styles.theme.colors.dodgerBlue} !important;
        `}
        disabledClassName={css`
          cursor: default;
          background: ${styles.theme.colors.lightestGrey};
        `}
      >
        {children}
      </ReactTab>
    )}
  </ClassNames>
)
Tab.tabsRole = 'Tab'

export const TabPanel: React.FunctionComponent<
  Pick<TabPanelProps, 'forceRender'>
> & { tabsRole: string } = ({ children, ...props }) => (
  <ReactTabPanel {...props}>{children}</ReactTabPanel>
)

TabPanel.tabsRole = 'TabPanel'

import { strings } from 'src/strings'

export const composeValidators = (
  ...validators: Array<(value: any) => string | undefined>
) => (value: any) =>
  validators.reduce<string | undefined>(
    (error, validator) => error || validator(value),
    undefined
  )

export const required = (value: any) =>
  value ? undefined : strings.formValidation.required

import ReactGA from 'react-ga4'
import { AnalyticsEventTypes } from 'src/lib/AnalyticsEvents'

export const trackEvent = (
  category: AnalyticsEventTypes,
  action: string,
  label?: string,
  value?: number
) => {
  IS_ENABLED &&
    ReactGA.event({
      category,
      action,
      label,
      value,
    })
}

export const identifyUser = (userId: number) => {
  IS_ENABLED && ReactGA.set({ userId })
}

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

const IS_ENABLED = Boolean(GOOGLE_ANALYTICS_ID)

export const initAnalytics = () => {
  if (GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  }
}

export const trackPageView = (location: Location) => {
  const path = [location.pathname, location.search].filter(Boolean).join('')
  IS_ENABLED &&
    ReactGA.send({
      hitType: 'pageview',
      page: path,
    })
}

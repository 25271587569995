import React from 'react'
import { Flex } from 'src/components/Box'
import styled from 'src/styles/styled'
import { theme } from 'src/styles/theme'
import { CheckmarkIcon } from 'src/components/icons'
import { SmallBody } from 'src/components/text'

interface Props {
  name: string
  checked: boolean
  onChangeHandler: () => void
  labelText: string
}

const CheckboxLabel = styled.label`
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: solid 1px ${theme.colors.charcoalGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s;
`

const CheckboxContainer = styled.div`
  position: relative;
  padding-top: ${theme.space[1]}px;
  margin-right: ${theme.space[3]}px;
  input:checked + label {
    background-color: ${theme.colors.dodgerBlue};
    border: 1px solid ${theme.colors.dodgerBlue};
  }
  input:hover + label,
  input:focus + label {
    outline: none;
    border: 1px solid ${theme.colors.skyBlue};
  }
`

const BasicCheckbox: React.FunctionComponent<Props> = ({
  children,
  name,
  checked,
  onChangeHandler,
  labelText,
}) => {
  const labelId = `${name}-label`
  const checkboxId = `${name}-id`
  return (
    <Flex>
      <CheckboxContainer>
        <input
          name={name}
          id={checkboxId}
          type="checkbox"
          checked={checked}
          onChange={onChangeHandler}
          aria-describedby={labelId}
          css={{
            opacity: 0,
            cursor: 'pointer',
            position: 'absolute',
          }}
        />
        <CheckboxLabel htmlFor={name}>
          {checked && (
            <CheckmarkIcon animate color="white" width={14} height={14} />
          )}
        </CheckboxLabel>
      </CheckboxContainer>
      <SmallBody id={labelId} as="span">
        <label htmlFor={checkboxId} css={{ cursor: 'pointer' }}>
          {labelText}
          {children}
        </label>
      </SmallBody>
    </Flex>
  )
}

export default BasicCheckbox

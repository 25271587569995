import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Flex, Box } from 'src/components/Box'
import { Body, Title1, SmallBody } from 'src/components/text'
import { Card, SubCard } from 'src/components/Card'
import { ButtonLink } from 'src/components/Button'
import Link from 'src/components/Link'
import UnauthenticatedContainer from 'src/components/UnauthenticatedContainer'
import {
  chooseAccountTypePath,
  termsOfUsePath,
  privacyPolicyPath,
  pactfulMarketingSitePath,
} from 'src/lib/paths'
import { loginUrl } from 'src/lib/urls'
import { strings } from 'src/strings'
import ErrorLabel from 'src/components/FormFields/ErrorLabel'

const Home: React.FunctionComponent<RouteComponentProps> = () => {
  sessionStorage.setItem('sign-in', 'true')

  const body = [strings.homepage.body1, strings.homepage.body2].filter(Boolean)
  const queryStringParams = new URLSearchParams(window.location.search)

  return (
    <UnauthenticatedContainer>
      <Card width="430px" borderColor="lightGrey" p="6" pb="7">
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Title1 textAlign="center" color="purple">
            {strings.homepage.title}
          </Title1>

          {body.map((copy, index) => (
            <Body mt="4" key={index}>
              {copy &&
                strings.formatString(copy, {
                  termsLink: (
                    <Link to={termsOfUsePath()}>
                      {strings.homepage.termsLink}
                    </Link>
                  ),
                  pactfulLink: (
                    <Link target="_blank" to={pactfulMarketingSitePath()}>
                      {strings.homepage.pactfulLink}
                    </Link>
                  ),
                })}
            </Body>
          ))}

          <Flex
            mt="6"
            flexDirection={['column', 'row']}
            justifyContent="center"
            alignItems="center"
          >
            <Box mr={[0, 5]} mb={[5, 0]}>
              <ButtonLink
                to={chooseAccountTypePath()}
                variant="secondary"
                data-cy="createAccount"
              >
                {strings.homepage.signUpLabel}
              </ButtonLink>
            </Box>
            <Box>
              {/* ButtonLink outputs a warning with minWidth prop, so use css */}
              <ButtonLink
                to={loginUrl}
                css={{ minWidth: '155px' }}
                data-cy="signIn"
              >
                {strings.signIn}
              </ButtonLink>
            </Box>
          </Flex>
          {queryStringParams.has('notFound') && (
            <Box mt="5">
              <ErrorLabel data-cy="userNotFoundError">
                {strings.homepage.errorUserNotFound}
              </ErrorLabel>
            </Box>
          )}
        </Flex>
      </Card>

      <SubCard borderColor="lightGrey" width="400px">
        <Flex
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mb="2"
        >
          <SmallBody>
            <Link to={termsOfUsePath()} data-cy="termsOfUse">
              {strings.homepage.termsLink}
            </Link>
          </SmallBody>
          <SmallBody ml="5">
            <Link to={privacyPolicyPath()} data-cy="privacyPolicy">
              {strings.homepage.privacyLink}
            </Link>
          </SmallBody>
        </Flex>
      </SubCard>
    </UnauthenticatedContainer>
  )
}

export default Home

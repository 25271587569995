import React from 'react'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import { FORM_ERROR } from 'final-form'
import { navigate, Redirect } from '@reach/router'
import { AuthenticatedRouteProps } from 'src/components/AuthenticatedRoute'
import { Flex } from 'src/components/Box'
import { LargeTitle } from 'src/components/text'
import { Props as ProjectFormProps } from 'src/components/ProjectForm'
import { projectPath } from 'src/lib/paths'
import Loading from 'src/components/Loading'
import { useResolveClassGroupId } from 'src/hooks/useClassGroup'
import PageBackground, { BackgroundType } from 'src/components/PageBackground'
import { useCreateProjectMutation } from 'src/hooks/useProjectMutation'
import strings from 'src/strings'
import { trackEvent } from 'src/lib/analytics'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'

const ProjectForm = React.lazy(() => import('src/components/ProjectForm'))

const NewProject: React.FunctionComponent<AuthenticatedRouteProps> = ({
  location,
  viewer,
}) => {
  const mutate = useCreateProjectMutation()

  const { loading, classGroupId } = useResolveClassGroupId(
    get(location, 'state.classGroupId')
  )
  if (loading) {
    return <Loading />
  }

  if (!classGroupId) {
    return <Redirect to="/" />
  }

  const onSubmit: ProjectFormProps['onSubmit'] = async values => {
    const { data } = await mutate({
      variables: {
        teamName: values.teamName,
        name: values.projectName,
        description: values.projectDescription,
        globalGoal: values.globalGoal,
        memberIds: values.memberIds,
        classGroupId: values.classGroupId,
      },
    })
    if (!data || !data.createProject) {
      return { [FORM_ERROR]: strings.formValidation.genericError }
    }

    const projectId = data.createProject.id

    //Make sure to only track logged in users and
    //also avoid exception due to viewer.id not being available
    if (viewer) {
      recordAnalyticsEvent({
        userId: viewer.id,
        event: AnalyticsEvents.ProjectCreate,
        url: window.location.href,
        type: AnalyticsEventTypes.Project,
        title: values.projectName,
        mediaSource: null,
        objectId: projectId.toString(),
        mediaSourceUrl: null,
        referrer: null,
        trackStartTime: null,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })
    }

    trackEvent(
      AnalyticsEventTypes.Project,
      AnalyticsEvents.ProjectCreate,
      values.projectName,
      projectId
    )

    navigate(projectPath(projectId))
  }

  const initialValues = {
    projectName: '',
    projectDescription: '',
    teamName: '',
    classGroupId: classGroupId,
    memberIds: [viewer.id],
  }

  return (
    <Flex mt="10" flexDirection="column">
      <Helmet title={strings.pageTitles.createProject} />
      <PageBackground type={BackgroundType.YellowCurveConcave} />
      <LargeTitle mb="5" data-cy="newProjectTitle">
        {strings.projectForm.createFormTitle}
      </LargeTitle>

      <React.Suspense fallback={<Loading />}>
        <ProjectForm
          viewer={viewer}
          initialValues={initialValues}
          submitText={strings.projectForm.createAction}
          onSubmit={onSubmit}
        />
      </React.Suspense>
    </Flex>
  )
}
export default NewProject

export enum AnalyticsEvents {
  PageView = 'page-view',
  VideoPlay = 'video-play',
  VideoStop = 'video-stop',
  VideoPause = 'video-pause',
  VideoEnd = 'video-end',
  LinkClick = 'link-click',
  ProjectUpdate = 'project-update',
  ProjectDelete = 'project-delete',
  ProjectCreate = 'project-create',
  AudioPlay = 'audio-play',
  AudioStop = 'audio-stop',
  ActivityOpened = 'activity-opened',
  ActivityClosed = 'activity-closed',
  DocumentAttached = 'document-attached',
  SignIn = 'auth-sign-in',
  SignOut = 'auth-sign-out',
  PhaseSubmit = 'phase-submit',
  PhaseApprove = 'phase-approve',
  PhaseReturn = 'phase-return',
}

import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { ProjectPhase } from 'src/lib/project'
import { DoStepEntryId } from 'src/contentful/entryIds'
import ContentfulPage from 'src/pages/ContentfulPage'

type Props = RouteComponentProps & { phase: ProjectPhase }

export const DoStep: React.FunctionComponent<Props> = ({ phase }) => {
  const entryId = entryIdFromPhase(phase)

  return <ContentfulPage entryId={entryId} />
}

const entryIdFromPhase = (phase: ProjectPhase) => {
  switch (phase) {
    case ProjectPhase.Understand:
      return DoStepEntryId.Understand

    case ProjectPhase.Ideate:
      return DoStepEntryId.Ideate

    case ProjectPhase.Pitch:
      return DoStepEntryId.Pitch

    case ProjectPhase.Prototype:
      return DoStepEntryId.Prototype

    //no default
  }
}

import React from 'react'
import { Flex, Box } from 'src/components/Box'
import FieldLabel from './FieldLabel'

interface Option {
  value: string
  label: string
}

interface Props {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  options: Option[]
  children?: undefined
  label?: string
}

const SelectField: React.FunctionComponent<Props> = ({
  options,
  onChange,
  value,
  placeholder,
  label,
}) => (
  <Flex flexDirection="column">
    {label && (
      <Box mb="3">
        <FieldLabel>{label}</FieldLabel>
      </Box>
    )}
    <select onChange={event => onChange(event.target.value)} value={value}>
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </Flex>
)

export default SelectField

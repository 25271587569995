import React from 'react'
import { Redirect } from '@reach/router'

import { Flex } from 'src/components/Box'
import PageBackground, { BackgroundType } from 'src/components/PageBackground'
import Loading from 'src/components/Loading'
import useLoggedIn from 'src/hooks/useLoggedIn'
import { dashboardPath } from 'src/lib/paths'
import ErrorPage from 'src/pages/ErrorPage'

const UnauthenticatedContainer: React.FunctionComponent = ({ children }) => {
  const { viewer, loading, error } = useLoggedIn()

  if (loading) return <Loading />
  if (error) return <ErrorPage />
  if (viewer) return <Redirect noThrow to={dashboardPath()} />

  return (
    <>
      <PageBackground type={BackgroundType.Onboarding} />

      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={[0, 7, 9]}
      >
        {children}
      </Flex>
    </>
  )
}

export default UnauthenticatedContainer

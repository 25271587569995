import { useViewerQuery, Viewer } from 'src/graphql-generated'
import { ApolloError } from 'apollo-boost'

type LoggedInResult = {
  error: ApolloError | undefined
  loading: boolean
  viewer: Viewer | null
}

const useLoggedIn = (): LoggedInResult => {
  const { data, error, loading } = useViewerQuery()

  if (loading) {
    return { loading: true, error: undefined, viewer: null }
  }

  if (error) {
    return { loading: false, error, viewer: null }
  }

  return { loading: false, error: undefined, viewer: data ? data.viewer : null }
}

export default useLoggedIn

import React from 'react'

import { Card, SubCard } from 'src/components/Card'
import {
  ProjectPhase,
  ProjectPhaseStatus,
  ProjectPhaseState,
} from 'src/graphql-generated'
import { Link } from 'src/components/Link'
import { Flex, PositionContainer } from 'src/components/Box'
import { NotificationDot } from 'src/components/Notification'

import CardContent from './CardContent'
import { PhaseCardLabel } from './PhaseCardLabel'

export interface PhaseCardProps {
  cardDescription: string
  name: string
  phase: ProjectPhase
  phaseStatus: ProjectPhaseStatus
  toDocument: string
  toExplore: string
  canEdit: boolean
  hasNotification: boolean
}

export const APPROVED_BACKGROUND_COLOR = '#f0fffe'

const PhaseCardState: React.FunctionComponent<{
  phaseStatus: ProjectPhaseStatus
}> = ({ phaseStatus }) => {
  switch (phaseStatus.state) {
    case ProjectPhaseState.Approved:
      return (
        <SubCard
          p="3"
          background={APPROVED_BACKGROUND_COLOR}
          borderColor="topaz"
          width="216px"
        >
          <Flex justifyContent="center">
            <PhaseCardLabel state={phaseStatus.state} />
          </Flex>
        </SubCard>
      )
    case ProjectPhaseState.Returned:
      return (
        <SubCard p="3" borderColor="lightGrey" width="216px">
          <Flex justifyContent="center">
            <PhaseCardLabel state={phaseStatus.state} />
          </Flex>
        </SubCard>
      )
    case ProjectPhaseState.Submitted:
      return (
        <SubCard p="3" borderColor="lightGrey" width="216px">
          <Flex justifyContent="center">
            <PhaseCardLabel state={phaseStatus.state} />
          </Flex>
        </SubCard>
      )
    default:
      return null
  }
}

const PhaseCard: React.FunctionComponent<PhaseCardProps> = ({
  cardDescription,
  name,
  phase,
  toDocument,
  toExplore,
  canEdit,
  phaseStatus,
  hasNotification,
}) => (
  <PositionContainer position="relative">
    {hasNotification && (
      <PositionContainer zIndex={2} position="absolute" top="-5px" left="-5px">
        <NotificationDot />
      </PositionContainer>
    )}
    <Flex alignItems="center" flexDirection="column">
      <Link plain as={canEdit ? undefined : 'div'} to={toExplore}>
        <Card
          width="230px"
          height="225px"
          borderColor={canEdit ? 'dodgerBlue' : 'lightGrey'}
          textAlign="center"
        >
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <CardContent
              canEdit={canEdit}
              name={name}
              cardDescription={cardDescription}
              phase={phase}
            />
          </Flex>
        </Card>
      </Link>
      <Link plain as={canEdit ? undefined : 'div'} to={toDocument}>
        <PhaseCardState phaseStatus={phaseStatus} />
      </Link>
    </Flex>
  </PositionContainer>
)

export default PhaseCard

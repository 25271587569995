import React from 'react'
import {
  GooglePickerAPI,
  DriveDocument,
  PickerAction,
  PickedAction,
  loadPickerAPI,
  getTokenClient,
} from 'src/lib/gapis'
import * as Sentry from '@sentry/react'

const API_KEY = process.env.REACT_APP_GOOGLE_PICKER_API_KEY
const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID

interface PickerOptions {
  accessToken: string
  onSelect: (item: DriveDocument[]) => void
  documentType?: keyof GooglePickerAPI['ViewId']
  multi?: boolean
  title?: string
  mineOnly?: boolean
  includeFolders?: boolean
}

const createPicker = (
  picker: GooglePickerAPI,
  {
    accessToken,
    onSelect,
    documentType = 'DOCS',
    multi = false,
    title,
    mineOnly = false,
    includeFolders = false,
  }: PickerOptions
) => {
  if (!API_KEY) {
    throw new Error('Expected to find Picker API Key')
  }

  if (!APP_ID) {
    throw new Error('Expected to find Google App ID')
  }

  const isPickedAction = (x: PickerAction): x is PickedAction =>
    x.action === picker.Action.PICKED
  //.addView(new picker.DocsView(picker.ViewId[documentType]))
  const builder = new picker.PickerBuilder()
    .addView(picker.ViewId[documentType])
    .setOAuthToken(accessToken)
    .setDeveloperKey(API_KEY)
    .setAppId(APP_ID)
    .setSize(1051, 650)
    .setCallback((data: PickerAction) => {
      if (isPickedAction(data)) {
        onSelect(data.docs)
      }
    })

  includeFolders &&
    builder.addView(
      new picker.DocsView(picker.ViewId.FOLDERS).setSelectFolderEnabled(true)
    )
  multi && builder.enableFeature(picker.Feature.MULTISELECT_ENABLED)
  mineOnly && builder.enableFeature(picker.Feature.MINE_ONLY)
  title && builder.setTitle(title)

  builder.build().setVisible(true)
}

export type Params = Omit<PickerOptions, 'accessToken'>
export const useGoogleFilePicker = (pickerOptions: Params) => {
  const [picker, setPicker] = React.useState<GooglePickerAPI | null>(null)

  React.useEffect(() => {
    if (!picker) {
      loadPickerAPI().then(setPicker)
    }
  })

  if (!picker) {
    return { loading: true, openFilePicker: null }
  }

  return {
    loading: false,
    openFilePicker: async () => {
      //TODO: Remove the following two statements...
      Sentry.captureMessage(
        'User is attempting to open the google drive file picker.',
        Sentry.Severity.Debug
      )

      //TODO: Need to eventually turn the response:any into a type for TokenClientResponse
      const { tokenClient } = await getTokenClient(async (response: any) => {
        if (response.error !== undefined) {
          throw response
        }
        if (!response.access_token) {
          throw new Error('Access not granted.')
        } else {
          const accessToken = response.access_token
          createPicker(picker, {
            accessToken,
            ...pickerOptions,
          })
        }
      })
      tokenClient.requestAccessToken({ prompt: 'consent' })
    },
  }
}

import css from '@emotion/css'
import * as shadow from './boxShadow'
import * as typography from './typography'
import * as animations from './animations'
import { theme } from './theme'

export const flowStyles = <T>(...styles: ((props: T) => {} | undefined)[]) => (
  props: T
) => styles.map(fn => fn(props))

export const focusOutline = css`
  outline: 4px auto ${theme.colors.dodgerBlue};
`

export { shadow, typography, animations, theme }

import React from 'react'
import { Box } from 'src/components/Box'
import { trackEvent } from 'src/lib/analytics'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import { globalViewerId } from 'src/lib/globalViewerId'

const YouTube = React.lazy(() => import('react-youtube'))

interface Props {
  videoId: string
  width: string
}

const YouTubePlayer: React.FunctionComponent<Props> = ({ videoId, width }) => (
  <Box my="3">
    <React.Suspense
      fallback={<LoadingYouTubePlayer width={width} height="360px" />}
    >
      <YouTube
        videoId={videoId}
        onPlay={event => {
          const videoTitle = event.target.getVideoData().title

          //globalViewerId.id is set in src/components/Header/index.tsx
          if (globalViewerId.id !== 0) {
            recordAnalyticsEvent({
              userId: globalViewerId.id,
              event: AnalyticsEvents.VideoPlay,
              url: window.location.href,
              type: AnalyticsEventTypes.Video,
              title: videoTitle,
              mediaSource: 'youtube',
              objectId: event.target.getVideoData().video_id,
              mediaSourceUrl: event.target.getVideoUrl(),
              referrer: null,
              trackStartTime: Math.round(event.target.getCurrentTime()),
              timeZoneOffset: new Date().getTimezoneOffset(),
            })
          }

          trackEvent(
            AnalyticsEventTypes.Video,
            AnalyticsEvents.VideoPlay,
            event.target.getVideoData().video_id,
            Math.round(event.target.getCurrentTime())
          )
        }}
        onPause={event => {
          //globalViewerId.id is set in src/components/Header/index.tsx
          if (globalViewerId.id !== 0) {
            recordAnalyticsEvent({
              userId: globalViewerId.id,
              event: AnalyticsEvents.VideoPause,
              url: window.location.href,
              type: AnalyticsEventTypes.Video,
              title: event.target.getVideoData().title,
              mediaSource: 'youtube',
              objectId: event.target.getVideoData().video_id,
              mediaSourceUrl: event.target.getVideoUrl(),
              referrer: null,
              trackStartTime: Math.round(event.target.getCurrentTime()),
              timeZoneOffset: new Date().getTimezoneOffset(),
            })
          }

          trackEvent(
            AnalyticsEventTypes.Video,
            AnalyticsEvents.VideoPause,
            event.target.getVideoData().video_id,
            Math.round(event.target.getCurrentTime())
          )
        }}
        onEnd={event => {
          //globalViewerId.id is set in src/components/Header/index.tsx
          if (globalViewerId.id !== 0) {
            recordAnalyticsEvent({
              userId: globalViewerId.id,
              event: AnalyticsEvents.VideoEnd,
              url: window.location.href,
              type: AnalyticsEventTypes.Video,
              title: event.target.getVideoData().title,
              mediaSource: 'youtube',
              objectId: event.target.getVideoData().video_id,
              mediaSourceUrl: event.target.getVideoUrl(),
              referrer: null,
              trackStartTime: Math.round(event.target.getCurrentTime()),
              timeZoneOffset: new Date().getTimezoneOffset(),
            })
          }

          trackEvent(
            AnalyticsEventTypes.Video,
            AnalyticsEvents.VideoEnd,
            event.target.getVideoData().video_id,
            Math.round(event.target.getCurrentTime())
          )
        }}
        opts={{
          width: width,
          playerVars: {
            rel: 0, //  related videos come from the same channel as the video that was just played
            modestbranding: 1, // does not show a YouTube logo
          },
        }}
      />
    </React.Suspense>
  </Box>
)

const LoadingYouTubePlayer: React.FunctionComponent<
  Pick<Props, 'width'> & { height: string }
> = ({ width, height }) => <Box width={width} height={height} bg="black" />

export default YouTubePlayer

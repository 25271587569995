import React from 'react'

import { Box } from 'src/components/Box'
import { Body, SmallBody } from 'src/components/text'
import { Link } from 'src/components/Link'
import { AttachedDocument } from 'src/graphql-generated'
import strings from 'src/strings'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import { globalViewerId } from 'src/lib/globalViewerId'
import { trackEvent } from 'src/lib/analytics'

interface Props {
  documents: Pick<AttachedDocument, 'serviceId' | 'url' | 'name'>[]
}

export const DocumentList: React.FunctionComponent<Props> = ({ documents }) => {
  if (documents.length === 0) {
    return (
      <SmallBody my="3" color="mediumGrey">
        {strings.documentStep.placeholderNoDocuments}
      </SmallBody>
    )
  }

  return (
    <>
      {documents.map(doc => (
        <Box my="4" key={doc.serviceId}>
          <Body>
            <Link
              to={doc.url}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => {
                //globalViewerId.id is set in src/components/Header/index.tsx
                //current-location will be the value of the previous url when this is read
                if (globalViewerId.id !== 0) {
                  recordAnalyticsEvent({
                    userId: globalViewerId.id,
                    event: AnalyticsEvents.LinkClick,
                    url: doc.url,
                    type: AnalyticsEventTypes.Link,
                    title: doc.name,
                    mediaSource: null,
                    objectId: null,
                    mediaSourceUrl: null,
                    referrer: sessionStorage.getItem('current-location'),
                    trackStartTime: null,
                    timeZoneOffset: new Date().getTimezoneOffset(),
                  })
                }

                trackEvent(
                  AnalyticsEventTypes.Link,
                  AnalyticsEvents.LinkClick,
                  doc.url
                )
              }}
            >
              {doc.name}
            </Link>
          </Body>
        </Box>
      ))}
    </>
  )
}

import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { Body } from 'src/components/text'
import { ProjectPhase } from 'src/lib/project'
import { ExploreStepModel } from 'src/contentful/models'
import renderRichTextDocument from 'src/contentful/renderRichTextDocument'
import useContentfulEntry from 'src/hooks/useContentfulEntry'
import Loading from 'src/components/Loading'
import { ExploreStepEntryId } from 'src/contentful/entryIds'
import PageLayout from 'src/components/PageLayout'
import ErrorPage from 'src/pages/ErrorPage'

type Props = RouteComponentProps & { phase: ProjectPhase }

export const ExploreStep: React.FunctionComponent<Props> = ({ phase }) => {
  const entryId = entryIdFromPhase(phase)

  return <ExploreStepContentfulEntry entryId={entryId} />
}

const entryIdFromPhase = (phase: ProjectPhase) => {
  switch (phase) {
    case ProjectPhase.Understand:
      return ExploreStepEntryId.Understand

    case ProjectPhase.Ideate:
      return ExploreStepEntryId.Ideate

    case ProjectPhase.Pitch:
      return ExploreStepEntryId.Pitch

    case ProjectPhase.Prototype:
      return ExploreStepEntryId.Prototype

    //no default
  }
}

export const ExploreStepContentfulEntry: React.FunctionComponent<{
  entryId: string
  preview?: boolean
}> = ({ entryId, preview = false }) => {
  const { loading, error, fields } = useContentfulEntry<ExploreStepModel>(
    entryId,
    preview
  )

  if (loading) return <Loading />
  if (error || !fields) return <ErrorPage error={error} />

  return <ExploreStepLayout {...fields} />
}

const ExploreStepLayout: React.FunctionComponent<ExploreStepModel> = ({
  title,
  introduction,
  body,
}) => (
  <PageLayout title={title}>
    <Body mb="4">{introduction}</Body>
    {renderRichTextDocument(body)}
  </PageLayout>
)

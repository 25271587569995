import React from 'react'
import isPropValid from '@emotion/is-prop-valid'
import { Link as ReachLink, LinkProps as ReachLinkProps } from '@reach/router'
import styled, { AsProps } from 'src/styles/styled'
import { focusOutline } from 'src/styles'

interface Props extends AsProps, ReachLinkProps<any> {
  plain?: boolean // hide underline?
}

const capturePlain = { shouldForwardProp: isPropValid }

export const SmartLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ children, to, ...props }, ref) => {
    if (props.target || (to && isExternalLink(to))) {
      return (
        <a ref={ref} href={to} {...props}>
          {children}
        </a>
      )
    }

    return (
      <ReachLink ref={ref as any} to={to} {...props}>
        {children}
      </ReachLink>
    )
  }
)

export const Link = styled(SmartLink, capturePlain)`
  border-bottom: ${props =>
    props.plain ? 'none' : `1px solid ${props.theme.colors.skyBlue}`};

  padding-bottom: ${props => (props.plain ? 'none' : '1px')};

  color: ${props => props.theme.colors.charcoalGrey};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.colors.dodgerBlue};
  }

  &:focus {
    ${focusOutline};
  }

  &:active {
    color: ${props => props.theme.colors.dodgerBlue};
    border-bottom: 0;
    outline: none;
  }
`

export const isExternalLink = (to: string) =>
  to.startsWith('http://') ||
  to.startsWith('https://') ||
  to.startsWith('//') ||
  to.startsWith('mailto:') ||
  (process.env.REACT_APP_API_URL &&
    to.startsWith(process.env.REACT_APP_API_URL))

export default Link

import styled from 'src/styles/styled'
import { theme } from 'src/styles/theme'

const ErrorLabel = styled.span`
  font-family: ${theme.fonts.mavenPro};
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: ${theme.colors.coral};
`

export default ErrorLabel

import styled from 'src/styles/styled'
import { shadow, flowStyles } from 'src/styles'
import { Theme } from 'src/styles/theme'
import {
  borderColor,
  height,
  HeightProps,
  textAlign,
  TextAlignProps,
  BackgroundProps,
  background,
  SpaceProps,
  space,
} from 'styled-system'

interface Props
  extends HeightProps,
    TextAlignProps,
    BackgroundProps,
    SpaceProps {
  borderColor: keyof Theme['colors']
  width: string
}

const cardStyles = flowStyles(
  props => `
    max-width: ${props.width};
    width: 90vw;
    background-color: ${props.theme.colors.white};
  `,
  height,
  borderColor,
  textAlign,
  background,
  space
)

export const Card = styled.div<Props>`
  ${shadow.small};
  border-radius: 4px;
  border: solid 2px;
  z-index: 1;
  padding: ${props => props.theme.space[5]}px;
  padding-bottom: ${props => props.theme.space[6]}px;
  ${cardStyles};
`

export const SubCard = styled.div<Props>`
  ${shadow.small};
  border: solid 2px;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 0;
  padding: ${props => props.theme.space[4]}px;
  ${cardStyles};
`

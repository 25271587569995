import React from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import {
  IsValidClassGroupCodeDocument,
  IsValidClassGroupCodeQuery,
  IsValidClassGroupCodeQueryVariables,
} from 'src/graphql-generated'
import { strings } from 'src/strings'
import TextField from './FormFields/TextField'
import DebouncingValidatingField from './FormFields/DebouncingValidatingField'

interface Props {
  name: string
}

const GroupClassCodeField: React.FunctionComponent<Props> = ({ name }) => {
  const client = useApolloClient()
  const [validateCache, setValidateCache] = React.useState<
    Record<string, string | undefined>
  >({})

  const validateCode = (code: string) => {
    if (!code) {
      return strings.formValidation.required
    }

    if (code in validateCache) {
      return validateCache[code]
    }

    return client
      .query<IsValidClassGroupCodeQuery, IsValidClassGroupCodeQueryVariables>({
        query: IsValidClassGroupCodeDocument,
        variables: { code },
      })
      .then(({ data }) => {
        const error = data.isValidClassGroupCode
          ? undefined
          : strings.groupClassCodeField.invalidCodeError

        setValidateCache({ ...validateCache, [code]: error })
        return error
      })
  }

  return (
    <DebouncingValidatingField
      initialValue=""
      validate={validateCode}
      name={name}
      parse={value => value && value.toUpperCase().trim()}
    >
      {field => (
        <TextField
          field={field}
          placeholder={strings.groupClassCodeField.placeholder}
          label={strings.groupClassCodeField.label}
        />
      )}
    </DebouncingValidatingField>
  )
}

export default GroupClassCodeField

import styled from 'src/styles/styled'

interface Props {
  size?: number
}
export const NotificationDot = styled.div<Props>`
  width: ${props => props.size || 14}px;
  height: ${props => props.size || 14}px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.coral};
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.1);
`

import React, { useEffect } from 'react'
import { Location, WindowLocation } from '@reach/router'
import { trackPageView } from 'src/lib/analytics'

const TrackLocation: React.FunctionComponent<{
  location: WindowLocation
}> = ({ location }) => {
  useEffect(() => {
    trackPageView(location)
  }, [location])

  return null
}

const PageViewTracker: React.FunctionComponent = () => (
  <Location>
    {({ location }) => (
      <>
        <TrackLocation location={location} />
      </>
    )}
  </Location>
)

export default PageViewTracker

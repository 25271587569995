import React from 'react'
import { Title1, SectionTitle, SmallBody } from 'src/components/text'
import { projectPath } from 'src/lib/paths'
import Link from 'src/components/Link'
import { Flex, PositionContainer } from 'src/components/Box'
import { Card } from 'src/components/Card'
import GlobalGoalIcon from 'src/components/GlobalGoalIcon'
import { NotificationDot } from 'src/components/Notification'
import strings from 'src/strings'
import { ProjectDetailsWithNotification } from 'src/lib/project'
import { TeamMember } from 'src/components/TeamMember'
import ProjectProgress from './ProjectProgress'
interface Props {
  project: ProjectDetailsWithNotification
  isLinkable?: boolean
  showAvatars?: boolean
}

const ProjectCard: React.FunctionComponent<Props> = ({
  project,
  isLinkable = true,
  showAvatars = true,
}) =>
  isLinkable ? (
    <Link plain to={projectPath(project.id)}>
      <PositionContainer position="relative">
        {project.hasNotification && (
          <PositionContainer position="absolute" top="-5px" left="-5px">
            <NotificationDot />
          </PositionContainer>
        )}
        <Card width="370px" borderColor="lightGrey">
          <Flex>
            <Flex width="264px">
              <Title1 breakWord>{project.name}</Title1>
            </Flex>
            <Flex width="106px" justifyContent="end">
              <GlobalGoalIcon width="106" globalGoal={project.globalGoal} />
            </Flex>
          </Flex>
          <Flex mt="2">
            <SmallBody breakWord color="mediumGrey">
              {project.description}
            </SmallBody>
          </Flex>
          <Flex justifyContent="space-between">
            <Flex auto flexDirection="column">
              <SectionTitle breakWord mt="4">
                {strings.projectCard.teamTitle}
              </SectionTitle>
              <SmallBody breakWord color="mediumGrey">
                {project.teamName}
              </SmallBody>
              <Flex width="200px" flexWrap="wrap" mt="3">
                {showAvatars
                  ? project.teamMembers.map(student => (
                      <TeamMember key={student.id} {...student} />
                    ))
                  : ''}
              </Flex>
            </Flex>
            <Flex>
              <SectionTitle breakWord mt="4">
                {/* strings.projectCard.pitchVideoTitle */}
                {/* Put Youtube thumbnail here */}
              </SectionTitle>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" mt="4">
            <Flex auto flexDirection="column">
              <SectionTitle breakWord>
                {strings.projectCard.progressTitle}
              </SectionTitle>
              <ProjectProgress project={project} />
            </Flex>
          </Flex>
        </Card>
      </PositionContainer>
    </Link>
  ) : (
    <PositionContainer position="relative">
      {project.hasNotification && (
        <PositionContainer position="absolute" top="-5px" left="-5px">
          <NotificationDot />
        </PositionContainer>
      )}
      <Card width="370px" borderColor="lightGrey">
        <Flex>
          <Flex width="264px">
            <Title1 breakWord>{project.name}</Title1>
          </Flex>
          <Flex width="106px" justifyContent="end">
            <GlobalGoalIcon width="106" globalGoal={project.globalGoal} />
          </Flex>
        </Flex>
        <Flex mt="2">
          <SmallBody breakWord color="mediumGrey">
            {project.description}
          </SmallBody>
        </Flex>
        <Flex justifyContent="space-between">
          <Flex auto flexDirection="column">
            <SectionTitle breakWord mt="4">
              {strings.projectCard.teamTitle}
            </SectionTitle>
            <SmallBody breakWord color="mediumGrey">
              {project.teamName}
            </SmallBody>
            <Flex width="200px" flexWrap="wrap">
              {showAvatars
                ? project.teamMembers.map(student => (
                    <TeamMember key={student.id} {...student} />
                  ))
                : ''}
            </Flex>
          </Flex>
          <Flex>
            <SectionTitle breakWord mt="4">
              {/* strings.projectCard.pitchVideoTitle */}
              {/* Put Youtube thumbnail here */}
            </SectionTitle>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" mt="4">
          <Flex auto flexDirection="column">
            <SectionTitle breakWord>
              {strings.projectCard.progressTitle}
            </SectionTitle>
            <ProjectProgress project={project} />
          </Flex>
        </Flex>
      </Card>
    </PositionContainer>
  )

export default ProjectCard

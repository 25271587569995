import React from 'react'
import { Box, Flex } from 'src/components/Box'
import { SmallBody } from 'src/components/text'

interface ImageProps {
  align: 'left' | 'right' | 'center'
  alt: string
  src: string
  caption?: string
  maxHeight?: number
}

const Image: React.FunctionComponent<ImageProps> = ({
  align,
  alt,
  src,
  caption,
  maxHeight,
}) => {
  const AlignContainer = chooseContainer(align)

  return (
    <AlignContainer>
      <Box m={align === 'center' ? 0 : 3} my="3" as="figure">
        <img
          src={src}
          alt={alt}
          css={{
            maxWidth: '100%',
            maxHeight,
          }}
        />
        {caption && (
          <Box pt="2">
            <SmallBody as="figcaption">{caption}</SmallBody>
          </Box>
        )}
      </Box>
    </AlignContainer>
  )
}

const chooseContainer = (align: ImageProps['align']) => {
  switch (align) {
    case 'left':
      return Left

    case 'right':
      return Right

    case 'center':
      return Center

    // no default
  }
}

const Center: React.FunctionComponent = ({ children }) => (
  <Flex justifyContent="center">{children}</Flex>
)

const Left: React.FunctionComponent = ({ children }) => (
  <Box css={{ float: 'left' }}>{children}</Box>
)

const Right: React.FunctionComponent = ({ children }) => (
  <Box css={{ float: 'right' }}>{children}</Box>
)

export default Image

import React from 'react'
import css from '@emotion/css'
import { minWidth, MinWidthProps, space, SpaceProps } from 'styled-system'
import styled from 'src/styles/styled'
import { theme } from 'src/styles/theme'
import { SmartLink } from 'src/components/Link'
import { Flex } from './Box'

interface StyledButtonProps extends MinWidthProps, SpaceProps {
  variant?: 'primary' | 'secondary'
}

interface CommonProps extends StyledButtonProps {
  disabled?: boolean
}

interface ButtonType extends CommonProps {
  onClick: () => void
  type?: 'button'
}

interface FormButtonType extends CommonProps {
  type: 'submit' | 'reset'
  onClick?: undefined
}

type Props = ButtonType | FormButtonType

const primaryStyles = css`
  background-color: ${theme.colors.dodgerBlue};
  color: ${theme.colors.white};

  &:focus,
  &:hover {
    background-color: ${theme.colors.skyBlue};
  }
  &:active {
    background-color: ${theme.colors.peacockBlue};
  }
  &:disabled {
    background-color: ${theme.colors.middleGrey};
  }
`

const secondaryStyles = css`
  background-color: ${theme.colors.white};
  color: ${theme.colors.dodgerBlue};

  &:focus,
  &:hover,
  &:focus svg,
  &:hover svg {
    color: ${theme.colors.skyBlue};
  }
  &:active,
  &:active svg {
    color: ${theme.colors.peacockBlue};
  }
  &:disabled,
  &:disabled svg {
    color: ${theme.colors.middleGrey};
  }
`

const StyledButton = styled<'button', StyledButtonProps>('button')`
  display: block;
  border: solid 2px ${theme.colors.dodgerBlue};
  border-radius: 4px;
  padding: 11px ${theme.space[5]}px;
  cursor: pointer;
  font-family: ${theme.fonts.karla};
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  &:focus,
  &:hover {
    border-color: ${theme.colors.skyBlue};
  }
  &:active {
    border-color: ${theme.colors.peacockBlue};
  }
  &:disabled {
    border-color: ${theme.colors.middleGrey};
    cursor: default;
  }

  ${props => (props.variant === 'secondary' ? secondaryStyles : primaryStyles)}

  ${minWidth}
  ${space}
`

const Button: React.FunctionComponent<Props> = ({
  children,
  type = 'button',
  disabled = false,
  variant = 'primary',
  ...rest
}) => {
  return (
    <StyledButton variant={variant} disabled={disabled} type={type} {...rest}>
      <Flex flexDirection="row" justifyContent="center">
        {children}
      </Flex>
    </StyledButton>
  )
}

export const ButtonLink = StyledButton.withComponent(SmartLink)

export default Button

import React, { useEffect, useState } from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import { Match } from '@reach/router'

import { Box, Flex } from 'src/components/Box'
import { Link } from 'src/components/Link'
import { buttonLabel } from 'src/styles/typography'
import useLoggedIn from 'src/hooks/useLoggedIn'
import {
  ProfileIcon,
  ProfileIconLoadingPlaceholder,
} from 'src/components/ProfileIcon'
import { Viewer, ClassGroupRole } from 'src/graphql-generated'
import { logoutUrl } from 'src/lib/urls'
import strings from 'src/strings'
import {
  innovatorsGuidePath,
  dashboardPath,
  contactPath,
  showcasePath,
  communityPath,
} from 'src/lib/paths'
//TODO: remove import { getGoogleAuth } from 'src/lib/gapis'
import Button from 'src/components/Button'
import Popup from 'src/components/Popup'
import JoinClassGroupModal from 'src/components/JoinClassGroupModal'
import { SmallBody, Body, Title2 } from 'src/components/text'
import { WordMark } from 'src/components/icons'
import Action from 'src/components/Action'
import Drawer from 'src/components/Drawer'
import HamburgerSVG from 'assets/svgs/hamburger.svg'
import { globalViewerId } from 'src/lib/globalViewerId'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'

const Header: React.FunctionComponent = () => (
  <Flex
    py={[3, 4]}
    px={[4, 6, 7]}
    justifyContent="center"
    alignItems="center"
    as="header"
  >
    <Flex flexBasis="960px" justifyContent="space-between" alignItems="center">
      <Link plain to="/">
        <Flex>
          <WordMark height="30" />
        </Flex>
      </Link>
      <Flex>
        <HeaderContents />
      </Flex>
    </Flex>
  </Flex>
)

const MobileNavItem: React.FunctionComponent<{
  to: string
  mt?: string
  target?: string
  onClick?: () => void
}> = ({ to, mt, target, children, onClick }) => (
  <Match path={to}>
    {props =>
      props.match ? (
        <Title2 mt={mt} color="dodgerBlue" as="div">
          {children}
        </Title2>
      ) : (
        <Title2 mt={mt} as="div">
          <Link target={target} to={to} onClick={onClick}>
            {children}
          </Link>
        </Title2>
      )
    }
  </Match>
)

const MobileNav: React.FunctionComponent<
  Pick<HeaderContentsLayoutProps, 'logout' | 'viewer'>
> = ({ logout, viewer }) => {
  const [showDrawer, setDrawer] = React.useState(false)
  const [studentJoinModalIsOpen, setStudentJoinModalIsOpen] = useState(false)

  const hideDrawer = () => setDrawer(false)

  return (
    <Flex display={['flex', 'none']}>
      <Action onClick={() => setDrawer(true)}>
        <img
          src={HamburgerSVG}
          alt="Navigation Menu Icon"
          width="27px"
          height="30px"
        />
      </Action>
      <Drawer isOpen={showDrawer} onClose={() => setDrawer(false)}>
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Flex p="5" flexDirection="column" as="nav">
            <MobileNavItem
              onClick={hideDrawer}
              mt="3"
              to={innovatorsGuidePath()}
            >
              {strings.header.innovatorsGuide}
            </MobileNavItem>
            <MobileNavItem onClick={hideDrawer} mt="3" to={showcasePath()}>
              {strings.header.showcase}
            </MobileNavItem>
            <MobileNavItem onClick={hideDrawer} mt="3" to={communityPath()}>
              {strings.header.community}
            </MobileNavItem>
            <MobileNavItem onClick={hideDrawer} mt="3" to={dashboardPath()}>
              {strings.header.myDashboard}
            </MobileNavItem>
          </Flex>
          <Flex
            p="5"
            mb="6"
            flexDirection="column"
            justifyContent="space-between"
            borderTop="2px solid lightGrey"
          >
            <Flex>
              <Box>
                <ProfileIcon user={viewer} />
              </Box>
              <Flex css={{ overflow: 'hidden' }} flexDirection="column" ml="4">
                <Body truncate>{viewer.name || 'Me'}</Body>
                <SmallBody truncate>{viewer.email}</SmallBody>
              </Flex>
            </Flex>
            {viewer.role === ClassGroupRole.Student && (
              <>
                <Flex>
                  <Box mt="5">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStudentJoinModalIsOpen(true)
                        setDrawer(false)
                      }}
                    >
                      {strings.header.joinClass}
                    </Button>
                  </Box>
                </Flex>
                <JoinClassGroupModal
                  open={studentJoinModalIsOpen}
                  cancelOnClick={() => setStudentJoinModalIsOpen(false)}
                />
              </>
            )}
            <Box mt="5">
              <Button variant="secondary" onClick={logout}>
                {strings.header.logout}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  )
}

const HeaderContents: React.FunctionComponent = () => {
  const { loading, viewer } = useLoggedIn()
  const logout = useLogout()
  const [avatarModalOpen, setAvatarModalOpen] = React.useState(false)

  // using this hook to handle loading the applicaiton initially
  // from a tab duplication or a link to a page within the application
  // other than the sign in page
  useEffect(() => {
    const isInitialLoad = sessionStorage.getItem('initial-load-done')
      ? true
      : false

    //TODO: remove this after migration is complete
    document.cookie = 'G_AUTH2_MIGRATION=enforced'

    //globalViewerId.id is set in src/components/Header/index.tsx
    if (isInitialLoad && globalViewerId.id !== 0) {
      recordAnalyticsEvent({
        userId: globalViewerId.id,
        event: AnalyticsEvents.PageView,
        url: window.location.href,
        type: AnalyticsEventTypes.Page,
        title: null,
        mediaSource: null,
        objectId: null,
        mediaSourceUrl: null,
        referrer: document.referrer.length > 0 ? document.referrer : null,
        trackStartTime: null,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })
      sessionStorage.removeItem('initial-load-done')
    }
  })

  if (!viewer) {
    return (
      <>
        <Box>
          <ProfileIconLoadingPlaceholder />
        </Box>

        {!loading && (
          <Body textAlign="right">
            {strings.formatString(strings.header.contactUs, {
              contactUsLink: (
                <Link target="_blank" to={contactPath()}>
                  {strings.header.contactUsLink}
                </Link>
              ),
            })}
          </Body>
        )}
      </>
    )
  }

  //This is kind of bad.  Setting a global variable so that I can get at the viewer id for analytics tracking purposes.
  globalViewerId.id = viewer.id

  return (
    <HeaderContentsLayout
      logout={logout}
      modalOpen={avatarModalOpen}
      setModalState={setAvatarModalOpen}
      viewer={viewer}
    />
  )
}

interface HeaderContentsLayoutProps {
  viewer: Viewer
  setModalState: (isOpen: boolean) => void
  logout: () => Promise<void>
  modalOpen: boolean
}

export const HeaderContentsLayout: React.FunctionComponent<
  HeaderContentsLayoutProps
> = ({ viewer, setModalState, modalOpen, logout }) => {
  const [studentJoinModalIsOpen, setStudentJoinModalIsOpen] = useState(false)

  return (
    <>
      <Flex display={['none', 'flex']}>
        <Flex alignItems="center" justifyContent="space-between" as="nav">
          <NavLink to={innovatorsGuidePath()}>
            {strings.header.innovatorsGuide}
          </NavLink>
          <NavLink to={showcasePath()}>{strings.header.showcase}</NavLink>
          <NavLink to={communityPath()}>{strings.header.community}</NavLink>
          <NavLink to={dashboardPath()}>{strings.header.myDashboard}</NavLink>
        </Flex>
        <Box ml="6">
          <Popup
            trigger={
              <Action onClick={() => {}} data-cy="avatar-button">
                <ProfileIcon user={viewer} />
              </Action>
            }
            isOpen={modalOpen}
            onRequestClose={() => setModalState(false)}
          >
            <Flex
              minWidth="200px"
              maxWidth="300px"
              flexDirection="column"
              p="5"
            >
              <Body truncate title={viewer.name || 'Me'} mb="2">
                {viewer.name || 'Me'}
              </Body>
              <SmallBody
                title={viewer.email}
                truncate
                color="mediumGrey"
                mb="5"
              >
                {viewer.email}
              </SmallBody>
              {viewer.role === ClassGroupRole.Student && (
                <>
                  <Flex>
                    <Box mb="5">
                      <Button
                        data-cy="join-classGroup-button"
                        variant="primary"
                        minWidth="177px"
                        onClick={() => {
                          setStudentJoinModalIsOpen(true)
                        }}
                      >
                        {strings.header.joinClass}
                      </Button>
                    </Box>
                  </Flex>
                  <JoinClassGroupModal
                    open={studentJoinModalIsOpen}
                    cancelOnClick={() => setStudentJoinModalIsOpen(false)}
                  />
                </>
              )}
              <Flex>
                <Button variant="secondary" minWidth="177px" onClick={logout}>
                  {strings.header.logout}
                </Button>
              </Flex>
            </Flex>
          </Popup>
        </Box>
      </Flex>
      <MobileNav logout={logout} viewer={viewer} />
    </>
  )
}

const NavLink: React.FunctionComponent<{ to: string; target?: string }> = ({
  children,
  to,
  target = '_self',
}) => (
  <Box mx="6">
    <Link to={to} target={target} css={buttonLabel} plain>
      {children}
    </Link>
  </Box>
)

const useLogout = () => {
  const apolloClient = useApolloClient()

  return async () => {
    await apolloClient.clearStore()

    //TODO: remove this after migration is complete
    document.cookie =
      'G_AUTH2_MIGRATION=; expires=Thu, 18 Dec 2013 12:00:00 UTC;'

    //globalViewerId.id is set in src/components/Header/index.tsx
    //current-location will be the value of the previous url when this is read
    if (globalViewerId.id !== 0) {
      recordAnalyticsEvent({
        userId: globalViewerId.id,
        event: AnalyticsEvents.SignOut,
        url: window.location.href,
        type: AnalyticsEventTypes.Auth,
        title: null,
        mediaSource: null,
        objectId: null,
        mediaSourceUrl: null,
        referrer: sessionStorage.getItem('current-location'),
        trackStartTime: null,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })
    }

    window.location.assign(logoutUrl)
  }
}

export default Header

import React from 'react'
import { Router } from '@reach/router'

import { ContentfulPageEntryId } from 'src/contentful/entryIds'
import Home from 'src/pages/Home'
import NotFound from 'src/pages/NotFound'
import ProjectOverview from 'src/pages/ProjectOverview'
import {
  contentfulPreviewPath,
  dashboardPath,
  showcasePath,
  innovatorsGuidePath,
  privacyPolicyPath,
  projectPath,
  createStudentAccountPath,
  createTeacherAccountPath,
  termsOfUsePath,
  chooseAccountTypePath,
  communityPath,
} from 'src/lib/paths'
import ContentfulPage from 'src/pages/ContentfulPage'
import NewProject from 'src/pages/NewProject'
import Dashboard from 'src/pages/Dashboard'
import Showcase from 'src/pages/Showcase/Showcase'
import useTrackUser from 'src/hooks/useTrackUser'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import PageViewTracker from './Analytics/PageViewTracker'
import LazyRoute from './LazyRoute'
import ScrollToTop from './ScrollToTop'

const ContentfulPreviewRouter = React.lazy(() =>
  import('src/contentful/ContentfulPreviewRouter')
)

const InnovatorsGuide = React.lazy(() => import('src/pages/InnovatorsGuide'))
const CreateStudentAccount = React.lazy(() =>
  import('src/pages/CreateStudentAccount')
)
const CreateTeacherAccount = React.lazy(() =>
  import('src/pages/CreateTeacherAccount')
)
const ChooseAccountType = React.lazy(() =>
  import('src/pages/ChooseAccountType')
)

const AppRouter = () => {
  useTrackUser()

  return (
    <>
      <PageViewTracker />
      <ScrollToTop />

      <Router primary={false}>
        {/* Public routes */}
        <Home path="/" />

        <LazyRoute
          component={CreateStudentAccount}
          path={createStudentAccountPath()}
        />
        <LazyRoute
          component={CreateTeacherAccount}
          path={createTeacherAccountPath()}
        />
        <LazyRoute
          component={ChooseAccountType}
          path={chooseAccountTypePath()}
        />

        <LazyRoute
          component={InnovatorsGuide}
          path={innovatorsGuidePath('*')}
        />

        {process.env.REACT_APP_CONTENTFUL_ENABLE_PREVIEW === 'true' && (
          <LazyRoute
            path={contentfulPreviewPath()}
            component={ContentfulPreviewRouter}
          />
        )}

        <ContentfulPage
          path={privacyPolicyPath()}
          entryId={ContentfulPageEntryId.PrivacyPolicy}
        />

        <ContentfulPage
          path={termsOfUsePath()}
          entryId={ContentfulPageEntryId.TermsOfUse}
        />

        <ContentfulPage
          path={communityPath()}
          entryId={ContentfulPageEntryId.Community}
        />

        {/* Routes requiring authentication */}
        <AuthenticatedRoute component={Dashboard} path={dashboardPath('*')} />

        <AuthenticatedRoute component={Showcase} path={showcasePath('*')} />

        <AuthenticatedRoute component={NewProject} path={projectPath('new')} />

        <AuthenticatedRoute
          component={ProjectOverview}
          path={projectPath(':projectId/*')}
        />

        <NotFound default />
      </Router>
    </>
  )
}

export default AppRouter

import React from 'react'
import { ApolloError } from 'apollo-boost'
import { get } from 'lodash'
import strings from 'src/strings'
import { MAX_CONTENT_WIDTH } from 'src/constants'
import { SmallBody } from './text'
import { Flex, PositionContainer } from './Box'
import { ErrorIcon } from './icons'

interface Props {
  error?: ApolloError
}

export const InlineError: React.FunctionComponent = ({ children }) => (
  <Flex alignItems="center">
    <Flex alignItems="center">
      <ErrorIcon animate width="18px" height="18px" />
    </Flex>
    <SmallBody ml="2" color="coral">
      {children}
    </SmallBody>
  </Flex>
)

const BannerError: React.FunctionComponent = ({ children }) => (
  <Flex
    width="100%"
    justifyContent=" center"
    alignItems="center"
    bg="coral"
    py="3"
    px="5"
  >
    <Flex alignItems="center" width="100%" maxWidth={MAX_CONTENT_WIDTH}>
      <ErrorIcon animate color="white" width="18px" height="18px" />
      <SmallBody ml="2" color="white">
        {children}
      </SmallBody>
    </Flex>
  </Flex>
)

export const GenericInlineError: React.FunctionComponent<Props> = ({
  error,
}) => {
  switch (get(error, 'graphQLErrors[0].extensions.code')) {
    case 'FORBIDDEN':
      return <InlineError>{strings.errors.forbidden}</InlineError>
    default:
      return <InlineError>{strings.errors.generic}</InlineError>
  }
}

interface PageErrorContext {
  error?: string
  setError: (error: string) => void
  clearError: () => void
}

export const PageErrorContext = React.createContext<PageErrorContext>({
  setError: () => {},
  clearError: () => {},
})

export const PageErrorProvider: React.FunctionComponent = ({ children }) => {
  const [error, setError] = React.useState<string | undefined>()

  return (
    <PageErrorContext.Provider
      value={{ error, setError, clearError: () => setError(undefined) }}
    >
      {children}
    </PageErrorContext.Provider>
  )
}

export const PageError: React.FunctionComponent = () => {
  const { error } = React.useContext(PageErrorContext)

  if (!error) {
    return null
  }

  return (
    <PositionContainer position="sticky" top="0px">
      <BannerError>{error}</BannerError>
    </PositionContainer>
  )
}

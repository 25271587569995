import {
  ProjectDetails,
  ProjectPhaseState,
  ProjectPhase,
} from 'src/graphql-generated'
import { enumValues } from './enum'

export { ProjectPhase } from 'src/graphql-generated'

export type ProjectDetailsComparator = (
  a: ProjectDetailsWithNotification,
  b: ProjectDetailsWithNotification
) => -1 | 0 | 1

export interface ProjectDetailsWithNotification extends ProjectDetails {
  hasNotification?: boolean
}

export const hasReturnedState = (project: ProjectDetails) =>
  Object.values(project.phaseSummary).some(
    s => s.state === ProjectPhaseState.Returned
  )

export const sortByHasFeedback: ProjectDetailsComparator = (a, b) => {
  const aHasReturned = hasReturnedState(a)
  const bHasReturned = hasReturnedState(b)

  if (aHasReturned && !bHasReturned) return -1
  if (bHasReturned && !aHasReturned) return 1
  return 0
}

export const sortByNotification: ProjectDetailsComparator = (a, b) => {
  if (a.hasNotification && b.hasNotification) {
    return 0
  }

  if (a.hasNotification) {
    return -1
  }

  if (b.hasNotification) {
    return 1
  }

  return 0
}

export enum ProjectPhaseStep {
  Explore = 'explore',
  Do = 'do',
  Document = 'document',
}

export interface PhaseReadyForReview {
  phase: ProjectPhase
  project: ProjectDetails
  time: number
}

export const computePhasesReadyForReview = (projects: ProjectDetails[]) => {
  const phasesReady: PhaseReadyForReview[] = []

  projects.forEach(project =>
    enumValues(ProjectPhase).forEach((phase: ProjectPhase) => {
      const status = project.phaseSummary[phase]

      if (status.state === ProjectPhaseState.Submitted) {
        phasesReady.push({
          phase,
          project,
          time: status.time ? status.time : 0,
        })
      }
    })
  )

  // oldest submissions first
  phasesReady.sort((a, b) => a.time - b.time)

  return phasesReady
}

import strings from 'src/strings'
import { ProjectPhase, ProjectPhaseStep } from './project'

export const createStudentAccountPath = () => '/create-student-account'
export const createTeacherAccountPath = () => '/create-teacher-account'
export const termsOfUsePath = () => '/terms-of-use'
export const privacyPolicyPath = () => '/privacy-policy'
export const chooseAccountTypePath = () => '/choose-account-type'
export const communityPath = () => '/community'

export const contentfulPreviewPath = () => 'contentful-preview/*'

export const dashboardPath = (
  page?: 'my-projects' | 'class-projects' | 'review' | '*'
) => ['/dashboard', page].filter(Boolean).join('/')

export const projectPath = (projectId: string | number) =>
  `/project/${projectId}`

interface ProjectPhasePathParams {
  projectId: string | number
  phase: string | ProjectPhase
  step?: string | ProjectPhaseStep
  embedded?: boolean // omit '/project' from path
}

export const projectPhasePath = ({
  projectId,
  phase,
  step = ProjectPhaseStep.Explore,
  embedded: embeddedInProjectRouter = false,
}: ProjectPhasePathParams) =>
  [embeddedInProjectRouter ? projectId : projectPath(projectId), phase, step]
    .filter(Boolean)
    .join('/')

export const newProjectPath = () => projectPath('new')
export const editProjectPath = (projectId: string | number) =>
  projectPath(`${projectId}/edit`)

export const showcasePath = (page?: '*') =>
  ['/showcase', page].filter(Boolean).join('/')

export const innovatorsGuidePath = (
  page?: 'getting-started' | 'global-goals' | 'resources' | '*'
) => ['/innovators-guide', page].filter(Boolean).join('/')

export const gettingStartGuidePath = () =>
  innovatorsGuidePath('getting-started')

export const globalGoalGuidePath = () => innovatorsGuidePath('global-goals')

export const resourceGuidePath = () => innovatorsGuidePath('resources')

export const contactPath = () => strings.links.contactPath

export const pactfulMarketingSitePath = () =>
  strings.links.pactfulMarketingSitePath

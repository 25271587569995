import css from '@emotion/css'
import styled from 'src/styles/styled'
import * as typography from 'src/styles/typography'
import { theme } from 'src/styles/theme'
import { Flex, Box } from 'src/components/Box'
import { CheckmarkIcon, ErrorIcon } from 'src/components/icons'

const Input = styled.input`
  ${typography.smallBody};
  color: ${theme.colors.charcoalGrey};
  border: none;
  width: 100%;
  caret-color: ${theme.colors.dodgerBlue};
  padding: ${theme.space[1]}px;

  ::placeholder {
    color: ${theme.colors.mediumGrey};
  }
`

const styles = ({ valid, error }: { valid: boolean; error: boolean }) => css`
  ${valid && `color: ${theme.colors.leafly}`};
  ${error && `color: ${theme.colors.coral}`};
`

const BottomBorder = styled.div`
  width: 100%;
  color: ${theme.colors.charcoalGrey};
  border: solid 1px currentColor;
`

interface Props extends React.HTMLProps<HTMLInputElement> {
  valid?: boolean
  error?: boolean
}

export const TextInput: React.FunctionComponent<Props> = ({
  error = false,
  valid = false,
  ...inputProps
}) => (
  <Flex flexDirection="column">
    <Flex justifyContent="space-between" alignItems="center">
      <Input {...inputProps} type="text" />
      {valid && (
        <Box ml="2">
          <CheckmarkIcon animate width="18px" height="18px" />
        </Box>
      )}
      {error && (
        <Box ml="2">
          <ErrorIcon animate width="18px" height="18px" />
        </Box>
      )}
    </Flex>
    <Box mt="2">
      <BottomBorder
        css={styles({
          error,
          valid,
        })}
      />
    </Box>
  </Flex>
)

import React, { useEffect } from 'react'
import { Location, WindowLocation } from '@reach/router'

export const useScrollToTopEffect = (dependency?: any) => {
  useEffect(
    () => {
      window.scrollTo(0, 0)
    },
    dependency ? [dependency] : undefined
  )
}

const ScrollToTopComponent: React.FunctionComponent<{
  location: WindowLocation
}> = ({ location }) => {
  useScrollToTopEffect(location)

  return null
}

const ScrollToTop: React.FunctionComponent = () => (
  <Location>
    {({ location }) => <ScrollToTopComponent location={location} />}
  </Location>
)

export default ScrollToTop

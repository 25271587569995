export const dateToString = (
  date: Date,
  options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
) => date.toLocaleDateString('default', options)

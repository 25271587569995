import styled from 'src/styles/styled'
import { theme } from 'src/styles/theme'
import * as typography from 'src/styles/typography'
import css from '@emotion/css'
import strings from 'src/strings'

interface Props {
  optional?: boolean
  active?: boolean
}

const optionalStyle = ({ optional }: Props) => {
  if (!optional) {
    return
  }

  return css`
    &:after {
      padding-left: ${theme.space[2]}px;
      content: '${strings.formValidation.optional}';
      text-transform: none;
      ${typography.smallBody};
      color: ${theme.colors.mediumGrey};
    }
  `
}

const FieldLabel = styled<'label', Props>('label')`
  ${typography.label};
  color: ${props =>
    props.active ? theme.colors.dodgerBlue : theme.colors.charcoalGrey};
  text-transform: uppercase;
  &[for] {
    cursor: pointer;
  }
  ${optionalStyle};
`

export default FieldLabel

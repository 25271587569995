import { useState, useEffect } from 'react'
import { Entry } from 'contentful'
import {
  getContentfulClient,
  getContentfulPreviewClient,
} from 'src/contentful/contentfulClient'

const entryCache: Record<string, Entry<any>> = {}

export const useContentfulEntry = <TModel extends {}>(
  entryId: string,
  preview: boolean = false
) => {
  const existingEntry = entryCache[entryId]
  const [loading, setLoading] = useState(!existingEntry)
  const [error, setError] = useState<any>(null)
  const [entry, setEntry] = useState<Entry<TModel> | null>(existingEntry)

  useEffect(() => {
    const client =
      preview && process.env.REACT_APP_CONTENTFUL_ENABLE_PREVIEW === 'true'
        ? getContentfulPreviewClient()
        : getContentfulClient()

    client
      .getEntry<TModel>(entryId)
      .then(entry => {
        entryCache[entryId] = entry
        setEntry(entry)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [entryId, preview])

  return { fields: entry ? entry.fields : null, loading, error }
}

export default useContentfulEntry

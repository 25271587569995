import { RecordAnalyticsEventMutationVariables } from 'src/graphql-generated'

export const recordAnalyticsEvent = (
  analyticsEvent: RecordAnalyticsEventMutationVariables
) => {
  //Using fetch so that this can be used in any general context.  Not dependent on ReactJS or React Hooks.
  fetch(process.env.REACT_APP_API_URL + '/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'RecordAnalyticsEvent',
      variables: {
        userId: analyticsEvent.userId,
        event: analyticsEvent.event,
        url: analyticsEvent.url,
        type: analyticsEvent.type,
        title: analyticsEvent.title,
        mediaSource: analyticsEvent.mediaSource,
        objectId: analyticsEvent.objectId,
        mediaSourceUrl: analyticsEvent.mediaSourceUrl,
        referrer: analyticsEvent.referrer,
        trackStartTime: analyticsEvent.trackStartTime,
        timeZoneOffset: analyticsEvent.timeZoneOffset,
      },
      query: `
      mutation RecordAnalyticsEvent(
        $userId: Int!
        $event: String!
        $url: String!
        $type: String!
        $title: String
        $mediaSource: String
        $objectId: String
        $mediaSourceUrl: String
        $referrer: String
        $trackStartTime: Int
        $timeZoneOffset: Int!
      ) {
        recordAnalyticsEvent(
          userId: $userId
          event: $event
          url: $url
          type: $type
          title: $title
          mediaSource: $mediaSource
          objectId: $objectId
          mediaSourceUrl: $mediaSourceUrl
          referrer: $referrer
          trackStartTime: $trackStartTime
          timeZoneOffset: $timeZoneOffset
        ) {
          id
          userId
          event
        }
      }
      `,
    }),
  })
  //can do whatever other thing is needed here once the Promise is resolved
  // .then(response => {
  //   //console.log(response.json())
  // })
}

import React from 'react'
import Helmet from 'react-helmet'
import { Router, RouteComponentProps } from '@reach/router'

import { AuthenticatedRouteProps } from 'src/components/AuthenticatedRoute'
import Loading from 'src/components/Loading'
import {
  useProjectDetailsQuery,
  ProjectDetails,
  ClassGroupRole,
} from 'src/graphql-generated'
import { Box } from 'src/components/Box'
import { PageBackground, BackgroundType } from 'src/components/PageBackground'
import { ProjectPhase as ProjectPhaseEnum } from 'src/lib/project'
import EditProject from 'src/pages/EditProject'
import ProjectPhase from 'src/pages/ProjectPhase'
import ErrorPage from 'src/pages/ErrorPage'
import { minuteToMs } from 'src/lib/time'
import NotFound from '../NotFound'
import PageTitle from './PageTitle'
import Details from './Details'
import PhaseCards from './PhaseCards'

type RouteProps = AuthenticatedRouteProps<{ projectId: string }>

const REFRESH_DETAILS_INTERVAL_MS = minuteToMs(2)

const useProjectDetailsQueryWithId = (projectId?: String) => {
  if (projectId === undefined) {
    throw new Error('expected projectId')
  }

  return useProjectDetailsQuery({
    variables: { projectId: Number(projectId) },
    pollInterval: REFRESH_DETAILS_INTERVAL_MS,
  })
}

const ProjectRouter: React.FunctionComponent<RouteProps> = ({
  viewer,
  projectId,
}) => {
  const { data, error, loading } = useProjectDetailsQueryWithId(projectId)

  if (loading) return <Loading />

  if (error) return <ErrorPage error={error} />

  if (!data || !data.projectDetails) return <NotFound />

  const project = data.projectDetails

  const viewerCanEdit =
    viewer.role === ClassGroupRole.Instructor ||
    project.teamMembers.map(t => t.id).includes(viewer.id)

  return (
    <Router primary={false}>
      <ProjectOverview
        path="/"
        project={project}
        viewerCanEdit={viewerCanEdit}
      />

      {viewerCanEdit && (
        <EditProject path="/edit" project={project} viewer={viewer} />
      )}

      {viewerCanEdit && (
        <ProjectPhase
          path={`${ProjectPhaseEnum.Understand}/*`}
          phase={ProjectPhaseEnum.Understand}
          project={project}
        />
      )}

      {viewerCanEdit && (
        <ProjectPhase
          path={`${ProjectPhaseEnum.Ideate}/*`}
          phase={ProjectPhaseEnum.Ideate}
          project={project}
        />
      )}

      {viewerCanEdit && (
        <ProjectPhase
          path={`${ProjectPhaseEnum.Prototype}/*`}
          phase={ProjectPhaseEnum.Prototype}
          project={project}
        />
      )}

      {viewerCanEdit && (
        <ProjectPhase
          path={`${ProjectPhaseEnum.Pitch}/*`}
          phase={ProjectPhaseEnum.Pitch}
          project={project}
        />
      )}

      <NotFound default />
    </Router>
  )
}

const ProjectOverview: React.FunctionComponent<
  RouteComponentProps & {
    project: ProjectDetails
    viewerCanEdit: boolean
  }
> = ({ project, viewerCanEdit }) => (
  <>
    <Helmet title={project.name} />
    <PageBackground type={BackgroundType.YellowCurveConvex} />

    <PageTitle details={project} canEdit={viewerCanEdit} />

    <Box mt="7">
      <Details {...project} />
    </Box>

    <Box mt={[6, 9]}>
      <PhaseCards canEdit={viewerCanEdit} project={project} />
    </Box>
  </>
)

export default ProjectRouter


export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: number,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};


export type AnalyticsEvent = {
  id: Scalars['Int'],
  userId: Scalars['Int'],
  event: Scalars['String'],
};

export type AttachedDocument = {
  url: Scalars['String'],
  mimeType: Scalars['String'],
  name: Scalars['String'],
  serviceId: Scalars['String'],
  service: AttachmentService,
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase,
};

export enum AttachmentService {
  Google = 'google'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type ClassGroup = {
  id: Scalars['Int'],
  name: Scalars['String'],
  code: Scalars['String'],
  classCreatorId?: Maybe<Scalars['Int']>,
  students: Array<Student>,
  instructors: Array<Instructor>,
  projects: Array<ProjectDetails>,
  time: Scalars['Date'],
};

export enum ClassGroupRole {
  Student = 'student',
  Instructor = 'instructor'
}

export type ClassInfoInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  organization: Scalars['String'],
  jobTitle: Scalars['String'],
  studentTypeDescription?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  state?: Maybe<Scalars['String']>,
  country: Scalars['String'],
};

export type ClassInfoInputMinimal = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
};


export type DeletionDetails = {
  classGroupId: Scalars['Int'],
  projectId: Scalars['Int'],
};

export enum GlobalGoal {
  NoPoverty = 'NoPoverty',
  ZeroHunger = 'ZeroHunger',
  GoodHealthAndWellBeing = 'GoodHealthAndWellBeing',
  QualityEducation = 'QualityEducation',
  GenderEquality = 'GenderEquality',
  CleanWaterAndSanitation = 'CleanWaterAndSanitation',
  AffordableAndCleanEnergy = 'AffordableAndCleanEnergy',
  DecentWorkAndEconomicGrowth = 'DecentWorkAndEconomicGrowth',
  IndustryInnovationAndInfrastructure = 'IndustryInnovationAndInfrastructure',
  ReducedInequalities = 'ReducedInequalities',
  SustainableCitiesAndCommunities = 'SustainableCitiesAndCommunities',
  ResponsibleConsumptionAndProduction = 'ResponsibleConsumptionAndProduction',
  ClimateAction = 'ClimateAction',
  LifeBelowWater = 'LifeBelowWater',
  LifeOnLand = 'LifeOnLand',
  PeaceJusticeAndStrongInstitutions = 'PeaceJusticeAndStrongInstitutions',
  PartnershipsForTheGoals = 'PartnershipsForTheGoals'
}

export type Instructor = User & {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  profileIconUrl: Scalars['String'],
};

export type Mutation = {
  _?: Maybe<Scalars['Boolean']>,
  recordAnalyticsEvent: AnalyticsEvent,
  submitProjectPhase: ProjectPhaseStatus,
  approveProjectPhase: ProjectPhaseStatus,
  returnProjectPhase: ProjectPhaseStatus,
  createProject: ProjectDetails,
  updateProject: ProjectDetails,
  deleteProject: DeletionDetails,
  removeProjectNotifications: Array<ProjectNotification>,
  attachDocument: AttachedDocument,
  joinClassGroup: ClassGroup,
  createNewClassGroup: ClassGroup,
  updateClassGroupInstructors: ClassGroup,
};


export type MutationRecordAnalyticsEventArgs = {
  userId: Scalars['Int'],
  event: Scalars['String'],
  url: Scalars['String'],
  type: Scalars['String'],
  title: Scalars['String'],
  mediaSource: Scalars['String'],
  objectId: Scalars['String'],
  mediaSourceUrl: Scalars['String'],
  referrer: Scalars['String'],
  trackStartTime: Scalars['Int'],
  timeZoneOffset: Scalars['Int']
};


export type MutationSubmitProjectPhaseArgs = {
  input: PhaseActionInput
};


export type MutationApproveProjectPhaseArgs = {
  input: PhaseActionInput
};


export type MutationReturnProjectPhaseArgs = {
  input: PhaseActionInput
};


export type MutationCreateProjectArgs = {
  name: Scalars['String'],
  description: Scalars['String'],
  teamName: Scalars['String'],
  globalGoal: GlobalGoal,
  memberIds: Array<Scalars['Int']>,
  classGroupId: Scalars['Int']
};


export type MutationUpdateProjectArgs = {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  teamName?: Maybe<Scalars['String']>,
  globalGoal?: Maybe<GlobalGoal>,
  memberIds?: Maybe<Array<Scalars['Int']>>,
  classGroupId?: Maybe<Scalars['Int']>
};


export type MutationDeleteProjectArgs = {
  id: Scalars['Int']
};


export type MutationRemoveProjectNotificationsArgs = {
  ids: Array<Scalars['Int']>
};


export type MutationAttachDocumentArgs = {
  url: Scalars['String'],
  mimeType: Scalars['String'],
  name: Scalars['String'],
  serviceId: Scalars['String'],
  service: AttachmentService,
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type MutationJoinClassGroupArgs = {
  code: Scalars['String']
};


export type MutationCreateNewClassGroupArgs = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>
};


export type MutationUpdateClassGroupInstructorsArgs = {
  classGroupId: Scalars['Int'],
  memberIds: Array<Scalars['Int']>
};

export type PhaseActionInput = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase,
  comment?: Maybe<Scalars['String']>,
};

export type ProjectDetails = {
  id: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  teamName: Scalars['String'],
  globalGoal: GlobalGoal,
  teamMembers: Array<Student>,
  classGroupId: Scalars['Int'],
  phaseSummary: ProjectPhaseSummary,
};

export type ProjectNotification = {
  id: Scalars['Int'],
  time: Scalars['Date'],
  projectId: Scalars['Int'],
  phase: ProjectPhase,
};

export enum ProjectPhase {
  Understand = 'understand',
  Ideate = 'ideate',
  Prototype = 'prototype',
  Pitch = 'pitch'
}

export type ProjectPhaseHistoryEntry = {
  id: Scalars['Int'],
  comment?: Maybe<Scalars['String']>,
  state: ProjectPhaseState,
  time: Scalars['Date'],
};

export enum ProjectPhaseState {
  Unsubmitted = 'unsubmitted',
  Submitted = 'submitted',
  Returned = 'returned',
  Approved = 'approved'
}

export type ProjectPhaseStatus = {
  id: Scalars['ID'],
  comment?: Maybe<Scalars['String']>,
  state: ProjectPhaseState,
  time?: Maybe<Scalars['Date']>,
};

export type ProjectPhaseSummary = {
  understand: ProjectPhaseStatus,
  ideate: ProjectPhaseStatus,
  prototype: ProjectPhaseStatus,
  pitch: ProjectPhaseStatus,
};

export type Query = {
  _?: Maybe<Scalars['Boolean']>,
  projectPhaseHistory: Array<ProjectPhaseHistoryEntry>,
  projectPhaseStatus: ProjectPhaseStatus,
  viewer?: Maybe<Viewer>,
  allInstructors: Array<Instructor>,
  projectDetails?: Maybe<ProjectDetails>,
  showcaseProjects: Array<ProjectDetails>,
  viewerProjectNotifications: Array<ProjectNotification>,
  attachedDocuments: Array<AttachedDocument>,
  attachedDocumentsAllPhases: Array<AttachedDocument>,
  viewerClassGroups: Array<ClassGroup>,
  isValidClassGroupCode: Scalars['Boolean'],
  viewerClassGroup: ClassGroup,
  viewerAllClassGroups: Array<ClassGroup>,
};


export type QueryProjectPhaseHistoryArgs = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type QueryProjectPhaseStatusArgs = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type QueryProjectDetailsArgs = {
  id: Scalars['Int']
};


export type QueryAttachedDocumentsArgs = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type QueryAttachedDocumentsAllPhasesArgs = {
  projectId: Scalars['Int']
};


export type QueryIsValidClassGroupCodeArgs = {
  code: Scalars['String']
};


export type QueryViewerClassGroupArgs = {
  id: Scalars['Int']
};

export type Student = User & {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  profileIconUrl: Scalars['String'],
};


export type User = {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  profileIconUrl: Scalars['String'],
};

export type Viewer = User & {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  profileIconUrl: Scalars['String'],
  role: ClassGroupRole,
};
export type RecordAnalyticsEventMutationVariables = {
  userId: Scalars['Int'],
  event: Scalars['String'],
  url: Scalars['String'],
  type: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  mediaSource?: Maybe<Scalars['String']>,
  objectId?: Maybe<Scalars['String']>,
  mediaSourceUrl?: Maybe<Scalars['String']>,
  referrer?: Maybe<Scalars['String']>,
  trackStartTime?: Maybe<Scalars['Int']>,
  timeZoneOffset: Scalars['Int']
};


export type RecordAnalyticsEventMutation = ({ __typename?: 'Mutation' } & { recordAnalyticsEvent: ({ __typename?: 'AnalyticsEvent' } & Pick<AnalyticsEvent, 'id' | 'userId' | 'event'>) });

export type ViewerQueryVariables = {};


export type ViewerQuery = ({ __typename?: 'Query' } & { viewer: Maybe<({ __typename?: 'Viewer' } & Pick<Viewer, 'id' | 'name' | 'email' | 'profileIconUrl' | 'role'>)> });

export type ClassGroupPartsFragment = ({ __typename?: 'ClassGroup' } & Pick<ClassGroup, 'id' | 'name' | 'code' | 'classCreatorId' | 'time'> & { students: Array<({ __typename?: 'Student' } & Pick<Student, 'id' | 'name' | 'profileIconUrl'>)>, instructors: Array<({ __typename?: 'Instructor' } & Pick<Instructor, 'id' | 'name' | 'profileIconUrl'>)>, projects: Array<({ __typename?: 'ProjectDetails' } & ProjectDetailPartsFragment)> });

export type ClassGroupsQueryVariables = {};


export type ClassGroupsQuery = ({ __typename?: 'Query' } & { viewerClassGroups: Array<({ __typename?: 'ClassGroup' } & ClassGroupPartsFragment)> });

export type ClassGroupQueryVariables = {
  id: Scalars['Int']
};


export type ClassGroupQuery = ({ __typename?: 'Query' } & { viewerClassGroup: ({ __typename?: 'ClassGroup' } & ClassGroupPartsFragment) });

export type AllClassGroupsQueryVariables = {};


export type AllClassGroupsQuery = ({ __typename?: 'Query' } & { viewerAllClassGroups: Array<({ __typename?: 'ClassGroup' } & ClassGroupPartsFragment)> });

export type JoinClassGroupMutationVariables = {
  code: Scalars['String']
};


export type JoinClassGroupMutation = ({ __typename?: 'Mutation' } & { joinClassGroup: ({ __typename?: 'ClassGroup' } & ClassGroupPartsFragment) });

export type CreateNewClassGroupMutationVariables = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>
};


export type CreateNewClassGroupMutation = ({ __typename?: 'Mutation' } & { createNewClassGroup: ({ __typename?: 'ClassGroup' } & ClassGroupPartsFragment) });

export type UpdateClassGroupInstructorsMutationVariables = {
  memberIds: Array<Scalars['Int']>,
  classGroupId: Scalars['Int']
};


export type UpdateClassGroupInstructorsMutation = ({ __typename?: 'Mutation' } & { updateClassGroupInstructors: ({ __typename?: 'ClassGroup' } & ClassGroupPartsFragment) });

export type IsValidClassGroupCodeQueryVariables = {
  code: Scalars['String']
};


export type IsValidClassGroupCodeQuery = ({ __typename?: 'Query' } & Pick<Query, 'isValidClassGroupCode'>);

export type AttachedDocumentsQueryVariables = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type AttachedDocumentsQuery = ({ __typename?: 'Query' } & { attachedDocuments: Array<({ __typename?: 'AttachedDocument' } & Pick<AttachedDocument, 'serviceId' | 'service' | 'name' | 'url' | 'mimeType' | 'projectId' | 'projectPhase'>)> });

export type AttachedDocumentsAllPhasesQueryVariables = {
  projectId: Scalars['Int']
};


export type AttachedDocumentsAllPhasesQuery = ({ __typename?: 'Query' } & { attachedDocumentsAllPhases: Array<({ __typename?: 'AttachedDocument' } & Pick<AttachedDocument, 'serviceId' | 'service' | 'name' | 'url' | 'mimeType' | 'projectId' | 'projectPhase'>)> });

export type AttachDocumentMutationVariables = {
  url: Scalars['String'],
  mimeType: Scalars['String'],
  name: Scalars['String'],
  serviceId: Scalars['String'],
  service: AttachmentService,
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type AttachDocumentMutation = ({ __typename?: 'Mutation' } & { attachDocument: ({ __typename?: 'AttachedDocument' } & Pick<AttachedDocument, 'serviceId' | 'service' | 'name' | 'mimeType' | 'url' | 'projectId' | 'projectPhase'>) });

export type ProjectNotificationPartsFragment = ({ __typename?: 'ProjectNotification' } & Pick<ProjectNotification, 'id' | 'time' | 'projectId' | 'phase'>);

export type ViewerProjectNotificationsQueryVariables = {};


export type ViewerProjectNotificationsQuery = ({ __typename?: 'Query' } & { viewerProjectNotifications: Array<({ __typename?: 'ProjectNotification' } & ProjectNotificationPartsFragment)> });

export type RemoveProjectNotificationsMutationVariables = {
  ids: Array<Scalars['Int']>
};


export type RemoveProjectNotificationsMutation = ({ __typename?: 'Mutation' } & { removeProjectNotifications: Array<({ __typename?: 'ProjectNotification' } & ProjectNotificationPartsFragment)> });

export type ProjectDetailPartsFragment = ({ __typename?: 'ProjectDetails' } & Pick<ProjectDetails, 'id' | 'description' | 'globalGoal' | 'name' | 'classGroupId' | 'teamName'> & { phaseSummary: ({ __typename?: 'ProjectPhaseSummary' } & { understand: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'state' | 'time'>), ideate: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'state' | 'time'>), prototype: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'state' | 'time'>), pitch: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'state' | 'time'>) }), teamMembers: Array<({ __typename?: 'Student' } & Pick<Student, 'id' | 'name' | 'profileIconUrl'>)> });

export type ProjectDetailsQueryVariables = {
  projectId: Scalars['Int']
};


export type ProjectDetailsQuery = ({ __typename?: 'Query' } & { projectDetails: Maybe<({ __typename?: 'ProjectDetails' } & ProjectDetailPartsFragment)> });

export type ShowcaseProjectsQueryVariables = {};


export type ShowcaseProjectsQuery = ({ __typename?: 'Query' } & { showcaseProjects: Array<({ __typename?: 'ProjectDetails' } & ProjectDetailPartsFragment)> });

export type CreateProjectMutationVariables = {
  name: Scalars['String'],
  teamName: Scalars['String'],
  description: Scalars['String'],
  globalGoal: GlobalGoal,
  memberIds: Array<Scalars['Int']>,
  classGroupId: Scalars['Int']
};


export type CreateProjectMutation = ({ __typename?: 'Mutation' } & { createProject: ({ __typename?: 'ProjectDetails' } & ProjectDetailPartsFragment) });

export type UpdateProjectMutationVariables = {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  teamName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  globalGoal?: Maybe<GlobalGoal>,
  memberIds?: Maybe<Array<Scalars['Int']>>,
  classGroupId?: Maybe<Scalars['Int']>
};


export type UpdateProjectMutation = ({ __typename?: 'Mutation' } & { updateProject: ({ __typename?: 'ProjectDetails' } & ProjectDetailPartsFragment) });

export type DeleteProjectMutationVariables = {
  id: Scalars['Int']
};


export type DeleteProjectMutation = ({ __typename?: 'Mutation' } & { deleteProject: ({ __typename?: 'DeletionDetails' } & Pick<DeletionDetails, 'classGroupId' | 'projectId'>) });

export type ProjectPhaseStatusQueryVariables = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type ProjectPhaseStatusQuery = ({ __typename?: 'Query' } & { projectPhaseStatus: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'state' | 'time'>) });

export type ProjectPhaseHistoryQueryVariables = {
  projectId: Scalars['Int'],
  projectPhase: ProjectPhase
};


export type ProjectPhaseHistoryQuery = ({ __typename?: 'Query' } & { projectPhaseHistory: Array<({ __typename?: 'ProjectPhaseHistoryEntry' } & Pick<ProjectPhaseHistoryEntry, 'id' | 'state' | 'time' | 'comment'>)> });

export type SubmitProjectPhaseMutationVariables = {
  input: PhaseActionInput
};


export type SubmitProjectPhaseMutation = ({ __typename?: 'Mutation' } & { submitProjectPhase: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'state' | 'time'>) });

export type ApproveProjectPhaseMutationVariables = {
  input: PhaseActionInput
};


export type ApproveProjectPhaseMutation = ({ __typename?: 'Mutation' } & { approveProjectPhase: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'comment' | 'state' | 'time'>) });

export type ReturnProjectPhaseMutationVariables = {
  input: PhaseActionInput
};


export type ReturnProjectPhaseMutation = ({ __typename?: 'Mutation' } & { returnProjectPhase: ({ __typename?: 'ProjectPhaseStatus' } & Pick<ProjectPhaseStatus, 'id' | 'comment' | 'state' | 'time'>) });

export type AllInstructorsQueryVariables = {};


export type AllInstructorsQuery = ({ __typename?: 'Query' } & { allInstructors: Array<({ __typename?: 'Instructor' } & Pick<Instructor, 'id' | 'name' | 'profileIconUrl'>)> });

import gql from 'graphql-tag';
import * as ReactApolloHooks from 'react-apollo-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export const ProjectDetailPartsFragmentDoc = gql`
    fragment ProjectDetailParts on ProjectDetails {
  id
  description
  globalGoal
  name
  classGroupId
  teamName
  phaseSummary {
    understand {
      id
      state
      time
    }
    ideate {
      id
      state
      time
    }
    prototype {
      id
      state
      time
    }
    pitch {
      id
      state
      time
    }
  }
  teamMembers {
    id
    name
    profileIconUrl
  }
}
    `;
export const ClassGroupPartsFragmentDoc = gql`
    fragment ClassGroupParts on ClassGroup {
  id
  name
  code
  classCreatorId
  students {
    id
    name
    profileIconUrl
  }
  instructors {
    id
    name
    profileIconUrl
  }
  projects {
    ...ProjectDetailParts
  }
  time
}
    ${ProjectDetailPartsFragmentDoc}`;
export const ProjectNotificationPartsFragmentDoc = gql`
    fragment ProjectNotificationParts on ProjectNotification {
  id
  time
  projectId
  phase
}
    `;
export const RecordAnalyticsEventDocument = gql`
    mutation RecordAnalyticsEvent($userId: Int!, $event: String!, $url: String!, $type: String!, $title: String, $mediaSource: String, $objectId: String, $mediaSourceUrl: String, $referrer: String, $trackStartTime: Int, $timeZoneOffset: Int!) {
  recordAnalyticsEvent(userId: $userId, event: $event, url: $url, type: $type, title: $title, mediaSource: $mediaSource, objectId: $objectId, mediaSourceUrl: $mediaSourceUrl, referrer: $referrer, trackStartTime: $trackStartTime, timeZoneOffset: $timeZoneOffset) {
    id
    userId
    event
  }
}
    `;

export function useRecordAnalyticsEventMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<RecordAnalyticsEventMutation, RecordAnalyticsEventMutationVariables>) {
  return ReactApolloHooks.useMutation<RecordAnalyticsEventMutation, RecordAnalyticsEventMutationVariables>(RecordAnalyticsEventDocument, baseOptions);
};
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    id
    name
    email
    profileIconUrl
    role
  }
}
    `;

export function useViewerQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ViewerQueryVariables>) {
  return ReactApolloHooks.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
};
export const ClassGroupsDocument = gql`
    query ClassGroups {
  viewerClassGroups {
    ...ClassGroupParts
  }
}
    ${ClassGroupPartsFragmentDoc}`;

export function useClassGroupsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ClassGroupsQueryVariables>) {
  return ReactApolloHooks.useQuery<ClassGroupsQuery, ClassGroupsQueryVariables>(ClassGroupsDocument, baseOptions);
};
export const ClassGroupDocument = gql`
    query ClassGroup($id: Int!) {
  viewerClassGroup(id: $id) {
    ...ClassGroupParts
  }
}
    ${ClassGroupPartsFragmentDoc}`;

export function useClassGroupQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ClassGroupQueryVariables>) {
  return ReactApolloHooks.useQuery<ClassGroupQuery, ClassGroupQueryVariables>(ClassGroupDocument, baseOptions);
};
export const AllClassGroupsDocument = gql`
    query allClassGroups {
  viewerAllClassGroups {
    ...ClassGroupParts
  }
}
    ${ClassGroupPartsFragmentDoc}`;

export function useAllClassGroupsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<AllClassGroupsQueryVariables>) {
  return ReactApolloHooks.useQuery<AllClassGroupsQuery, AllClassGroupsQueryVariables>(AllClassGroupsDocument, baseOptions);
};
export const JoinClassGroupDocument = gql`
    mutation JoinClassGroup($code: String!) {
  joinClassGroup(code: $code) {
    ...ClassGroupParts
  }
}
    ${ClassGroupPartsFragmentDoc}`;

export function useJoinClassGroupMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<JoinClassGroupMutation, JoinClassGroupMutationVariables>) {
  return ReactApolloHooks.useMutation<JoinClassGroupMutation, JoinClassGroupMutationVariables>(JoinClassGroupDocument, baseOptions);
};
export const CreateNewClassGroupDocument = gql`
    mutation CreateNewClassGroup($name: String!, $description: String) {
  createNewClassGroup(name: $name, description: $description) {
    ...ClassGroupParts
  }
}
    ${ClassGroupPartsFragmentDoc}`;

export function useCreateNewClassGroupMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<CreateNewClassGroupMutation, CreateNewClassGroupMutationVariables>) {
  return ReactApolloHooks.useMutation<CreateNewClassGroupMutation, CreateNewClassGroupMutationVariables>(CreateNewClassGroupDocument, baseOptions);
};
export const UpdateClassGroupInstructorsDocument = gql`
    mutation UpdateClassGroupInstructors($memberIds: [Int!]!, $classGroupId: Int!) {
  updateClassGroupInstructors(memberIds: $memberIds, classGroupId: $classGroupId) {
    ...ClassGroupParts
  }
}
    ${ClassGroupPartsFragmentDoc}`;

export function useUpdateClassGroupInstructorsMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<UpdateClassGroupInstructorsMutation, UpdateClassGroupInstructorsMutationVariables>) {
  return ReactApolloHooks.useMutation<UpdateClassGroupInstructorsMutation, UpdateClassGroupInstructorsMutationVariables>(UpdateClassGroupInstructorsDocument, baseOptions);
};
export const IsValidClassGroupCodeDocument = gql`
    query IsValidClassGroupCode($code: String!) {
  isValidClassGroupCode(code: $code)
}
    `;

export function useIsValidClassGroupCodeQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<IsValidClassGroupCodeQueryVariables>) {
  return ReactApolloHooks.useQuery<IsValidClassGroupCodeQuery, IsValidClassGroupCodeQueryVariables>(IsValidClassGroupCodeDocument, baseOptions);
};
export const AttachedDocumentsDocument = gql`
    query AttachedDocuments($projectId: Int!, $projectPhase: ProjectPhase!) {
  attachedDocuments(projectId: $projectId, projectPhase: $projectPhase) {
    serviceId
    service
    name
    url
    mimeType
    projectId
    projectPhase
  }
}
    `;

export function useAttachedDocumentsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<AttachedDocumentsQueryVariables>) {
  return ReactApolloHooks.useQuery<AttachedDocumentsQuery, AttachedDocumentsQueryVariables>(AttachedDocumentsDocument, baseOptions);
};
export const AttachedDocumentsAllPhasesDocument = gql`
    query AttachedDocumentsAllPhases($projectId: Int!) {
  attachedDocumentsAllPhases(projectId: $projectId) {
    serviceId
    service
    name
    url
    mimeType
    projectId
    projectPhase
  }
}
    `;

export function useAttachedDocumentsAllPhasesQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<AttachedDocumentsAllPhasesQueryVariables>) {
  return ReactApolloHooks.useQuery<AttachedDocumentsAllPhasesQuery, AttachedDocumentsAllPhasesQueryVariables>(AttachedDocumentsAllPhasesDocument, baseOptions);
};
export const AttachDocumentDocument = gql`
    mutation AttachDocument($url: String!, $mimeType: String!, $name: String!, $serviceId: String!, $service: AttachmentService!, $projectId: Int!, $projectPhase: ProjectPhase!) {
  attachDocument(url: $url, mimeType: $mimeType, name: $name, serviceId: $serviceId, service: $service, projectId: $projectId, projectPhase: $projectPhase) {
    serviceId
    service
    name
    mimeType
    url
    projectId
    projectPhase
  }
}
    `;

export function useAttachDocumentMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<AttachDocumentMutation, AttachDocumentMutationVariables>) {
  return ReactApolloHooks.useMutation<AttachDocumentMutation, AttachDocumentMutationVariables>(AttachDocumentDocument, baseOptions);
};
export const ViewerProjectNotificationsDocument = gql`
    query ViewerProjectNotifications {
  viewerProjectNotifications {
    ...ProjectNotificationParts
  }
}
    ${ProjectNotificationPartsFragmentDoc}`;

export function useViewerProjectNotificationsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ViewerProjectNotificationsQueryVariables>) {
  return ReactApolloHooks.useQuery<ViewerProjectNotificationsQuery, ViewerProjectNotificationsQueryVariables>(ViewerProjectNotificationsDocument, baseOptions);
};
export const RemoveProjectNotificationsDocument = gql`
    mutation RemoveProjectNotifications($ids: [Int!]!) {
  removeProjectNotifications(ids: $ids) {
    ...ProjectNotificationParts
  }
}
    ${ProjectNotificationPartsFragmentDoc}`;

export function useRemoveProjectNotificationsMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<RemoveProjectNotificationsMutation, RemoveProjectNotificationsMutationVariables>) {
  return ReactApolloHooks.useMutation<RemoveProjectNotificationsMutation, RemoveProjectNotificationsMutationVariables>(RemoveProjectNotificationsDocument, baseOptions);
};
export const ProjectDetailsDocument = gql`
    query ProjectDetails($projectId: Int!) {
  projectDetails(id: $projectId) {
    ...ProjectDetailParts
  }
}
    ${ProjectDetailPartsFragmentDoc}`;

export function useProjectDetailsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ProjectDetailsQueryVariables>) {
  return ReactApolloHooks.useQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, baseOptions);
};
export const ShowcaseProjectsDocument = gql`
    query ShowcaseProjects {
  showcaseProjects {
    ...ProjectDetailParts
  }
}
    ${ProjectDetailPartsFragmentDoc}`;

export function useShowcaseProjectsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ShowcaseProjectsQueryVariables>) {
  return ReactApolloHooks.useQuery<ShowcaseProjectsQuery, ShowcaseProjectsQueryVariables>(ShowcaseProjectsDocument, baseOptions);
};
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $teamName: String!, $description: String!, $globalGoal: GlobalGoal!, $memberIds: [Int!]!, $classGroupId: Int!) {
  createProject(name: $name, teamName: $teamName, description: $description, globalGoal: $globalGoal, memberIds: $memberIds, classGroupId: $classGroupId) {
    ...ProjectDetailParts
  }
}
    ${ProjectDetailPartsFragmentDoc}`;

export function useCreateProjectMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
  return ReactApolloHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
};
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: Int!, $name: String, $teamName: String, $description: String, $globalGoal: GlobalGoal, $memberIds: [Int!], $classGroupId: Int) {
  updateProject(id: $id, name: $name, teamName: $teamName, description: $description, globalGoal: $globalGoal, memberIds: $memberIds, classGroupId: $classGroupId) {
    ...ProjectDetailParts
  }
}
    ${ProjectDetailPartsFragmentDoc}`;

export function useUpdateProjectMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
  return ReactApolloHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
};
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: Int!) {
  deleteProject(id: $id) {
    classGroupId
    projectId
  }
}
    `;

export function useDeleteProjectMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
  return ReactApolloHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
};
export const ProjectPhaseStatusDocument = gql`
    query ProjectPhaseStatus($projectId: Int!, $projectPhase: ProjectPhase!) {
  projectPhaseStatus(projectId: $projectId, projectPhase: $projectPhase) {
    id
    state
    time
  }
}
    `;

export function useProjectPhaseStatusQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ProjectPhaseStatusQueryVariables>) {
  return ReactApolloHooks.useQuery<ProjectPhaseStatusQuery, ProjectPhaseStatusQueryVariables>(ProjectPhaseStatusDocument, baseOptions);
};
export const ProjectPhaseHistoryDocument = gql`
    query ProjectPhaseHistory($projectId: Int!, $projectPhase: ProjectPhase!) {
  projectPhaseHistory(projectId: $projectId, projectPhase: $projectPhase) {
    id
    state
    time
    comment
  }
}
    `;

export function useProjectPhaseHistoryQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<ProjectPhaseHistoryQueryVariables>) {
  return ReactApolloHooks.useQuery<ProjectPhaseHistoryQuery, ProjectPhaseHistoryQueryVariables>(ProjectPhaseHistoryDocument, baseOptions);
};
export const SubmitProjectPhaseDocument = gql`
    mutation SubmitProjectPhase($input: PhaseActionInput!) {
  submitProjectPhase(input: $input) {
    id
    state
    time
  }
}
    `;

export function useSubmitProjectPhaseMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<SubmitProjectPhaseMutation, SubmitProjectPhaseMutationVariables>) {
  return ReactApolloHooks.useMutation<SubmitProjectPhaseMutation, SubmitProjectPhaseMutationVariables>(SubmitProjectPhaseDocument, baseOptions);
};
export const ApproveProjectPhaseDocument = gql`
    mutation ApproveProjectPhase($input: PhaseActionInput!) {
  approveProjectPhase(input: $input) {
    id
    comment
    state
    time
  }
}
    `;

export function useApproveProjectPhaseMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<ApproveProjectPhaseMutation, ApproveProjectPhaseMutationVariables>) {
  return ReactApolloHooks.useMutation<ApproveProjectPhaseMutation, ApproveProjectPhaseMutationVariables>(ApproveProjectPhaseDocument, baseOptions);
};
export const ReturnProjectPhaseDocument = gql`
    mutation ReturnProjectPhase($input: PhaseActionInput!) {
  returnProjectPhase(input: $input) {
    id
    comment
    state
    time
  }
}
    `;

export function useReturnProjectPhaseMutation(baseOptions?: ReactApolloHooks.MutationHookOptions<ReturnProjectPhaseMutation, ReturnProjectPhaseMutationVariables>) {
  return ReactApolloHooks.useMutation<ReturnProjectPhaseMutation, ReturnProjectPhaseMutationVariables>(ReturnProjectPhaseDocument, baseOptions);
};
export const AllInstructorsDocument = gql`
    query AllInstructors {
  allInstructors {
    id
    name
    profileIconUrl
  }
}
    `;

export function useAllInstructorsQuery(baseOptions?: ReactApolloHooks.QueryHookOptions<AllInstructorsQueryVariables>) {
  return ReactApolloHooks.useQuery<AllInstructorsQuery, AllInstructorsQueryVariables>(AllInstructorsDocument, baseOptions);
};
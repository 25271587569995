import styled, { CreateStyled } from '@emotion/styled'
import { WithTheme as EmotionWithTheme } from '@emotion/styled-base'

import { Theme } from './theme'

export interface AsProps {
  as?: keyof JSX.IntrinsicElements
}

export type WithTheme<P> = EmotionWithTheme<P, Theme>

export default styled as CreateStyled<Theme>

import css from '@emotion/css'
import {
  alignItems,
  AlignItemsProps,
  bottom,
  BottomProps,
  color,
  ColorProps,
  display,
  DisplayProps,
  flexBasis,
  FlexBasisProps,
  flexDirection,
  FlexDirectionProps,
  flexWrap,
  FlexWrapProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  left,
  LeftProps,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  minWidth,
  MinWidthProps,
  position,
  PositionProps,
  right,
  RightProps,
  space,
  SpaceProps,
  top,
  TopProps,
  width,
  WidthProps,
  zIndex,
  ZIndexProps,
  gridGap,
  gridColumnGap,
  gridRowGap,
  GridGapProps,
  GridColumnProps,
  GridRowProps,
  gridTemplateRows,
  gridTemplateColumns,
  GridTemplatesAreasProps,
  GridTemplateRowsProps,
  GridTemplateColumnsProps,
  gridArea,
  gridAutoRows,
  gridAutoColumns,
  gridAutoFlow,
  gridTemplateAreas,
  GridAutoFlowProps,
  GridAutoColumnsProps,
  GridAutoRowsProps,
  BackgroundProps,
  background,
  BordersProps,
  borders,
  MinHeightProps,
  minHeight,
} from 'styled-system'
import styled, { AsProps } from 'src/styles/styled'
import { flowStyles } from 'src/styles'

interface BaseProps
  extends AsProps,
    SpaceProps,
    HeightProps,
    WidthProps,
    MaxHeightProps,
    MinHeightProps,
    MaxWidthProps,
    MinWidthProps,
    ColorProps,
    BackgroundProps,
    BordersProps,
    FlexBasisProps {}

const baseStyles = flowStyles(
  space,
  height,
  width,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  color,
  background,
  flexBasis,
  borders
)

interface BoxProps extends BaseProps, DisplayProps {}

export const Box = styled<'div', BoxProps>('div')`
  ${baseStyles}
  ${display}
`

interface FlexProps
  extends BaseProps,
    AlignItemsProps,
    FlexWrapProps,
    FlexDirectionProps,
    JustifyContentProps,
    DisplayProps {
  auto?: boolean
}

export const Flex = styled<'div', FlexProps>('div')`
  ${baseStyles}
  display: flex;
  ${({ auto = false }) => (auto ? 'flex: 1 1 auto' : undefined)};
  ${display}
  ${alignItems}
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
`

interface CenterProps {
  center?: boolean
  verticalCenter?: boolean
  horizontalCenter?: boolean
}

const center = (props: CenterProps) => {
  if (props.verticalCenter) {
    return css`
      top: 50%;
      transform: translate(0, -50%);
    `
  }

  if (props.horizontalCenter) {
    return css`
      left: 50%;
      transform: translate(-50%, 0);
    `
  }

  if (props.center) {
    return css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `
  }
}

interface PositionContainerProps
  extends BaseProps,
    DisplayProps,
    PositionProps,
    TopProps,
    RightProps,
    BottomProps,
    LeftProps,
    ZIndexProps,
    CenterProps {}

export const PositionContainer = styled<'div', PositionContainerProps>('div')`
  ${baseStyles}
  ${display}
  ${position}
  ${top}
  ${right}
  ${bottom}
  ${left}
  ${zIndex}
  ${center}
`
interface GridProps
  extends BaseProps,
    GridGapProps,
    GridColumnProps,
    GridRowProps,
    GridTemplateRowsProps,
    GridTemplateColumnsProps,
    GridTemplatesAreasProps,
    GridAutoFlowProps,
    GridAutoColumnsProps,
    GridAutoRowsProps {}

export const Grid = styled<'div', GridProps>('div')`
  display: grid;
  ${baseStyles};
  ${gridGap};
  ${gridColumnGap};
  ${gridRowGap};
  ${gridTemplateRows};
  ${gridTemplateColumns};
  ${gridTemplateAreas};
  ${gridAutoFlow};
  ${gridAutoColumns};
  ${gridAutoRows};
  ${gridArea};
`

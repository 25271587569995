import React from 'react'
import Helmet from 'react-helmet'
import arrify from 'arrify'
import { FORM_ERROR } from 'final-form'
import { navigate, RouteComponentProps } from '@reach/router'
import { Flex } from 'src/components/Box'
import { LargeTitle } from 'src/components/text'
import {
  Props as ProjectFormProps,
  ProjectFormState,
} from 'src/components/ProjectForm'
import { projectPath } from 'src/lib/paths'
import PageBackground, { BackgroundType } from 'src/components/PageBackground'
import { useUpdateProjectMutation } from 'src/hooks/useProjectMutation'
import strings from 'src/strings'
import { ProjectDetails, Viewer } from 'src/graphql-generated'
import Loading from 'src/components/Loading'
import { trackEvent } from 'src/lib/analytics'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'

const ProjectForm = React.lazy(() => import('src/components/ProjectForm'))

interface Props {
  viewer: Viewer
  project: ProjectDetails
}

const EditProject: React.FunctionComponent<RouteComponentProps & Props> = ({
  viewer,
  project,
}) => {
  const mutate = useUpdateProjectMutation()

  const onSubmit: ProjectFormProps['onSubmit'] = async ({
    classGroupId,
    globalGoal,
    memberIds,
    projectDescription,
    projectName,
    teamName,
  }) => {
    const { data } = await mutate({
      variables: {
        id: project.id,
        classGroupId,
        description: projectDescription,
        globalGoal,
        memberIds,
        name: projectName,
        teamName,
      },
    })

    if (!data || !data.updateProject) {
      return { [FORM_ERROR]: strings.formValidation.genericError }
    }

    //Make sure to only track logged in users and
    //also avoid exception due to viewer.id not being available
    if (viewer) {
      recordAnalyticsEvent({
        userId: viewer.id,
        event: AnalyticsEvents.ProjectUpdate,
        url: window.location.href,
        type: AnalyticsEventTypes.Project,
        title: project.name,
        mediaSource: null,
        objectId: project.id.toString(),
        mediaSourceUrl: null,
        referrer: null,
        trackStartTime: null,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })
    }

    trackEvent(
      AnalyticsEventTypes.Project,
      AnalyticsEvents.ProjectUpdate,
      project.name,
      project.id
    )

    navigate(projectPath(data.updateProject.id))
  }

  const initialValues: ProjectFormState = {
    globalGoal: project.globalGoal,
    projectName: project.name,
    projectDescription: project.description ? project.description : '',
    teamName: project.teamName,
    classGroupId: project.classGroupId,
    memberIds: project.teamMembers.map(({ id }) => id),
  }

  return (
    <Flex mt="10" flexDirection="column">
      <Helmet
        title={arrify(
          strings.formatString(strings.pageTitles.editProject, {
            projectName: project.name,
          })
        ).join(' - ')}
      />
      <PageBackground type={BackgroundType.YellowCurveConcave} />
      <LargeTitle mb="5">{strings.projectForm.editFormTitle}</LargeTitle>
      <React.Suspense fallback={<Loading />}>
        <ProjectForm
          viewer={viewer}
          initialValues={initialValues}
          initialPage={1}
          submitText={strings.projectForm.editAction}
          onSubmit={onSubmit}
          projectId={project.id}
        />
      </React.Suspense>
    </Flex>
  )
}
export default EditProject

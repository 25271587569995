import React from 'react'
import { Box, Flex } from 'src/components/Box'
import { SectionTitle } from 'src/components/text'
import Action from 'src/components/Action'
import { ChevronIcon } from 'src/components/icons'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import { globalViewerId } from 'src/lib/globalViewerId'
import { trackEvent } from 'src/lib/analytics'
interface Props {
  label: string
  open?: boolean
  noBorder?: boolean
  title?: string
}

const Disclosure: React.FunctionComponent<Props> = ({
  children,
  label,
  open = false,
  noBorder = false,
  title = null,
}) => {
  const [isOpen, setOpen] = React.useState(open)

  return (
    <>
      <Action
        onClick={() => {
          //globalViewerId.id is set in src/components/Header/index.tsx
          //current-location will be the value of the previous url when this is read
          if (globalViewerId.id !== 0 && title) {
            recordAnalyticsEvent({
              userId: globalViewerId.id,
              event: isOpen
                ? AnalyticsEvents.ActivityClosed
                : AnalyticsEvents.ActivityOpened,
              url: window.location.href,
              type: AnalyticsEventTypes.Activity,
              title: title,
              mediaSource: null,
              objectId: null,
              mediaSourceUrl: null,
              referrer: sessionStorage.getItem('current-location'),
              trackStartTime: null,
              timeZoneOffset: new Date().getTimezoneOffset(),
            })

            trackEvent(
              AnalyticsEventTypes.Activity,
              isOpen
                ? AnalyticsEvents.ActivityClosed
                : AnalyticsEvents.ActivityOpened,
              title
            )
          }

          setOpen(!isOpen)
        }}
        css={{ width: '100%' }}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          pb={noBorder ? 2 : 4}
          borderBottom={noBorder ? 'none' : '1px solid currentColor'}
          width="100%"
        >
          <Box>
            <SectionTitle color="currentColor" as="div">
              {/* as="div" to avoid being styled by DeduplicateExaggeratedMargins */}
              {label}
            </SectionTitle>
          </Box>
          <Box
            css={{
              transform: isOpen
                ? 'translateY(1px)'
                : 'rotate(180deg) translateY(4px)',
            }}
          >
            <ChevronIcon width="14px" height="8.2px" color="currentColor" />
          </Box>
        </Flex>
      </Action>

      {isOpen && children}
    </>
  )
}

export default Disclosure

import React from 'react'
import { Field, FormSpy } from 'react-final-form'
import { FieldSubscription } from 'final-form'

interface FieldRenderPropsWithValidating<T extends HTMLElement> {
  input: {
    name: string
    onBlur: (event?: React.FocusEvent<T>) => void
    onChange: (event: React.ChangeEvent<T>) => void
    onFocus: (event?: React.FocusEvent<T>) => void
    value: any
    checked?: boolean
  }
  meta: Partial<{
    active: boolean
    data: object
    dirty: boolean
    dirtySinceLastSubmit: boolean
    error: any
    initial: any
    invalid: boolean
    pristine: boolean
    submitError: any
    submitFailed: boolean
    submitSucceeded: boolean
    submitting: boolean
    touched: boolean
    valid: boolean
    visited: boolean
  }>
  validating: boolean
}

interface Props<T extends HTMLElement> {
  children: (props: FieldRenderPropsWithValidating<T>) => React.ReactNode
  allowNull?: boolean
  format?: ((value: any, name: string) => any) | null
  formatOnBlur?: boolean
  parse?: ((value: any, name: string) => any) | null
  name: string
  isEqual?: (a: any, b: any) => boolean
  subscription?: FieldSubscription
  validate: (value: any, allValues: object) => any
  value?: any
  [otherProp: string]: any
}

class DebouncingValidatingField<T extends HTMLElement> extends React.Component<
  Props<T>
> {
  static defaultProps = {
    debounce: 500,
  }

  clearTimeout?: () => void

  componentWillUnmount() {
    this.clearTimeout && this.clearTimeout()
  }

  validate: any = (value: any, values: any, fieldState: any) => {
    if (fieldState.active) {
      return new Promise(resolve => {
        if (this.clearTimeout) {
          this.clearTimeout()
        }
        const timerId = setTimeout(() => {
          resolve(this.props.validate(value, values))
        }, this.props.debounce)

        this.clearTimeout = () => {
          clearTimeout(timerId)
          resolve()
        }
      })
    } else {
      return this.props.validate(value, values)
    }
  }
  render() {
    return (
      <FormSpy>
        {({ validating }) => (
          <Field {...this.props} validate={this.validate}>
            {field =>
              this.props.children({
                ...field,
                validating,
              })
            }
          </Field>
        )}
      </FormSpy>
    )
  }
}

export default DebouncingValidatingField

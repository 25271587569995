import React from 'react'
import Helmet from 'react-helmet'
import { Box, Flex } from './Box'
import { Body, Title1 } from './text'

interface Props {
  pageTitle: string
  title: string
  body: (JSX.Element | string)[] | string | JSX.Element
  img: JSX.Element
}

const ErrorPageLayout: React.FunctionComponent<Props> = ({
  pageTitle,
  title,
  body,
  img,
  children,
}) => (
  <>
    <Helmet title={pageTitle} />
    <Flex alignItems="center" height="100%" mt={[5, 9]} justifyContent="center">
      <Flex maxWidth="550px" flexDirection="column" alignItems="center">
        {img}
        <Title1 my="5">{title}</Title1>
        <Box maxWidth="425px" mb="5">
          <Body textAlign="center">{body}</Body>
        </Box>
        {children}
      </Flex>
    </Flex>
  </>
)

export default ErrorPageLayout

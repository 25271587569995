import React from 'react'
import { Student } from 'src/graphql-generated'
import { Flex, Box } from 'src/components/Box'
import { ProfileIcon } from 'src/components/ProfileIcon'
import { SmallBody } from 'src/components/text'

export const TeamMemberFullName: React.FunctionComponent<Student> = student => (
  <Flex alignItems="center">
    <Box mr="3">
      <ProfileIcon user={student} size={32} />
    </Box>
    <SmallBody>{student.name}</SmallBody>
  </Flex>
)

export const onePromiseAtATime = <T, TParams extends any[]>(
  f: (...args: TParams) => Promise<T>,
  timeout: number = 6000
) => {
  let promise: Promise<T> | null
  let promiseTime = Date.now()

  return (...args: TParams) => {
    if (promise && Date.now() - promiseTime < timeout) {
      return promise
    }

    promiseTime = Date.now()
    return (promise = f(...args).finally(() => {
      promise = null
    }))
  }
}
